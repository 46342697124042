import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class MobileMenuIcons extends Component {
    render() {
        return (
            <header className="nav-down css-h5j">
                <ul>
                    <li><Link to="/" style={{ color: '#edb22b' }} tabindex="1"><i className="ri-home-smile-2-fill"></i> <br />Home</Link></li>
                    <li><Link to="/product-grid" tabindex="2"><i className="ri-apps-2-fill"></i> <br />Categories</Link></li>
                    <li><Link to="/wishlist" tabindex="3"><i className="fa fa-heart"></i> <br />Wishlist</Link></li>
                    <li><Link to="/profile" tabindex="4"><i className="ri-user-fill"></i> <br />My Account</Link></li>
                    <li><Link to="/cart" tabindex="5"><i className="fa fa-shopping-cart"></i> <br />Cart</Link></li>
                </ul>
            </header>
        );
    }
}

export default MobileMenuIcons;