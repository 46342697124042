import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PUBLIC_URL } from '../../common/CommonString';
import Footer from '../../common/layout/Footer';
import Header from '../../common/layout/Header';
import MobileMenu from '../../common/layout/MobileMenu';
import MobileMenuIcons from '../../common/layout/MobileMenuIcons';
import PaginationLink from '../../product/PaginationLink';
import Tracking from './Tracking';
import {getFloatVal}  from '../../../commonFunction';
import { languageList } from '../../../languageHelper';
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';

let user_token = localStorage.getItem('user_token');

const Index = () => {

    const [firstLoad, setFirstLoad] = useState(true);
    const [order, setOrder] = useState([]);
    const [links, setLinks] = useState({
        current_page: '',
        first_page_url: '',
        from: '',
        last_page_url: '',
        next_page_url: '',
        path: '',
        per_page: '',
        prev_page_url: '',
        to: '',
        total: '',
    });
    const [orderNumber, setOrderNumber] = useState('');

    useEffect(() => {

        if (firstLoad == true) {

            let formData = new FormData();
            formData.append('user_id', 1);

            axios({
                url: `${PUBLIC_URL}/orderhistory`,
                method: 'POST',
                headers: { Authorization: "Bearer " + user_token },
                data: formData,

            }).then(response => {

                if (response.data.status == 1) {

                    setOrder(response.data.data.data);
                    setLinks({
                        current_page: response.data.data.current_page,
                        first_page_url: response.data.data.first_page_url,
                        from: response.data.data.from,
                        last_page_url: response.data.data.last_page_url,
                        next_page_url: response.data.data.next_page_url,
                        path: response.data.data.path,
                        per_page: response.data.data.per_page,
                        prev_page_url: response.data.data.prev_page_url,
                        to: response.data.data.to,
                        total: response.data.data.total,
                    });
                }

            }).catch((error) => { });
        }

        setFirstLoad(false);

    });

    const pageUpdate = () => { }

    const orderTracking = (number) => {

        setOrderNumber(number);
    } 

    return (
        <div>
            <div id="page">

                {/* <!-- Header --> */}
                <Header />
                {/* <!-- end header --> */}

                <section className="main-container col1-layout">
                    <div className="container">
                        <div className="breadcrumbs">
                            <ul>
                                <li className="home"> <Link to="/" title="Go to Home Page">{languageList['home'][lang]}</Link> <span>/</span> </li>
                                <li> <strong>{languageList['orders'][lang]}</strong> </li>
                            </ul>
                        </div>

                        <div className="row">
                            <div className="col-md-3 account-tabs-section extr-margin-bottom">
                                <ul className="nav flex-column account-tabs">
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/profile">{languageList['profile'][lang]}</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link active" to="/orders">{languageList['orders'][lang]}</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/wishlist">{languageList['Wishlist'][lang]}</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/address">{languageList['Addresses'][lang]}</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-9">
                                <div className="tab-content account-profile">
                                    <fieldset className="group-select">
                                        <legend>{languageList['orders'][lang]}</legend>
                                    </fieldset>
                                    <div className="row">
                                        {order.length == 0 ? <div className="text-center">No Data Found!</div> : ''}
                                        {order && order.map((item, index) => {

                                            return (
                                                <div className="col-sm-12 col-lg-12" key={index}>
                                                    <div className="uthssk">
                                                        {/* <div className="pous">
                                                            <img src="/images/beverages.jpg" alt="product" />
                                                        </div> */}
                                                        <div className="pous1">
                                                            <h3>Order : {item.order_number}</h3>
                                                            <h4>Date : {item.date}</h4>
                                                            <Link to={'/order-details?order=' + item.order_number} style={{ cursor: 'pointer' }} data-toggle="modal" data-target="#myModalTrack">View Details</Link>
                                                            <br />
                                                            <br />
                                                            {/* <span style={{ cursor: 'pointer' }} data-toggle="modal" data-target="#myModalTrack" onClick={() => orderTracking(item.id)}><i className="fa fa-map"></i> Track Order</span> */}
                                                        </div>
                                                        <div className="pous2" style={{ width: '30%' }}>
                                                            <p>AED {getFloatVal(item.grand_total)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}

                                    </div>

                                    {links.total / 10 >= 1 ? <PaginationLink links={links} pageUpdate={pageUpdate} /> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Footer --> */}
                <Footer />
                {/* <!-- End Footer -->  */}

            </div>

            {/* <!-- mobile menu --> */}
            <MobileMenu />

            <MobileMenuIcons />

            {/* <!-- JavaScript -->  */}

            {orderNumber !== '' ? <Tracking orderNumber={orderNumber} /> : ''}
        </div>
    );
};

export default Index;