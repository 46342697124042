import { Backdrop } from '@material-ui/core';
import React from 'react';
import { HashLoader } from 'react-spinners';

const SpinnerComponent = (props) => {
    return (
        <Backdrop
            sx={{
                color: "#3455b0",
                backgroundColor: "rgb(255, 255, 255)",
            }}
            open={props.open}
        >
            <HashLoader color="#28a5de" size="50" /><br/>
            <h2 style={{fontSize:'50px',color:'#28a5de'}}>Single Vendor</h2>
        </Backdrop>
    );
};

export default SpinnerComponent;