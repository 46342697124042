import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PUBLIC_URL } from '../common/CommonString';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { toast } from 'react-toastify';
import ReactTooltip from "react-tooltip";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

var jQuery = require("jquery");

let ciphertext = localStorage.getItem('user_token') ? localStorage.getItem('user_token') : '';

let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';

class CategoryProductTab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            category: [],
            selectedCategory: 0
        }
    }

    componentDidMount = () => {

        axios({
            url: `${PUBLIC_URL}/categoryWithProduct`,
            method: 'POST',
        }).then(response => {

            if (response.data.status == 1) {
                // console.log(response.data.category);
                this.setState({
                    category: response.data.category,
                });
            }
        }).catch((error) => {

        });
    }

    addToCart = (product) => {
        let taxvalue = 0;
        let proPrice=product.discounted_price == 0 || product.discounted_price == null || product.discounted_price == 'null' ? product.product_price : product.discounted_price;
        if (product.tax_type === 'percent') {

            taxvalue = proPrice * (product.tax / 100);

        }
        else {
            taxvalue = product.tax;
        }
        let cart = JSON.parse(localStorage.getItem('mycart'));
        if (!cart) {
            cart = [];
        }
        if ((ciphertext === '') || (ciphertext === 'null')) {
            let added = false;
            cart.forEach(function (cartItem, index) {
                if (cartItem.product_id === product.id) {
                    cart[index].qty++;
                    added = true;
                }
            });
            if (!added) {
                let NewItem = {};
                NewItem.user_id = 1;
                NewItem.vendor_id = product.vendor_id;

                NewItem.product_id = product.id;
                NewItem.product_name = product.product_name;
                NewItem.qty = 1;
                NewItem.price = proPrice;
                NewItem.discount_amount = 0;
                NewItem.tax = taxvalue;
                NewItem.shipping_cost = product.shipping_cost;
                NewItem.variation = 'null';
                NewItem.attribute = 'null';
                NewItem.variations = [];
                NewItem.image_url = product.productimage.image_url;
                NewItem.productObject = product;
                cart.push(NewItem);
                toast.success("Product is added to cart!", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                localStorage.setItem('mycart', JSON.stringify(cart));
                this.props.cartEvent();
            }

        } else {
            let formData = new FormData();
            formData.append('user_id', 1);
            formData.append('product_id', product.id);
            formData.append('vendor_id', product.vendor_id);
            formData.append('product_name', product.product_name);
            formData.append('qty', 1);
            formData.append('price', product.discounted_price == 0 || product.discounted_price == null || product.discounted_price == 'null' ? product.product_price : product.discounted_price);
            formData.append('image', product.productimagewithout_url.image_url);
            formData.append('discount_amount', 0);
            // formData.append('discount_amount', product.discounted_price == 0 || product.discounted_price == null || product.discounted_price == 'null' ? 0 : product.product_price - product.discounted_price);
            formData.append('tax', taxvalue);
            formData.append('shipping_cost', product.shipping_cost);

            axios({
                url: `${PUBLIC_URL}/addtocart`,
                method: 'POST',
                headers: { Authorization: "Bearer " + ciphertext },
                data: formData,

            }).then(response => {

                if (response.data.status == 1) {

                    toast.success("Product is added to cart!", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    this.props.cartEvent();
                }else{
                    toast.error(response.data.message, {
                        position: "top-right",
                        autoClose: false,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }

            }).catch((error) => { console.log(error, " error response") });
        }
    }

    addToWishlist = (id) => {
   if ((ciphertext === '')||(ciphertext==='null'))
        {
          toast.error("Please login before added to wishlist!", {
                position: "top-right",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
            return;
        }
        let formData = new FormData();
        formData.append('user_id', 1);
        formData.append('product_id', id);

        axios({
            url: `${PUBLIC_URL}/addtowishlist`,
            method: 'POST',
            headers: { Authorization: "Bearer " + ciphertext },
            data: formData,

        }).then(response => {

            if (response.data.status == 1) {

                toast.success("Product is added to wishlist!", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            }

        }).catch((error) => { });
    }

    setSelected = (currentId) => {
        this.setState({
            selectedCategory: currentId
        });
    }

    render() {

        let { category } = this.state;
        const option = {
            items: 4,
            nave: false,
            dots: false,
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 2
                },
                1000: {
                    items: 4
                }
            }
        }
        let settings = {
            dots: false,
            infinite: true,
            speed: 400,
            slidesToShow: 7,
            slidesToScroll: 1,
            slide: "li",
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        dots: false,
                        slidesToShow: 6,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        dots: false,
                        slidesToShow: 6,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        dots: false,
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 700,
                    settings: {
                        dots: false,
                        slidesToShow: 3,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        dots: false,
                        slidesToShow: 3,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
              
                {
                    breakpoint: 479,
                    settings: {
                        dots: false,
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 436,
                    settings: {
                        dots: false,
                        slidesToShow:2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                }
            ]
        };
        return (






            <div id="product-tabs-slider" className="scroll-tabs">


                {/* <span
              data-for="main"
              data-tip="Hello<br />multiline<br />tooltip"
              data-iscapture="true"
            >
              ◕‿‿◕
            </span> */}


                <div className="more-info-tab clearfix">

                    <ul className="nav nav-tabs nav-tab-line container">
                        <Slider {...settings}>
                            {category && category.map((item, index) => {

                                return (

                                    <li key={index} className={this.state.selectedCategory == index ? ("active") : ("")}><a onClick={() => this.setSelected(index)} data-transition-type="backSlide" href={`#${item.slug}`} data-toggle="tab"> 
                                    <img src={item.image_url} alt={item.category_name} />
                                        <span data-for={"tooltipid-" + index} data-tip={item.category_name} data-iscapture="true" className='category-name-span'>{lang=='en'?item.category_name.length < 17 ? item.category_name : item.category_name.slice(0, 17) + '...': item.multi_name ? item.multi_name.arabic.length < 17 ? item.multi_name.arabic : item.multi_name.arabic.slice(0, 17) + '...': item.category_name.length < 17 ? item.category_name : item.category_name.slice(0, 17) + '...'}</span> </a>
                                        <ReactTooltip
                                            id={"tooltipid-" + index}
                                            multiline={true}
                                            place="bottom"
                                            type="info"
                                        />
                                    </li>

                                );
                            })}
                        </Slider>
                    </ul>

                </div>
                <div className="tab-content container">
                    {category && category.map((item, index) => {



                        return (
                            <div className={"tab-pane " + (this.state.selectedCategory == index ? 'active' : '')} id={item.slug} key={index}>
                                <div className="product-slider products-grid">
                                    <div className="owl-carousel home-owl-carousel custom-carousel owl-theme" style={{ display: 'block' }}>

                                        <OwlCarousel className='owl-theme' {...option} >
                                            {item.products && item.products.map((row, index1) => {
                                                let wordcount = row.product_name.length;
                                                let productName = row.product_name;
                                                let productarabic = row.multi_name?row.multi_name.arabic:row.product_name;
                                                let rating = row.avg_ratting !== 'null' ? row.avg_ratting : 0;
                                                return (
                                                    <div class='item'>
                                                        <div className="item-inner">
                                                            <div className="item-img">
                                                                <div className="item-img-info">
                                                                    <Link className="product-image" to={`/product-details?product=${row.id}`}>
                                                                    <div style={{height: '200px', width: '200px',backgroundSize:'contain',backgroundRepeat:'no-repeat',backgroundPosition:'center',backgroundImage:`url("${row.productimage ? row.productimage.image_url : ''}")`}} ></div>
                                                                        {/* <img alt="" src={row.productimage ? row.productimage.image_url : ''} className="myfeatured-products" /> */}
                                                                    </Link>
                                                                    {/* <div className="sale-label sale-top-right">- {row.discounted_price} AED</div> */}
                                                                    <div className="box-hover">
                                                                        <ul className="add-to-links">
                                                                            <li><Link className="link-quickview" to={`/product-details?product=${row.id}`}></Link> </li>
                                                                            <li><a className="link-wishlist" onClick={()=>this.addToWishlist(row.id)} href="javascript:void(0);"></a> </li>
                                                                            {/* <li><a className="link-compare" href="compare.html"></a> </li> */}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="item-info">
                                                                <div className="info-inner">

                                                                    <div className="item-title"> <Link to={`/product-details?product=${row.id}`}>{lang=='en'?wordcount < 50 ? productName : productName.slice(0, 50) + '...':productarabic}</Link> </div>
                                                                    <div className="brand">{row.brand_name}</div>
                                                                    <div className="star-rating">
                                                                        <span style={{ width: `${rating * 20}%` }}>Rated <strong className="rating">{rating}</strong> out of 5</span>
                                                                    </div>
                                                                    <div className="item-content">
                                                                        <div className="item-price">
                                                                            <div className="price-box"> <span className="regular-price"> <span className="price">AED {row.discounted_price}</span> </span> </div>
                                                                        </div>
                                                                        <div className="action">
                                                                            <button className="button btn-cart" onClick={() => this.addToCart(row)} type="button" title={row.product_name} data-original-title="Add to Cart"><i className="fa fa-shopping-basket"></i></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                );
                                            })}

                                        </OwlCarousel>



                                    </div>
                                </div>
                            </div>
                        );
                        // }

                    })}

                </div>
                {/* <!-- /.tab-content -->  */}


            </div>

        );
    }
}

export default CategoryProductTab;