import React, { useEffect, useRef, useState } from 'react';
import BreadCrumb from '../BreadCrumb';
import Header from '../../common/layout/Header';
import Footer from '../../common/layout/Footer';
import MobileMenu from '../../common/layout/MobileMenu';
import MobileMenuIcons from '../../common/layout/MobileMenuIcons';
import axios from 'axios';
import { PUBLIC_URL } from '../../common/CommonString';
import { useLocation, useNavigate } from 'react-router';
import { Link, Redirect } from 'react-router-dom';
import ProductReview from './ProductReview';
import { toast } from 'react-toastify';
import BestSeller from '../../home/BestSeller';
import ReviewMessage from './ReviewMessage';
import OwlCarousel from 'react-owl-carousel';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  FacebookIcon,
  EmailIcon,
  WhatsappIcon
} from "react-share";
import YoutubeEmbed from "../YoutubeView";
import { languageList } from '../../../languageHelper';
let ciphertext = localStorage.getItem('user_token') ? localStorage.getItem('user_token') : 'null';
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';

const Index = (props) => {



    const location = useLocation();
    const navigate = useNavigate();


    const productId = ((new URLSearchParams(location.search).get('product')) != '') ? (new URLSearchParams(location.search).get('product')) : '';

    const [product, setProduct] = useState({
        rattings: [
            {
                avg_ratting: 0,
                product_id: 0,
            }
        ],
        productimage: {
            image_url: '',
        },
        productimages: [],
        brand: {
            brand_name: '',
        },
        multi_name: {
             arabic: '',
        },
        multi_description: {
            arabic: '',
       },
       multi_detail_description: {
        arabic: '',
       },
        description: '',
        reviews: [],
    });
    const [show, setShow] = useState('image');
    const [price, setPrice] = useState(0);
    const [descountPrice, setDescountPrice] = useState(0);
    const [variationId, setVariationId] = useState('');

    const [vendor, setVendor] = useState([]);
    const [cartAction, setCartAction] = useState(false);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);
    const [defaultImage, setDefaultImage] = useState('');
    const [variationExist, setVariationExist] = useState(false);
    const [variationPrice, setVariationPrice] = useState(false);
    const [attribute, setAttribute] = useState([]);
    const [variation, setVariation] = useState([]);
    const [selectVariation, setSlectVariation] = useState('');
    const [variationError, setVariationError] = useState('');
    const useEffectHandler=
    useEffect(() => {

        if (firstLoad == true) {

            let formData = new FormData();
            formData.append('user_id', 1);
            formData.append('product_id', productId);

            axios({
                url: `${PUBLIC_URL}/productdetails`,
                method: 'POST',
                headers: { Authorization: "Bearer " + ciphertext },
                data: formData,

            }).then(response => {
                // console.log(response.data.related_products);
                if (response.data.status == 1) {
                    setProduct(response.data.data);
                    setVendor(response.data.vendors);
                    setRelatedProducts(response.data.related_products);
                    setDefaultImage(response.data.data ? response.data.data.productimage.image_url : '');
                    setPrice(response.data.data.product_price);
                    setDescountPrice(response.data.data.discounted_price);
                    window.scrollTo(0, 0);
                }
            }).catch((error) => { });
        }

        setFirstLoad(false);
         
    }, [location]);
    useEffect(() => {
        setFirstLoad(true);

    }, [location]);
    const addToCart = () => {
     
//         console.log(ciphertext);
        let productPrice=(descountPrice>0)?descountPrice:price;
        if ((ciphertext !== '')&&(ciphertext!=='null')) {
           
               
                if (product.is_variation == 1) {

                    if (attribute.length != 0) {

                        let taxvalue = 0;

                        if (product.tax_type === 'percent') {

                            taxvalue = productPrice * (product.tax / 100);

                        }
                        else {
                            taxvalue = product.tax;
                        }

                        let formData = new FormData();
                        formData.append('user_id', 1);
                        formData.append('product_id', product.id);
                        formData.append('vendor_id', product.vendor_id);
                        formData.append('product_name', product.product_name);
                        formData.append('qty', 1);
                        formData.append('price', productPrice);
                        formData.append('image', product.productimagewithout_url.image_url);
                        // formData.append('variation', variation !== '' ? variation : '');
                        // formData.append('attribute', attribute !== '' ? attribute : '');
                        
                        attribute.map((item, index) => {

                            formData.append('attribute[]', item.attribute1);
                        });

                        variation.map((item, index) => {

                            formData.append('variation[]', item.variation1);
                        });

                        formData.append('variationId', variationId);

                        formData.append('discount_amount', 0);
                        formData.append('tax', taxvalue);
                        formData.append('shipping_cost', product.shipping_cost);

                        axios({
                            url: `${PUBLIC_URL}/addtocart`,
                            method: 'POST',
                            headers: { Authorization: "Bearer " + ciphertext },
                            data: formData,

                        }).then(response => {

                            if (response.data.status == 1) {

                                setCartAction(true);
                                setVariationError('');

                                toast.success("Product is added to cart!", {
                                    position: "top-right",
                                    autoClose: 2000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                                setVariationExist(false);

                            }

                        }).catch((error) => {    
                            toast.error("Please login before added to cart!", {
                                    position: "top-right",
                                    autoClose: false,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined,
                                });  
                        });
                    }
                    else {
                        setVariationError('Please select a variant');
                    }
                }
                else {
                     
                    let productPrice=(descountPrice>0)?descountPrice:price;
                    let taxvalue = 0;

                    if (product.tax_type === 'percent') {

                        taxvalue = productPrice * (product.tax / 100);

                    }
                    else {
                        taxvalue = product.tax;
                    }

                    let formData = new FormData();
                    formData.append('user_id', 1);
                    formData.append('product_id', product.id);
                    formData.append('vendor_id', product.vendor_id);
                    formData.append('product_name', product.product_name);
                    formData.append('qty', 1);
                    formData.append('price', productPrice);
                    formData.append('image', product.productimagewithout_url.image_url);
                    // formData.append('variation', variation !== '' ? variation : '');
                    // formData.append('attribute', attribute !== '' ? attribute : '');

                    attribute.map((item, index) => {

                        formData.append('attribute[]', item.attribute1);
                    });

                    variation.map((item, index) => {

                        formData.append('variation[]', item.variation1);
                    });

                    formData.append('variationId', variationId);

                    formData.append('discount_amount', 0);
                    formData.append('tax', taxvalue);
                    formData.append('shipping_cost', product.shipping_cost);

                    axios({
                        url: `${PUBLIC_URL}/addtocart`,
                        method: 'POST',
                        headers: { Authorization: "Bearer " + ciphertext },
                        data: formData,

                    }).then(response => {

                        if (response.data.status == 1) {

                            setCartAction(true);

                            toast.success("Product is added to cart!", {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }

                    }).catch((error) => { });
                }
           
        }

        else if(product.is_variation == 1 && ciphertext=='null'){

            if(variationExist == false){
            //     setVariationExist(true);
            // }else{
                if (attribute.length != 0) {

                                    let cart = JSON.parse(localStorage.getItem('mycart'));
                                    if(!cart)
                                    {
                                    cart=[];
                                    }
                                            let added=false;
                        cart.forEach(function(cartItem,index){
                            if(cartItem.product_id===product.id)
                            {
                                cart[index].qty++;
                                added=true;
                            }
                        });
                        if(!added)
                        {
                       
                            let taxvalue = 0;

                            if (product.tax_type === 'percent') {
    
                                taxvalue = productPrice * (product.tax / 100);
    
                            }
                            else {
                                taxvalue = product.tax;
                            }
                        
                            
                            let NewItem={};
                            NewItem.user_id=1;
                            NewItem.vendor_id=product.vendor_id;
                            
                            NewItem.product_id=product.id;
                            NewItem.product_name=product.product_name;
                            NewItem.qty=1;
                            NewItem.price=productPrice;
                            NewItem.discount_amount=0;
                            NewItem.tax=taxvalue;
                            NewItem.shipping_cost=product.shipping_cost;
                            NewItem.variation=variationId;
                            NewItem.attribute='null';
                            NewItem.variations=[];
                            NewItem.image_url=product.productimage.image_url;
                            NewItem.productObject=product;
                            cart.push(NewItem);
                            toast.success("Product is added to cart!", {
                                        position: "top-right",
                                        autoClose: 2000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    });
                            localStorage.setItem('mycart',JSON.stringify(cart));    
                            setCartAction(true);
                            setVariationExist(false);
                    
                            
                        }

                }

                else {
                    setVariationError('Please select a variant');
                }

              
                
        }
        }
       else {

                   let cart = JSON.parse(localStorage.getItem('mycart'));
                if(!cart)
                {
                   cart=[];
                }
                        let added=false;
      cart.forEach(function(cartItem,index){
          if(cartItem.product_id===product.id)
          {
              cart[index].qty++;
              added=true;
          }
      });
      if(!added)
      {
      let proPrice=  product.discounted_price == 0 || product.discounted_price == null || product.discounted_price == 'null' ? product.product_price : product.discounted_price;
                        let taxvalue = 0;

                        if (product.tax_type === 'percent') {

                            taxvalue = proPrice * (product.tax / 100);

                        }
                        else {
                            taxvalue = product.tax;
                        }
        //  if (product.is_variation == 1) {
        //           toast.error("This product have multiple variations ,you need to login to select product from variations!", {
        //         position: "top-right",
        //         autoClose: false,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: false,
        //         progress: undefined,
        //     });
            
        //  }else{
        // }
        
        let NewItem={};
        NewItem.user_id=1;
        NewItem.vendor_id=product.vendor_id;
        
        NewItem.product_id=product.id;
        NewItem.product_name=product.product_name;
        NewItem.qty=1;
        NewItem.price=proPrice;
        NewItem.discount_amount=0;
         NewItem.tax=taxvalue;
        NewItem.shipping_cost=product.shipping_cost;
        NewItem.variation='null';
        NewItem.attribute='null';
        NewItem.variations=[];
        NewItem.image_url=product.productimage.image_url;
        NewItem.productObject=product;
          cart.push(NewItem);
         toast.success("Product is added to cart!", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
           localStorage.setItem('mycart',JSON.stringify(cart));    
          setCartAction(true);
        
      }
        }
    }

    const addToWishlist = () => {

        if ((ciphertext !== '')&&(ciphertext!=='null')) {

            let formData = new FormData();
            formData.append('user_id', 1);
            formData.append('product_id', product.id);

            axios({
                url: `${PUBLIC_URL}/addtowishlist`,
                method: 'POST',
                headers: { Authorization: "Bearer " + ciphertext },
                data: formData,

            }).then(response => {

                if (response.data.status == 1) {

                    toast.success("Product is added to wishlist!", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

            }).catch((error) => { });

        }
        else {

            toast.error("Please login before added to wishlist!", {
                position: "top-right",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }
    }

    const buyNow = () => {
        
        let productPrice=(descountPrice>0)?descountPrice:price;
        if ((ciphertext !== '')&&(ciphertext !== 'null')) {
            if (product.is_variation == 1 && variationExist == false) {

                setVariationExist(true);

            }
            else {

                if (product.is_variation == 1) {

                    if (attribute !== '') {

                        let taxvalue = 0;

                        if (product.tax_type === 'percent') {

                            taxvalue = productPrice * (product.tax / 100);

                        }
                        else {
                            taxvalue = product.tax;
                        }

                        let formData = new FormData();
                        formData.append('user_id', 1);
                        formData.append('product_id', product.id);
                        formData.append('vendor_id', product.vendor_id);
                        formData.append('product_name', product.product_name);
                        formData.append('qty', 1);
                        formData.append('price', productPrice);
                        formData.append('image', product.productimagewithout_url.image_url);
                        // formData.append('variation', variation !== '' ? variation : '');
                        // formData.append('attribute', attribute !== '' ? attribute : '');

                        attribute.map((item, index) => {

                            formData.append('attribute[]', item.attribute1);
                        });

                        variation.map((item, index) => {

                            formData.append('variation[]', item.variation1);
                        });

                        formData.append('variationId', variationId);

                        formData.append('discount_amount', '');
                        formData.append('tax', taxvalue);
                        formData.append('shipping_cost', product.shipping_cost);

                        axios({
                            url: `${PUBLIC_URL}/addtocart`,
                            method: 'POST',
                            headers: { Authorization: "Bearer " + ciphertext },
                            data: formData,

                        }).then(response => {

                            if (response.data.status == 1) {

                                setCartAction(true);
                                setVariationError('');

                                navigate('/cart');
                            }

                        }).catch((error) => { });

                    }
                    else {

                        setVariationError('Please select a variant');

                    }
                }
                else {

                    let taxvalue = 0;

                    if (product.tax_type === 'percent') {

                        taxvalue = productPrice * (product.tax / 100);

                    }
                    else {
                        taxvalue = product.tax;
                    }

                    let formData = new FormData();
                    formData.append('user_id', 1);
                    formData.append('product_id', product.id);
                    formData.append('vendor_id', product.vendor_id);
                    formData.append('product_name', product.product_name);
                    formData.append('qty', 1);
                    formData.append('price', productPrice);
                    formData.append('image', product.productimagewithout_url.image_url);
                    // formData.append('variation', variation !== '' ? variation : '');
                    // formData.append('attribute', attribute !== '' ? attribute : '');

                    attribute.map((item, index) => {

                        formData.append('attribute[]', item.attribute1);
                    });

                    variation.map((item, index) => {

                        formData.append('variation[]', item.variation1);
                    });

                    formData.append('variationId', variationId);

                    formData.append('discount_amount', '');
                    formData.append('tax', taxvalue);
                    formData.append('shipping_cost', product.shipping_cost);

                    axios({
                        url: `${PUBLIC_URL}/addtocart`,
                        method: 'POST',
                        headers: { Authorization: "Bearer " + ciphertext },
                        data: formData,

                    }).then(response => {

                        if (response.data.status == 1) {

                            setCartAction(true);
                            setVariationError('');

                            navigate('/cart');
                        }

                    }).catch((error) => { });
                }
            }
        }
        else {

//            toast.error("Please login to buy product!", {
//                position: "top-right",
//                autoClose: false,
//                hideProgressBar: false,
//                closeOnClick: true,
//                pauseOnHover: true,
//                draggable: false,
//                progress: undefined,
//            });
            localStorage.removeItem('cus_redirect');
            window.jQuery('#myModalsignin').modal('show');
        }

    }

    const reviewChange = () => {

        let formData = new FormData();
        formData.append('user_id', 1);
        formData.append('product_id', productId);

        axios({
            url: `${PUBLIC_URL}/productdetails`,
            method: 'POST',
            headers: { Authorization: "Bearer " + ciphertext },
            data: formData,

        }).then(response => {

            if (response.data.status == 1) {

                setProduct(response.data.data);
                setVendor(response.data.vendors);
                setRelatedProducts(response.data.related_products);
            }

        }).catch((error) => { });

    }

   

    const variationChange = (attribute1, atrId, variation1) => {

        let obj = {
            
            attribute1,
            atrId
        }

        let attr = attribute.filter(item => {

            if(item.atrId != atrId){
                return item;
            }
        });

        attr.push(obj);

        setSlectVariation(variation1);

        setAttribute(attr);

        let obj2 = {

            variation1,
            atrId
        }

        let attr2 = variation.filter(item => {

            if(item.atrId != atrId){
                return item;
            }
        });

        attr2.push(obj2);
      

        setVariation(attr2);
        // console.log(obj2);
        setVariationPrice(true);
        
    }
    useEffect(() => {
        applyCombination();
      }, [variation]);

    const applyCombination = () => {

        let formData = new FormData();
        formData.append('product_id', product.id);

        variation.map((item, index) => {

            formData.append('variation[]', item.variation1);
        });

        axios({
            url: `${PUBLIC_URL}/variation-price-change`,
            method: 'POST',
            data: formData
        }).then(response => {
            if(response.data.status == 1){

                setPrice(response.data.data.price);
                setDescountPrice(response.data.data.discounted_variation_price);
                setVariationId(response.data.data.id);
            }
            else{

                toast.error(response.data.message);
            }

        }).catch((error) => {});
    }
   

    let rating = product.rattings.length > 0 ? product.rattings[0].avg_ratting : 0;
    const cartEvent = () => {
  
        setCartAction(true);
      }
    return (
        <>
            <div id="page">

                {/* <!-- Header --> */}
                <Header cartAction={cartAction} />
                {/* <!-- end header -->  */}

                {/* <!-- Main Container --> */}
                <section className="main-container col1-layout">
                    <div className="container">
                        <div className="row">

                            <BreadCrumb title={product.category_name} />

                            <div className="col-sm-12 col-xs-12">

                                <article className="col-main">
                                    <div className="product-view">
                                        <div className="product-essential">

                                           
                                                <input name="product" value={productId} type="hidden" />
                                                <div className="product-img-box col-lg-5 col-sm-5 col-xs-12">
                                                    {/* <div className="new-label new-top-left"> New </div> */}
                                                    <div className="product-image">
                                                        <div className="product-full">
                                                            {show == "image" && <InnerImageZoom src={defaultImage} zoomSrc={defaultImage} />}
                                                        
                                                            {show == "youtube" &&<YoutubeEmbed embedId={product.youtube_link} />}

                                                            {/* <img id="product-zoom" src={defaultImage} data-zoom-image={defaultImage} alt="product-image" style={{height:'240px',width:'300px'}}/> */}
                                                                {/* <div id="myresult" class="img-zoom-result"></div> */}
                                                        </div>
                                                        <div className="more-views">
                                                            <div className="slider-items-products">
                                                                <div id="gallery_01" className="product-flexslider hidden-buttons product-img-thumb">
                                                                    <div className="slider-items slider-width-col4 block-content">
                                                                        {product.productimages.length > 0 ? <OwlCarousel
                                                                            autoplay={false}
                                                                            items={4} //10 items above 1000px browser width
                                                                            itemsDesktop={[1024, 3]} //5 items between 1024px and 901px
                                                                            itemsDesktopSmall={[900, 3]} // 3 items betweem 900px and 601px
                                                                            itemsTablet={[600, 3]} //2 items between 600 and 0;
                                                                            itemsMobile={[320, 2]}
                                                                            nav={true}
                                                                            navText={[`<a class=\"flex-prev\"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg></a>`, `<a class=\"flex-next\"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg></a>`]}
                                                                            slideSpeed={500}
                                                                            pagination={false}
                                                                        >
                                                                            {product.productimages && product.productimages.map((item, index) => {

                                                                                return (
                                                                                    <div className="more-views-items" key={index}>
                                                                                        <a  data-image={item.image_url} data-zoom-image={item.image_url} onClick={() => {setDefaultImage(item.image_url);setShow('image')}}>
                                                                                            <img id={'product-zoom' + index} src={item.image_url} alt="product-image" style={{height: '119px'}}/>
                                                                                        </a>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                              {!!(product.youtube_link)?
                                                                              <div className="more-views-items" >
                                                                                        <a href="#" onClick={() =>setShow('youtube')} >
                                                                                            <img id='product-youtube' src={'https://i.ytimg.com/vi/'+product.youtube_link+'/hqdefault.jpg'}  alt="product-youtube" />
                                                                                        </a>
                                                                                    </div>: ''}
                                                                                    
                                                                                    

                                                                        </OwlCarousel> : ''}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <!-- end: more-images -->  */}
                                                    <div className="add-to-box">
                                                        <div className="add-to-cart">
                                                            <button onClick={addToCart} className="button btn-cart" title={'Add to Cart'} type="button">{languageList['Add_to_Cart'][lang]}</button>
                                                            <button onClick={buyNow} className="button btn-buy" title="Add to Cart" type="button">{languageList['Buy_Now'][lang]}</button>

                                                        </div>

                                                    </div>
                                                    <ul className="add-to-links">
                                                        <li> <a className="link-wishlist" onClick={addToWishlist} href="javascript:void(0);"><span>{languageList['Add_to_Wishlist'][lang]}</span></a></li>
                                                        {/* <li><a className="link-compare" href="compare.html"><span>Add to Compare</span></a></li> */}
                                                    </ul>
                                                </div>
                                                <div className="product-shop col-lg- col-sm-7 col-xs-12">
                                                    {/* <div className="product-next-prev"> <a className="product-next" href="#"><span></span></a> <a className="product-prev" href="#"><span></span></a> </div> */}
                                                    <div className="brand">{product.brand?product.brand.brand_name:''}</div>
                                                    <div className="product-name">
                                                        <h1>{lang == 'en'? product.product_name :  product.multi_name ? product.multi_name.arabic :product.product_name  }</h1>
                                                        <FacebookShareButton url={window.location}>
                                                        <div style={{padding:'5px'}}>
                                                        <FacebookIcon size={32} round />
                                                        </div>
                                                        </FacebookShareButton>
                                                        
                                                        <EmailShareButton url={window.location} >
                                                        <div style={{padding:'5px'}}>
                                                        <EmailIcon size={32} round />
                                                        </div>
                                                        </EmailShareButton>
                                                        <WhatsappShareButton url={window.location} >
                                                        <div style={{padding:'5px'}}>
                                                        <WhatsappIcon size={32} round />
                                                        </div>
                                                        </WhatsappShareButton>
                                                    </div>
                                                    <div className="rating">
                                                        <div className="star-rating">
                                                            <span style={{ width: `${rating * 20}%` }}>Rated <strong className="rating">{rating}</strong> out of 5</span>
                                                        </div>
                                                        <p className="rating-links">
                                                            <a href="#">
                                                                {product.reviews.length} reviews
                                                            </a>
                                                            <span className="separator">|</span>
                                                            {/* <a href="#">Add Review</a> */}
                                                        </p>
                                                    </div>


                        {product.is_variation == 1 ?
                            <>
                            

                                    {product.variation_attribute_item && product.variation_attribute_item.map((item, index) => {                 
                                        return(  
                                        
                                            <div className="form-group"  key={index}>
                                                        <label className="text-muted">{item.attribute_name}</label>
                                                        <div>
                                                        {item.items && item.items.map((row, index1) => {

                                            return ( 
                                            <label className="js-check btn btn-check mr-1" style={{backgroundColor: selectVariation == row.variation_value? '#aeaec1' : ''}}>
                                                    <input type="radio" id={'variant' + index1} onChange={() => variationChange(item.attribute_name, item.attribute_id, row.variation_value)} name={item.attribute_name} value={row.variation_value} aria-selec  />
                                                        <span>{row.variation_value}</span>
                                                    </label>

                                            )
                                            })}
                                                       
                                                        </div>   
                                                    </div>
                                        )
                                        })}

                                     {variationError !== '' ? <div style={{fontWeight:'bold'}} className="text-danger">{variationError}</div> : ''}

                                    </>
                                       : ''}



                                                    <div className="price-block">
                                                        <div className="price-box">
                                                            {/* <p className="availability in-stock"><span>{product.qty} in stock</span></p> */}
                                                            <p className="special-price"> <span className="price-label">Special Price</span> <span id="product-price-48" className="price">AED {descountPrice == 0 ? price : descountPrice} </span> </p>
                                                            {descountPrice == 0 ? ''
                                                                : <p className="old-price"> <span className="price-label">Regular Price:</span> <span className="price"> {(price>0)?'AED '+price:''} </span> </p>}

                                                        </div>
                                                    </div>

                                                    {product.is_variation == 1 ?
                                                        <>
                                                            <div className="product-variant" style={variationExist == true ? { display: 'block' } : { display: 'none' }}>
                                                                <div class="product-variant-modal-content">
                                                                    <span class="close-icon" onClick={() => setVariationExist(false)}>&times;</span>
                                                                    <div className="container-fluid">

                                                                        <div className="alert alert-warning"><strong>Warning !</strong> Please select a commbination for product.</div>

                                                                        {product.variation_attribute_item && product.variation_attribute_item.map((item, index) => {

                                                                            return (
                                                                                <div key={index}>
                                                                                    <h3>{item.attribute_name}</h3>
                                                                                    <div className="row">
                                                                                        {item.items && item.items.map((row, index1) => {

                                                                                            return (
                                                                                                <div className="col-md-4" key={index1}>
                                                                                                    <div className="form-group">
                                                                                                        <label for={'variant' + index1} style={{ marginRight: '10px' }} >{row.variation_value}</label>
                                                                                                        <input type="radio" onChange={() => variationChange(item.attribute_name, item.attribute_id, row.variation_value)} name={item.attribute_name} value={row.variation_value} id={'variant' + index1} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            );
                                                                                        })}

                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                        {/* <div class="add-to-box">
                                                                            <div class="add-to-cart">
                                                                                <button onClick={applyCombination} class="button btn-buy" title="Add to Cart" type="button" style={{width: '20%', float: 'left'}}>Apply</button>
                                                                            </div>
                                                                        </div>
                                                                        <hr /> */}
                                                                        {price > 0 ? <><h4 style={variationPrice == true ? { display: 'block' } : { display: 'none' }}>Price : <span style={{textDecoration: 'line-through'}}>AED {price}</span></h4><h4 style={variationPrice == true ? { display: 'block' } : { display: 'none' }}>Price : AED {descountPrice}</h4></>:<><h4>Price : AED {price}</h4></>}
                                                                        {variationError !== '' ? <div className="text-danger">{variationError}</div> : ''}
                                                                        <div class="add-to-box">
                                                                            <div class="add-to-cart">
                                                                                <button onClick={addToCart} class="button btn-cart" title="Add to Cart" type="button">{languageList['Add_to_Cart'][lang]}</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* {product.is_variation == 1 ?
                                                                product.variation_attribute_item && product.variation_attribute_item.map((item, index) => {
                                                                    
                                                                    return(
                                                                        <div className="list" key={index}>
                                                                            <div className="heading">{item.attribute_name}</div>
                                                                            <div className="points">
                                                                                {item.items && item.items.map((row, index1) => {
                                                                                    return (
                                                                                        <div key={index1} style={{display: 'inline-block'}}>{row.variation_value}</div>
                                                                                    )
                                                                                })}
                                                                                {/* <span style={{ backgroundColor: 'rgb(72, 118, 255)' }}></span>
                                                                            <span style={{ backgroundColor: 'rgb(192, 192, 192)' }}></span>
                                                                            <span style={{ backgroundColor: 'rgb(255, 195, 0)' }}></span> *
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })
                                                                
                                                            :''} */}
                                                        </>
                                                        : ''}

                                                    {/* <div className="list">
                                                        <div className="heading">Highlights</div>
                                                        <div className="points">
                                                            <ul>
                                                                <li>Intel Celeron Dual Core</li>
                                                                <li>HDD Capacity 1 TB</li>
                                                                <li>RAM 4 GB DDR4</li>
                                                                <li>19.5 inch Display</li>
                                                            </ul>
                                                        </div>
                                                    </div> */}

                                                    <div className="list">
                                                        <div className="heading">{languageList['Description'][lang]}</div>
                                                        {lang == 'en'?
                                                         <div className="points">
                                                         {product.description.substring(0, 150)}
                                                         {(product.description.length>150)?(<a href="#product-detail-tab">...</a>):(<></>)} 
                                                         </div> :                                    
                                                          <div className="points">
                                                           
                                                          {product.multi_description && product.multi_description.arabic.substring(0, 150)}
                                                          {product.multi_description && (product.multi_description.arabic.length>150)?(<a href="#product-detail-tab">...</a>):(<></>)} 
                                                      </div>
                                                    
                                                        }
                                                       
                                                    </div>

                                                    <div className="list">
                                                        <div className="heading">{languageList['Seller'][lang]}</div>
                                                        <div className="points">
                                                            <h4>{vendor ? vendor.name : ''}</h4>
                                                            <ul>
                                                             {product.est_shipping_days!=0 ? <li>{languageList['Estimated_Shipping_Days'][lang]} {product.est_shipping_days} </li> : ''  }
                                                                {(product.is_return==1)?<li>{product.return_days} {languageList['Days_Return_Policy'][lang]}</li>:<li>{languageList['No_Return'][lang]}</li>}
                                                                <li>{languageList['Shipping_Cost'][lang]} AED {product.shipping_cost !=0 ? product.shipping_cost : 0 }</li>
                                                                <li>{languageList['Tax'][lang]} {product.tax_type=='amount' ?  product.tax +' AED' :  product.tax +' %' }</li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                </div>

                                        </div>
                                        <div className="product-collateral">
                                            <div className="add_info">
                                                <ul id="product-detail-tab" className="nav nav-tabs product-tabs">
                                                    <li className="active"> <a href="#product_tabs_description" data-toggle="tab"> {languageList['Product_Description'][lang]} </a> </li>
                                                    {product.is_variation == 1 ? <li><a href="#product_tabs_tags" data-toggle="tab">{languageList['Specifications'][lang]}</a></li> : ''}
                                                    <li> <a href="#reviews_tabs" data-toggle="tab">{languageList['Reviews'][lang]}</a> </li>
                                                    {/* <li> <a href="#product_tabs_custom" data-toggle="tab">Custom Tab</a> </li> */}
                                                    {/* <li> <a href="#product_tabs_custom1" data-toggle="tab">Questions & Answers</a> </li> */}
                                                </ul>
                                                <div id="productTabContent" className="tab-content">
                                                    <div className="tab-pane fade in active" id="product_tabs_description">
                                                        <div className="std">
                                                            <h2>{languageList['Product_Description'][lang]}</h2>
                                                            {lang == 'en'? 
                                                             <div dangerouslySetInnerHTML={{__html:product.detail_description }}></div>:
                                                             
                                                            product.multi_detail_description && <div dangerouslySetInnerHTML={{__html:product.multi_detail_description.arabic }}></div>
                                                            }
                                                           
                                                        </div>
                                                    </div>
                                                    {product.is_variation == 1 ?
                                                        <div className="tab-pane fade" id="product_tabs_tags">
                                                            <div className="box-collateral box-spec">
                                                                <h3>Product Variants</h3>

                                                                <table width="100%" border="0" cellspacing="0" cellpadding="0" className="spec-tbl">
                                                                    <colgroup>
                                                                        <col width="20%">
                                                                        </col>
                                                                    </colgroup>
                                                                    {product.variation_attribute_item && product.variation_attribute_item.map((item, index) => {

                                                                        return (
                                                                            <tr key={index}>
                                                                                <th>{item.attribute_name}</th>

                                                                                {item.items && item.items.map((row, index1) => {
                                                                                    return (
                                                                                        <td key={index1}>{row.variation_value}</td>
                                                                                    )
                                                                                })}
                                                                            </tr>
                                                                        )
                                                                    })}

                                                                </table>
                                                            </div>

                                                        </div> : ''}
                                                    <div className="tab-pane fade" id="reviews_tabs">
                                                        <div className="woocommerce-Reviews">
                                                            <div>
                                                                {/* <h2 className="woocommerce-Reviews-title">{product.reviews ? product.reviews.length : 0} reviews for <span>{product.product_name}</span></h2> */}
                                                                <ol className="commentlist" style={{ maxHeight: '700px', overflow: 'auto' }}>

                                                                    {product.reviews && product.reviews.map((item, index) => {

                                                                        return <ReviewMessage review={item} />

                                                                    })}

                                                                </ol>
                                                            </div>

                                                            <ProductReview product={product} reviewChange={reviewChange} />

                                                            <div className="clear"></div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="product_tabs_custom">
                                                        <div className="product-tabs-content-inner clearfix">
                                                            <p><strong>Lorem Ipsum</strong><span>&nbsp;is
                                                                simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                                                has been the industry's standard dummy text ever since the 1500s, when
                                                                an unknown printer took a galley of type and scrambled it to make a type
                                                                specimen book. It has survived not only five centuries, but also the
                                                                leap into electronic typesetting, remaining essentially unchanged. It
                                                                was popularised in the 1960s with the release of Letraset sheets
                                                                containing Lorem Ipsum passages, and more recently with desktop
                                                                publishing software like Aldus PageMaker including versions of Lorem
                                                                Ipsum.</span></p>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="product_tabs_custom1">
                                                        <div className="product-tabs-content-inner clearfix">
                                                            <div className="faq">
                                                                <ul>
                                                                    <li>
                                                                        <strong>Is there any issue in display with color shifting? And is the body metallic?</strong>
                                                                        <p>Featuring a stylish and stunning design, this laptop from Vaio is a must-buy device that perfectly complements a modern corporate leader’s lifestyle. Aliquam laoreet consequat malesuada. Integer vitae diam sed dolor euismod laoreet eget ac felis. </p>
                                                                        <span>Jhon Doe</span>
                                                                    </li>
                                                                    <li>
                                                                        <strong>Is there any heating issue which is not so normal?</strong>
                                                                        <p>Donec eu cursus velit. Proin nunc lacus, gravida mollis dictum ut, vulputate eu turpis. Sed felis sapien, commodo in iaculis in, feugiat sed enim. Sed nunc ipsum, fermentum varius dignissim vitae, blandit et ante.</p>
                                                                        <span>Saraha Smith</span>
                                                                    </li>
                                                                    <li>
                                                                        <strong>Can i install external graphics card?</strong>
                                                                        <p> Integer vitae diam sed dolor euismod laoreet eget ac felis. Donec non erat sed elit bibendum sodales. Donec eu cursus velit.</p>
                                                                        <span>Stephen Doe</span>
                                                                    </li>
                                                                    <li>
                                                                        <strong>It is useful for coding or not?</strong>
                                                                        <p>Sed felis sapien, commodo in iaculis in, feugiat sed enim. Sed nunc ipsum, fermentum varius dignissim vitae, blandit et ante.</p>
                                                                        <span>Mark Doe</span>
                                                                    </li>
                                                                    <li>
                                                                        <strong>What is the average battery life?</strong>
                                                                        <p>Aliquam laoreet consequat malesuada. Integer vitae diam sed dolor euismod laoreet eget ac felis. Donec non erat sed elit bibendum sodales.</p>
                                                                        <span>Jhon Doe</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <!-- similar products --> */}

                                        <BestSeller featured={relatedProducts}  cartEvent={cartEvent} title={languageList['RELATED_PRODUCTS'][lang]} />

                                        {/* <!-- End similar products -->  */}

                                    </div>
                                </article>
                                {/* <!--	======    End article  =========  -->  */}
                            </div>

                        </div>
                    </div>
                </section>
                {/* <!-- Main Container End --> */}

                {/* <!-- Footer --> */}
                <Footer />
                {/* <!-- End Footer -->  */}
            </div>
            {/* <!-- mobile menu --> */}
            <MobileMenu />

            <MobileMenuIcons />

            {/* <!-- JavaScript -->  */}

        </>
    );
};

export default Index;