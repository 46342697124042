import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { languageList } from '../../languageHelper';
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';

class BreadCrumb extends Component {
    constructor(props) {
        super(props);
        
    }
    
    render() {

        let {title} = this.props;

        return (
            <>
                <div className="breadcrumbs">
                    <ul>
                        <li className="home"> <Link to="/" title="Go to Home Page">{languageList['home'][lang]}</Link> <span>/</span> </li>
                        <li> <strong>{title}</strong> </li>
                    </ul>
                </div>
            </>
        );
    }
}

export default BreadCrumb;