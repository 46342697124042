import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { PUBLIC_URL } from '../../common/CommonString';

let ciphertext = localStorage.getItem('user_token') ? localStorage.getItem('user_token') : '';

const ProductReview = (props) => {

    let { product } = props;

    const [ratting, setRatting] = useState('');
    const [review, setReview] = useState('');
    const [firstLoad, setFirstLoad] = useState(true);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const changeRatting = (value) => {

        if(ciphertext !== ''){
            setRatting(value);
        }
        else{
            toast.error("Please login before review product", {
                position: "top-right",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }
    }

    useEffect(() => {
        
        if (firstLoad == true) {

            let formData = new FormData();
            formData.append('user_id', 1);

            axios({
                url: `${PUBLIC_URL}/getprofile`,
                method: 'POST',
                headers: { Authorization: "Bearer " + ciphertext },
                data: formData,

            }).then(response => {

                if (response.data.status == 1) {

                    setName(response.data.data.name);
                    setEmail(response.data.data.email);
                }

            }).catch((error) => { });
        }

        setFirstLoad(false);
    });

    const reviewComment = (e) => {

        setReview(e.target.value);
    }

    const handleSubmit = () => {
                
        if (review !== '' && ratting !== '') {

            let formData = new FormData();
            formData.append('vendor_id', product.vendor_id);
            formData.append('product_id', product.id);
            formData.append('user_id', 1);
            formData.append('ratting', ratting);
            formData.append('comment', review);

            axios({
                url: `${PUBLIC_URL}/addratting`,
                method: 'POST',
                headers: { Authorization: "Bearer " + ciphertext },
                data: formData,

            }).then(response => {

                if (response.data.status == 1) {

                    toast.success("Review is placed!", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    props.reviewChange();

                    setRatting('');
                    setReview('');
                }
                else{

                    toast.error(response.data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

            }).catch((error) => { });
        }
        else {
               toast.error("Please rate before review product", {
                position: "top-right",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }
    }

    const btnStyle = {
        display: 'inline-block',
        height: '44px',
        lineHeight: '44px',
        fontSize: '14px',
        fontWeight: '600',
        color: "#fff",
        padding: '0 35px',
        borderRadius: '3px',
        background: '#2874f0',
        border: 'none',
        width: 'auto'
    }

    return (
        <div>
            <div>
                <div className="comment-respond">
                    <span className="comment-reply-title">Add a review </span>
                    <form className="comment-form" novalidate="">
                        <p className="comment-notes"><span id="email-notes">Your email address will not be published.</span> Required fields are marked <span className="required">*</span></p>
                        <div className="comment-form-rating">
                            <label id="rating">Your rating</label>
                            <p className="stars">
                                <span>
                                    <a className="star-1" style={ratting == 1 ? { color: '#fdd922' } : {}} onClick={() => changeRatting(1)} href="javascript:void(0);">1</a>
                                    <a className="star-2" style={ratting == 2 ? { color: '#fdd922' } : {}} onClick={() => changeRatting(2)} href="javascript:void(0);">2</a>
                                    <a className="star-3" style={ratting == 3 ? { color: '#fdd922' } : {}} onClick={() => changeRatting(3)} href="javascript:void(0);">3</a>
                                    <a className="star-4" style={ratting == 4 ? { color: '#fdd922' } : {}} onClick={() => changeRatting(4)} href="javascript:void(0);">4</a>
                                    <a className="star-5" style={ratting == 5 ? { color: '#fdd922' } : {}} onClick={() => changeRatting(5)} href="javascript:void(0);">5</a>
                                </span>
                            </p>
                        </div>
                        <p className="comment-form-comment">
                            <label>Your review <span className="required">*</span></label>
                            <textarea id="comment" onChange={reviewComment} name="comment" cols="45" rows="8" required="">{review}</textarea>
                        </p>
                        <p className="comment-form-author">
                            <label for="author">Name <span className="required">*</span></label>
                            <input id="author" name="author" type="text" onChange={(e)=>setName(e.target.value)} value={name} size="30" required="" /></p>
                        <p className="comment-form-email">
                            <label for="email">Email <span className="required">*</span></label>
                            <input id="email" name="email" value={email} onChange={(e)=>setEmail(e.target.value)} type="email" size="30" required="" /></p>
                        <div className="form-submit">
                            <button type="button" onClick={handleSubmit} className="btn btn-primary" style={btnStyle}>Submit</button>
                            {/* <input name="submit" onClick={handleSubmit} type="submit" id="submit" className="submit" value="Submit" /> */}
                        </div>
                    </form>
                </div>
                {/* <!-- #respond --> */}
            </div>
        </div>
    );
};

export default ProductReview;