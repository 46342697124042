import axios from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PUBLIC_URL } from '../common/CommonString';

let ciphertext = localStorage.getItem('user_token') ? localStorage.getItem('user_token') : '';
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';

const ProductItemWithDetails = (props) => {

    let {item} = props;

    const addToCart = () => {

        let taxvalue = 0;
        let proPrice=item.discounted_price == 0 || item.discounted_price == null || item.discounted_price == 'null' ? item.product_price : item.discounted_price;
        if(item.tax_type === 'percent'){

            taxvalue =proPrice * (item.tax / 100);

        }
        else{
            taxvalue = item.tax;
        }
          let cart = JSON.parse(localStorage.getItem('mycart'));
    if(!cart)
    {
       cart=[];
    }
        if ((ciphertext === '')||(ciphertext==='null'))
        {
        let added=false;
      cart.forEach(function(cartItem,index){
          if(cartItem.product_id===item.id)
          {
              cart[index].qty++;
              added=true;
          }
      });
      if(!added)
      {
        let NewItem={};
        NewItem.user_id=1;
        NewItem.vendor_id=item.vendor_id;
        
        NewItem.product_id=item.id;
        NewItem.product_name=item.product_name;
        NewItem.qty=1;
        NewItem.price=proPrice;
        NewItem.discount_amount=0;
         NewItem.tax=taxvalue;
        NewItem.shipping_cost=item.shipping_cost;
        NewItem.variation='null';
        NewItem.attribute='null';
        NewItem.variations=[];
        NewItem.image_url=item.productimagewithout_url.image_url;
        NewItem.productObject=item;
          cart.push(NewItem);
         toast.success("Product is added to cart!", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
           localStorage.setItem('mycart',JSON.stringify(cart));    
          props.cartEvent();
      }
      
       }else{
        let formData = new FormData();
        formData.append('user_id', 1);
        formData.append('product_id', item.id);
        formData.append('vendor_id', item.vendor_id);
        formData.append('product_name', item.product_name);
        formData.append('qty', 1);
        formData.append('price', item.discounted_price == 0 || item.discounted_price == null || item.discounted_price == 'null' ? item.product_price : item.discounted_price);
        formData.append('image', item.productimagewithout_url.image_url);
        formData.append('discount_amount', 0);
        formData.append('tax', taxvalue);
        formData.append('shipping_cost', item.shipping_cost);

        axios({
            url: `${PUBLIC_URL}/addtocart`,
            method: 'POST',
            headers: { Authorization: "Bearer " + ciphertext },
            data: formData,

        }).then(response => {

            if (response.data.status == 1) {

                toast.success("Product is added to cart!", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                props.cartEvent();
            }else{
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            }

        }).catch((error) => { });
       }
    }

    const addToWishlist = () => {
   if ((ciphertext === '')||(ciphertext==='null')) {
        toast.error("Please login before added to wishlist!", {
                position: "top-right",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
           return;
       }
        let formData = new FormData();
        formData.append('user_id', 1);
        formData.append('product_id', item.id);

        axios({
            url: `${PUBLIC_URL}/addtowishlist`,
            method: 'POST',
            headers: { Authorization: "Bearer " + ciphertext },
            data: formData,

        }).then(response => {

            if (response.data.status == 1) {

                toast.success("Product is added to wishlist!", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                
            }

        }).catch((error) => { });
    }

    let rating = item.rattings.length > 0 ? item.rattings[0].avg_ratting : 0;

    return (
        <div className="item">
            <div className="item-inner">
                <div className="item-img">
                    <div className="item-img-info">
                        <Link className="product-image" title={item.product_name} to={`/product-details?product=${item.id}`}>
                        <div style={{height: '200px', width: '200px',backgroundSize:'contain',backgroundRepeat:'no-repeat',backgroundPosition:'center',backgroundImage:`url("${item.productimage.image_url}")`}} ></div>
                            {/* <img alt="" src={item.productimage.image_url} className="myfeatured-products" /> */}
                        </Link>
                        {/* <div className="new-label new-top-left">new</div> */}
                        <div className="box-hover">
                            <ul className="add-to-links">
                                <li><Link className="link-quickview" to={`/product-details?product=${item.id}`}></Link> </li>
                                <li><a className="link-wishlist" onClick={addToWishlist} href="javascript:void(0);"></a></li>
                                {/* <li><a className="link-compare" href="compare.html"></a> </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="item-info">
                    <div className="info-inner">
                        <div className="item-title"> <Link title={item.product_name} to={`/product-details?product=${item.id}`} style={{color: '#000'}}>{lang=='en'? item.product_name ? item.product_name.substring(0, 50) + '...' : '' : item.multi_name ? item.multi_name.arabic.substring(0, 50) + '...' : item.product_name}</Link> </div>
                        <div className="brand">{item.brand_name}</div>
                        <div className="star-rating">
                            <span style={{ width: `${rating * 20}%` }}>Rated <strong className="rating">{rating}</strong> out of 5</span>
                        </div>
                        <div className="item-content">
                            <div className="item-price">
                                <div className="price-box"> <span className="regular-price"> <span className="price">AED {item.discounted_price}</span> </span> </div>
                            </div>
                            <div className="action">
                                <button className="button btn-cart" onClick={addToCart} type="button" title="" data-original-title="Add to Cart"><i className="fa fa-shopping-basket"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductItemWithDetails;