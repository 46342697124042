import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NewsLetter from './NewsLetter';
import { languageList } from '../../../languageHelper';

let ciphertext = localStorage.getItem('user_token') ? localStorage.getItem('user_token') : 'null';
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';

class Footer extends Component {
    render() {

        let year = new Date().getFullYear();
        return (
            <footer className="footer">
                
                <NewsLetter />
                {/* <!--newsletter--> */}

                <div className="footer-middle">
                    <div className="container">
                    <div className="row">
                      <div className="col-xs-12 col-sm-2">
                      <br/>
                      <center>
                        {/* <img alt="Citroen" src="/images/logo.png" className="myfooter-logo" style={{width:'80%'}}/> */}
                      <h3 className="myfooter-text" >Single Vendor</h3></center>
                        </div>
                    <div className="col-xs-12 col-md-10">
                        
                        <div className="row">
                            <div className="col-md-3 col-sm-12">
                                <div className="footer-column pull-left">
                                    <h4>{languageList['PROFILE'][lang]}</h4>
                                    <ul className="links">
                                        {/* <li><a href="blog.html" title="How to buy">Blog</a></li> */}
                                        {/* <li><Link to="#" title="FAQs">FAQs</Link></li>
                                        <li><Link to="#" title="Payment">Payment</Link></li>
                                        <li><Link to="#" title="Shipment">Shipment</Link></li>
                                        <li><Link to="#" title="Where is my order?">Where is my order?</Link></li>
                                        <li><Link to="#" title="Return policy">Return policy</Link></li> */}
                                         <li><Link to={(ciphertext&&(ciphertext!='null'))?'/profile':''} title="Your Account">{languageList['Your_Account'][lang]}</Link></li>
                                         <li><Link to={(ciphertext&&(ciphertext!='null'))?'/address':''} title="Addresses">{languageList['Addresses'][lang]}</Link></li>
                                         <li><Link to={(ciphertext&&(ciphertext!='null'))?'/wishlist':''}  title="Wishlist">{languageList['Wishlist'][lang]}</Link></li>
                                         <li><Link to={(ciphertext&&(ciphertext!='null'))?'/orders':''}  title="Orders History">{languageList['Orders_History'][lang]}</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="app-footer-img">
                                    <a href="#" alt="" title="App store" class="mr-20 ">
                                        <img src="images/g-play.png" alt=""/>
                                    </a>
                                    <a href="#" alt="" title="Play store">
                                        <img src="images/appstore.png"/>
                                    </a>
                                </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="footer-column pull-left">
                                        <h4>{languageList['PROFILE'][lang]}</h4>
                                        <ul className="links">
                                            <li><Link to="#" title="About Us">{languageList['About_Us'][lang]}</Link></li>
                                            <li><Link to="#" title="Contact Us">{languageList['Contact_Us'][lang]}</Link></li>
                                            
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="footer-column pull-left">
                                        <h4>{languageList['INFO'][lang]}</h4>
                                        <ul className="links">
                                            {/* <li><Link to="/contact" title="Site Map">Site Map</Link></li> */}
                                            {/* <li><a href="#" title="Search Terms">Search Terms</a></li>
                                            <li><a href="#" title="Advanced Search">Advanced Search</a></li> */}
                                            
                                            {/* <li><a href="https://admin.buyforless.ae/vendor-signup" title="Suppliers" className="blink"><b id="">{languageList['Sell_your_products_here'][lang]}</b></a></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <h4>{languageList['Contact_Us'][lang]}</h4>
                                <div className="contacts-info">
                                    <address>
                                        <i className="email-icon"></i><a href="mailto:support@buyforless.ae">support@singleVendor.ae</a>
                                        <br />
                                    </address>
                                    <div className="email-footer" style={{paddingBottom:'0px'}}><i className="email-icon"></i><a href="mailto:info@buyforless.ae">info@singleVendor.ae</a></div>
                                    <div className="phone-footer"><i className="phone-icon"></i>+ 971 000000000</div>
                                    <a style={{textDecoration: 'none'}} href="https://wa.me/971000000000" target="_blank"><div className="whatsapp-footer"><i className="whatsapp-icon"></i>+ 971 000000000</div></a>

                                </div>
                            </div>
                        </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                      
                        <div className="col-xs-12 col-sm-12">
                            <div className="social">
                                <ul>
                                    <li className="fb"><a href="https://wa.me/97105437727587"></a></li>
                                    <li className="tw"><a href="#"></a></li>
                                    <li className="googleplus"><a href="#"></a></li>
                                    <li className="rss"><a href="#"></a></li>
                                    <li className="pintrest"><a href="#"></a></li>
                                    <li className="linkedin"><a href="#"></a></li>
                                    <li className="youtube"><a href="#"></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-12 col-xs-12 coppyright">Copyright &copy; {year} SingleVendor. all rights reserved. </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;