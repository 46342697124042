import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

let ciphertext = localStorage.getItem('user_token') ? localStorage.getItem('user_token') : '';
class MiniCart extends Component {

    constructor(props) {
        super(props);

    }

    removeItem = (id) => {

        this.props.cartAction(id);
    }

    render() {

        let { cart } = this.props;
        let total = 0;

        return (
            <>
                <div className="top-cart-content">
                    {/* <!--block-subtitle--> */}
                    {cart.length == 0 ? <><br /><br /><div className="text-center">No Data!</div></> : ''}
                    <ul className="mini-products-list" id="cart-sidebar" style={{maxHeight:"500px",overflowY:"auto"}}>
                        {cart && cart.map((item, index) => {

                            return (
                                <li className="item first" key={index}>
                                    <div className="item-inner">
                                        <a className="product-image" title="Retis lapen casen" href="#l">
                                            <img alt={item.product_name} src={item.image_url} />
                                        </a>
                                        <div className="product-details">
                                            <div className="access">
                                                <a className="btn-remove1" onClick={() => this.removeItem(item.id?item.id:item.product_id)} title="Remove This Item" href="javascript:void(0);">Remove</a>
                                            </div>
                                            <p className="product-name">
                                                <a href="#">{item.product_name}</a>
                                                {item.variations && item.variations.map((row, index1) => {
                                                    return <div key={index1}>{row.attribute} : {row.variation}</div>
                                                })}
                                            </p>
                                            {item.qty} x &nbsp;
                                            <span className="price">AED {item.qty * item.price}</span>
                                        </div>
                                    </div>
                                </li>
                            );

                        })}

                    </ul>
                    {/* <!--actions--> */}
                    <div className="actions">
                        {cart.length == 0 ?
                            <button className="btn-checkout" title="Checkout" type="button"><span>Checkout</span> </button>
                            :(
                                ((ciphertext !== '')&&(ciphertext !== 'null'))?
                            (<Link to="/checkout">
                                <button className="btn-checkout" title="Checkout" type="button"><span>Checkout</span> </button>
                            </Link>)
                    :(<button className="btn-checkout" title="Checkout" type="button"
                      onClick={()=>{
                          localStorage.setItem('cus_redirect','checkout');
                           window.jQuery('#myModalsignin').modal('show');
                          }}>
                                  <span>Checkout</span> </button>))
                        }
                        <Link to="/cart" className="view-cart"><span>View Cart</span></Link>
                    </div>
                </div>
            </>
        );
    }
}

export default MiniCart;