import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../common/layout/Footer';
import Header from '../common/layout/Header';
import MobileMenu from '../common/layout/MobileMenu';
import MobileMenuIcons from '../common/layout/MobileMenuIcons';

const Payment = () => {
    return (
        <>
            <div id="page">
                {/* <!-- Header --> */}
                <Header />
                {/* <!-- end header -->  */}

                {/* <!-- Main Container --> */}
                <section className="main-container col1-layout">
                    <div className="container">
                        {/* <!-- Breadcrumbs --> */}
                        <div className="breadcrumbs">
                            <ul>
                                <li className="home"> <Link to="/" title="Go to Home Page">Home</Link> <span>/</span> </li>
                                <li> <strong>Payment</strong> </li>
                            </ul>
                        </div>
                        {/* <!-- Breadcrumbs End --> */}
                        <div className="row">
                            <div className="col-sm-12 col-xs-12">
                                <article className="col-main">
                                    <div className="cart">
                                        <div className="page-title">
                                            <h2>Payment</h2>
                                        </div>
                                        <br /><br />
                                        <div className="row">
                                            <div className="col-sm-12 col-xs-12">
                                                <div className="mb-3">
                                                    <h4><b>Lorem ipsum dolor sit amet?</b></h4>
                                                    <hr />
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ita credo. Paria sunt </p>
                                                    <p>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ita credo. Paria sunt igitur. 
                                                        Qui ita affectus, beatum esse numquam probabis; Sumenda potius quam expetenda. Que Manilium, 
                                                        ab iisque M. Quae diligentissime contra Aristonem dicuntur a Chryippo
                                                    </p>
                                                </div>
                                                <br />
                                                <div className="mb-3">
                                                    <h4><b>Lorem ipsum dolor sit amet?</b></h4>
                                                    <hr />
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ita credo. Paria sunt </p>
                                                    <p>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ita credo. Paria sunt igitur. 
                                                        Qui ita affectus, beatum esse numquam probabis; Sumenda potius quam expetenda. Que Manilium, 
                                                        ab iisque M. Quae diligentissime contra Aristonem dicuntur a Chryippo
                                                    </p>
                                                </div>                                                
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Main Container End --> */}

                {/* <!-- Footer --> */}
                <Footer />
                {/* <!-- End Footer -->  */}
            </div>
            {/* <!-- mobile menu --> */}
            <MobileMenu />

            <MobileMenuIcons />

            {/* <!-- JavaScript -->  */}
        </>
    );
};

export default Payment;