import React from 'react';
import { Link } from 'react-router-dom';
import { languageList } from '../../languageHelper';
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
const BannerWithMiddle = (props) => {

    let { banner } = props;

    return (
        <section className="u2n">
            <div className="container">
                <div className="row">
                    {banner && banner.map((item, index) => {

                        if(item.type === 'category'){

                            return (

                                <div className="col-md-6 col-sm-3 col-lg-3" key={index}>
                                    <div className="yh52d">
                                        <div className="kj2d">
                                            <img src={item.image} alt="" />
                                        </div>
                                        <div className="kj1d">
                                            <h3>{lang=='en'?item.title:item.multi_name? item.multi_name.arabic :item.title}</h3>
                                            <Link to={`/product-grid?category=${item.cat_id}&innersubcategory=&subcategory=&category_name=${item.category ? item.category.category_name : ''}`}> {languageList['Shop_now'][lang]}<i className="fa fa-arrow-circle-right"></i></Link>
                                        </div>
                                    </div>
                                </div>

                            );
                        }
                        else if(item.type === 'product'){

                            return (

                                <div className="col-md-6 col-sm-3 col-lg-3" key={index}>
                                    <div className="yh52d">
                                        <div className="kj2d">
                                            <img src={item.image} alt="" />
                                        </div>
                                        <div className="kj1d">
                                            <h3>{lang=='en'?item.title:item.multi_name? item.multi_name.arabic :item.title}</h3>
                                            <Link to={`/product-details?product=${item.product_id}`}>{languageList['Shop_now'][lang]}<i className="fa fa-arrow-circle-right"></i></Link>
                                        </div>
                                    </div>
                                </div>

                            );

                        }
                        else{

                            return (

                                <div className="col-md-6 col-sm-3 col-lg-3" key={index}>
                                    <div className="yh52d">
                                        <div className="kj2d">
                                            <img src={item.image} alt="" />
                                        </div>
                                        <div className="kj1d">
                                            <h3>{lang=='en'?item.title:item.multi_name? item.multi_name.arabic :item.title}</h3>
                                            {/* <a href="#">Shop Now <i className="fa fa-arrow-circle-right"></i></a> */}
                                        </div>
                                    </div>
                                </div>

                            );
                        }
                    })}

                </div>
            </div>
        </section>
    );
};

export default BannerWithMiddle;