import React, { Component } from 'react';

class Recommended extends Component {
    render() {
        return (
            <>
                <div className="container">
                    <div className="bestsell-pro mt-0 mb-60">
                    <div>
                        <div className="slider-items-products">
                        <div className="bestsell-block">
                            <div className="block-title">
                            <h2>Recommended</h2>
                            </div>
                            <div id="bestsell-slider" className="product-flexslider hidden-buttons">
                            <div className="slider-items slider-width-col4 products-grid block-content">
                                <div className="item">
                                <div className="item-inner">
                                    <div className="item-img">
                                    <div className="item-img-info"> <a className="product-image" title="Retis lapen casen"
                                        href="product_detail.html"> <img alt="" src="products-images\product12.jpg" /> </a>
                                        <div className="new-label new-top-left">new</div>
                                        <div className="box-hover">
                                        <ul className="add-to-links">
                                            <li><a className="link-quickview" href="quick_view.html"></a> </li>
                                            <li><a className="link-wishlist" href="wishlist.html"></a> </li>
                                            <li><a className="link-compare" href="compare.html"></a> </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="item-info">
                                    <div className="info-inner">
                                        <div className="item-title"> <a title="Retis lapen casen" href="product_detail.html">Anti Glare
                                            Side Narrow Border Display Laptop</a> </div>
                                        <div className="brand">Tonrex</div>
                                        <div className="star-rating">
                                        <span style={{width: '60%'}}>Rated <strong className="rating">3.00</strong> out of 5</span>
                                        </div>
                                        <div className="item-content">
                                        <div className="item-price">
                                            <div className="price-box"> <span className="regular-price"> <span className="price">$88.00</span>
                                            </span> </div>
                                        </div>
                                        <div className="action">
                                            <button className="button btn-cart" type="button" title=""
                                            data-original-title="Add to Cart"><i className="fa fa-shopping-basket"></i></button>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                {/* <!-- Item --> */}
                                <div className="item">
                                <div className="item-inner">
                                    <div className="item-img">
                                    <div className="item-img-info"> <a className="product-image" title="Retis lapen casen"
                                        href="product_detail.html"> <img alt="" src="products-images\product11.jpg" /> </a>
                                        <div className="box-hover">
                                        <ul className="add-to-links">
                                            <li><a className="link-quickview" href="quick_view.html"></a> </li>
                                            <li><a className="link-wishlist" href="wishlist.html"></a> </li>
                                            <li><a className="link-compare" href="compare.html"></a> </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="item-info">
                                    <div className="info-inner">
                                        <div className="item-title"> <a title="Retis lapen casen" href="product_detail.html"> Stovekraft
                                            Induction Stove with Feather touch </a> </div>
                                        <div className="brand">Unicorn</div>
                                        <div className="item-content">
                                        <div className="star-rating">
                                            <span style={{width: '60%'}}>Rated <strong className="rating">3.00</strong> out of 5</span>
                                        </div>
                                        <div className="item-price">
                                            <div className="price-box"> <span className="regular-price"> <span className="price">$325.00</span>
                                            </span> </div>
                                        </div>
                                        <div className="action">
                                            <button className="button btn-cart" type="button" title=""
                                            data-original-title="Add to Cart"><i className="fa fa-shopping-basket"></i></button>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                {/* <!-- End Item -->
                                <!-- Item --> */}
                                <div className="item">
                                <div className="item-inner">
                                    <div className="item-img">
                                    <div className="item-img-info"> <a className="product-image" title="Retis lapen casen"
                                        href="product_detail.html"> <img alt="" src="products-images\product10.jpg" /> </a>
                                        <div className="box-hover">
                                        <ul className="add-to-links">
                                            <li><a className="link-quickview" href="quick_view.html"></a> </li>
                                            <li><a className="link-wishlist" href="wishlist.html"></a> </li>
                                            <li><a className="link-compare" href="compare.html"></a> </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="item-info">
                                    <div className="info-inner">
                                        <div className="item-title"> <a title="Retis lapen casen" href="product_detail.html"> Home
                                            Security Camera with Alarm System </a> </div>
                                        <div className="brand">Harrier</div>
                                        <div className="item-content">
                                        <div className="star-rating">
                                            <span style={{width: '60%'}}>Rated <strong className="rating">3.00</strong> out of 5</span>
                                        </div>
                                        <div className="item-price">
                                            <div className="price-box"> <span className="regular-price"> <span className="price">$245.00</span>
                                            </span> </div>
                                        </div>
                                        <div className="action">
                                            <button className="button btn-cart" type="button" title=""
                                            data-original-title="Add to Cart"><i className="fa fa-shopping-basket"></i></button>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                {/* <!-- End Item --> */}
                                <div className="item">
                                <div className="item-inner">
                                    <div className="item-img">
                                    <div className="item-img-info"> <a className="product-image" title="Retis lapen casen"
                                        href="product_detail.html"> <img alt="" src="products-images\product6.jpg" /> </a>
                                        <div className="new-label new-top-left">new</div>
                                        <div className="box-hover">
                                        <ul className="add-to-links">
                                            <li><a className="link-quickview" href="quick_view.html"></a> </li>
                                            <li><a className="link-wishlist" href="wishlist.html"></a> </li>
                                            <li><a className="link-compare" href="compare.html"></a> </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="item-info">
                                    <div className="info-inner">
                                        <div className="item-title"> <a title="Retis lapen casen" href="product_detail.html"> Fitness
                                            Smartwatch with Heart Rate Monitor </a> </div>
                                        <div className="brand">Cruiser</div>
                                        <div className="star-rating">
                                        <span style={{width: '60%'}}>Rated <strong className="rating">3.00</strong> out of 5</span>
                                        </div>
                                        <div className="item-content">
                                        <div className="item-price">
                                            <div className="price-box"> <span className="regular-price"> <span className="price">$88.00</span>
                                            </span> </div>
                                        </div>
                                        <div className="action">
                                            <button className="button btn-cart" type="button" title=""
                                            data-original-title="Add to Cart"><i className="fa fa-shopping-basket"></i></button>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                {/* <!-- Item --> */}
                                <div className="item">
                                <div className="item-inner">
                                    <div className="item-img">
                                    <div className="item-img-info"> <a className="product-image" title="Retis lapen casen"
                                        href="product_detail.html"> <img alt="" src="products-images\product8.jpg" /> </a>
                                        <div className="box-hover">
                                        <ul className="add-to-links">
                                            <li><a className="link-quickview" href="quick_view.html"></a> </li>
                                            <li><a className="link-wishlist" href="wishlist.html"></a> </li>
                                            <li><a className="link-compare" href="compare.html"></a> </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="item-info">
                                    <div className="info-inner">
                                        <div className="item-title"> <a title="Retis lapen casen" href="product_detail.html">Mixer
                                            Grinder with 3 Stainless Steel Jar</a> </div>
                                        <div className="brand">Flipmart</div>
                                        <div className="star-rating">
                                        <span style={{width: '60%'}}>Rated <strong className="rating">3.00</strong> out of 5</span>
                                        </div>
                                        <div className="item-content">
                                        <div className="item-price">
                                            <div className="price-box"> <span className="regular-price"> <span className="price">$88.00</span>
                                            </span> </div>
                                        </div>
                                        <div className="action">
                                            <button className="button btn-cart" type="button" title=""
                                            data-original-title="Add to Cart"><i className="fa fa-shopping-basket"></i></button>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                {/* <!-- End Item --> */}
                                <div className="item">
                                <div className="item-inner">
                                    <div className="item-img">
                                    <div className="item-img-info"> <a className="product-image" title="Retis lapen casen"
                                        href="product_detail.html"> <img alt="" src="products-images\product7.jpg" /> </a>
                                        <div className="box-hover">
                                        <ul className="add-to-links">
                                            <li><a className="link-quickview" href="quick_view.html"></a> </li>
                                            <li><a className="link-wishlist" href="wishlist.html"></a> </li>
                                            <li><a className="link-compare" href="compare.html"></a> </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="item-info">
                                    <div className="info-inner">
                                        <div className="item-title"> <a title="Retis lapen casen" href="product_detail.html">5 Star
                                            Direct Cool Single Door Refrigerator</a> </div>
                                        <div className="brand">Nexus</div>
                                        <div className="star-rating">
                                        <span style={{width: '60%'}}>Rated <strong className="rating">3.00</strong> out of 5</span>
                                        </div>
                                        <div className="item-content">
                                        <div className="item-price">
                                            <div className="price-box"> <span className="regular-price"> <span className="price">$88.00</span>
                                            </span> </div>
                                        </div>
                                        <div className="action">
                                            <button className="button btn-cart" type="button" title=""
                                            data-original-title="Add to Cart"><i className="fa fa-shopping-basket"></i></button>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                {/* <!-- Item --> */}
                                <div className="item">
                                <div className="item-inner">
                                    <div className="item-img">
                                    <div className="item-img-info"> <a className="product-image" title="Retis lapen casen"
                                        href="product_detail.html"> <img alt="" src="products-images\product9.jpg" /> </a>
                                        <div className="box-hover">
                                        <ul className="add-to-links">
                                            <li><a className="link-quickview" href="quick_view.html"></a> </li>
                                            <li><a className="link-wishlist" href="wishlist.html"></a> </li>
                                            <li><a className="link-compare" href="compare.html"></a> </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="item-info">
                                    <div className="info-inner">
                                        <div className="item-title"> <a title="Retis lapen casen" href="product_detail.html">Direct
                                            Wireless Network Laser Printer</a> </div>
                                        <div className="brand">Dealsdot</div>
                                        <div className="star-rating">
                                        <span style={{width: '60%'}}>Rated <strong className="rating">3.00</strong> out of 5</span>
                                        </div>
                                        <div className="item-content">
                                        <div className="item-price">
                                            <div className="price-box"> <span className="regular-price"> <span className="price">$88.00</span>
                                            </span> </div>
                                        </div>
                                        <div className="action">
                                            <button className="button btn-cart" type="button" title=""
                                            data-original-title="Add to Cart"><i className="fa fa-shopping-basket"></i></button>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                {/* <!-- End Item --> */}
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Recommended;