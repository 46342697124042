import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PUBLIC_URL } from '../common/CommonString';
import { Range, getTrackBackground } from "react-range";
import { withRouter } from '../common/layout/withRouter';
import { languageList } from '../../languageHelper';
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';

class LeftBar extends Component {

    constructor(props) {
        super(props);
           const factoryOutlet=(props.hasOwnProperty('factoryOutlet'))?props.factoryOutlet:0;
        this.state = {
            category: [],
            brand: [],
            brandId: [],
            ratting: [],
            priceFrom: '',
            priceTo: '',
            type: '',
            values: [0, 10000],
            category_id: '',
            factoryOutlet:factoryOutlet
        }

    }

    componentDidMount = () => {

        let category = ((new URLSearchParams(this.props.location.search).get('category')) != '') ? (new URLSearchParams(this.props.location.search).get('category')) : '';
        this.setState({
            category_id: category,
        });

        axios({
            url: `${PUBLIC_URL}/categoryWithSubcategory`,
            method: 'POST',

        }).then(response => {

            if (response.data.status == 1) {

                this.setState({
                    category: response.data.category,
                });
            }

        }).catch((error) => {

        });

        if (typeof (category) === 'string' && category !== '') {

            let formData = new FormData();
            formData.append('category_id', category);

            axios({
                url: `${PUBLIC_URL}/categorybrands`,
                method: 'POST',
                data: formData

            }).then(response => {

                if (response.data.status == 1) {

                    this.setState({
                        brand: response.data.brand,
                    });
                }

            }).catch((error) => { });

        }
        else {

            axios({
                url: `${PUBLIC_URL}/brands`,
                method: 'GET',
            }).then(response => {

                if (response.data.status == 1) {

                    this.setState({
                        brand: response.data.vendors,
                    });
                }

            }).catch((error) => { });
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {

        let category = ((new URLSearchParams(nextProps.location.search).get('category')) != '') ? (new URLSearchParams(nextProps.location.search).get('category')) : '';
            // this.setState({
            //     category_id: category,
            // });
        
        // if (this.state.category_id != category && category != '') {
         if (category != '') {

            axios({
                url: `${PUBLIC_URL}/categoryWithSubcategory`,
                method: 'POST',

            }).then(response => {

                if (response.data.status == 1) {

                    this.setState({
                        category: response.data.category,
                    });
                }

            }).catch((error) => {

            });

            if (typeof (category) === 'string' && category !== '') {

                let formData = new FormData();
                formData.append('category_id', category);

                axios({
                    url: `${PUBLIC_URL}/categorybrands`,
                    method: 'POST',
                    data: formData

                }).then(response => {

                    if (response.data.status == 1) {

                        this.setState({
                            brand: response.data.brand,
                        });
                    }

                }).catch((error) => { });

            }
            else {
               
                axios({
                    url: `${PUBLIC_URL}/brands`,
                    method: 'GET',
                }).then(response => {

                    if (response.data.status == 1) {

                        this.setState({
                            brand: response.data.vendors,
                        });
                    }

                }).catch((error) => { });
            }
        }
        else{

            axios({
                url: `${PUBLIC_URL}/brands`,
                method: 'GET',
            }).then(response => {

                if (response.data.status == 1) {

                    this.setState({
                        brand: response.data.vendors,
                    });
                }

            }).catch((error) => { });
        }
    }

    brandCheck = (e, id) => {

        if (e.target.checked == true) {

            this.setState({
                brandId: [...this.state.brandId, id],
            }, () => {

                this.props.productFilter({
                    brandId: this.state.brandId,
                    ratting: this.state.ratting,
                    priceFrom: this.state.priceFrom,
                    priceTo: this.state.priceTo,
                    type: this.state.type,
                });
            });
        }
        else {
            this.setState({
                brandId: this.state.brandId.filter(item => {
                    if (item != id) {
                        return item;
                    }
                }),
            }, () => {
                
                this.props.productFilter({
                    brandId: this.state.brandId,
                    ratting: this.state.ratting,
                    priceFrom: this.state.priceFrom,
                    priceTo: this.state.priceTo,
                    type: this.state.type,
                });

                if(this.state.brandId.length == 0){
                    this.props.resetProps();
                }
            });
        }
    }

    rattingChange = (e, value) => {

        if (e.target.checked == true) {

            this.setState({
                ratting: [...this.state.ratting, value],
            }, () => {
                this.props.productFilter({
                    brandId: this.state.brandId,
                    ratting: this.state.ratting,
                    priceFrom: this.state.priceFrom,
                    priceTo: this.state.priceTo,
                    type: this.state.type,
                });
            });
        }
        else {
            this.setState({
                ratting: this.state.ratting.filter(item => {
                    if (value != item) {
                        return item;
                    }
                }),
            }, () => {
                this.props.productFilter({
                    brandId: this.state.brandId,
                    ratting: this.state.ratting,
                    priceFrom: this.state.priceFrom,
                    priceTo: this.state.priceTo,
                    type: this.state.type,
                });

                if(this.state.ratting.length == 0){
                    this.props.resetProps();
                }
            });
        }
    }

    priceRangeChange = (value) => {

        this.setState({
            values: value,
            priceFrom: value[0] != 0 ? value[0] : '',
            priceTo: value[1] != 10000 ? value[1] : '',
            type: 'price-range',
        });
    }

    applyPrice = () => {

        this.props.productFilter({
            brandId: this.state.brandId,
            ratting: this.state.ratting,
            priceFrom: this.state.priceFrom,
            priceTo: this.state.priceTo,
            type: this.state.type,
        });
        
    }

    render() {

        let { category, brand } = this.state;

        const STEP = 100;
        const MIN = 0;
        const MAX = 10000;
        const rtl = false;
        let barHeight = '5px';
        let iconHeight = '21px';
        let iconWidth = '21px';

        let type = this.props.type;
        let banner = this.props.banner;

        return (
            <>
                <aside className="col-left sidebar col-sm-3 col-xs-12 col-sm-pull-9">
                    <div className="widget widget-categories">
                        <div className="block-title">{languageList['BROWSE_CATEGORIES'][lang]}</div>
                        <div id="accordion" className="accordion">
                            {category && category.map((item, index) => {
                                return (
                                    <div className="card border-0 mb-2" key={index}>
                                        <div className="card-header">
                                            <h6 className="mb-0">
                                                <a className="link-title" data-toggle="collapse" data-parent="#accordion" href={`#collapse${index}`} aria-expanded="false">
                                                    {type === 'list' ? <Link to={`/product-list?category=${item.id}&innersubcategory=&subcategory=&category_name=${item.category_name}&factory_outlet=${this.state.factoryOutlet}`}>{lang=='en'?item.category_name:item.multi_name? item.multi_name.arabic :item.category_name}</Link> :
                                                        <Link to={`/product-grid?category=${item.id}&innersubcategory=&subcategory=&category_name=${item.category_name}&factory_outlet=${this.state.factoryOutlet}`}>{lang=='en'?item.category_name:item.multi_name? item.multi_name.arabic :item.category_name}</Link>}
                                                </a>
                                            </h6>
                                        </div>
                                        <div id={`collapse${index}`} className="collapse" data-parent="#accordion">
                                            <div className="card-body text-muted">
                                                <ul className="list-unstyled">
                                                    {item.subcategory && item.subcategory.map((item1, index1) => {

                                                        if (type === 'list') {
                                                            return (
                                                                <li key={index1}> <Link to={`/product-list?subcategory=${item1.id}&innersubcategory=&category=${item.id}&category_name=${item1.subcategory_name}`}>{lang=='en'?item1.subcategory_name:item1.multi_name? item1.multi_name.arabic :item1.subcategory_name}</Link></li>
                                                            );
                                                        }
                                                        else {
                                                            return (
                                                                <li key={index1}> <Link to={`/product-grid?subcategory=${item1.id}&innersubcategory=&category=${item.id}&category_name=${item1.subcategory_name}`}>{lang=='en'?item1.subcategory_name:item1.multi_name? item1.multi_name.arabic :item1.subcategory_name}</Link></li>
                                                            );
                                                        }
                                                    })}

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}

                        </div>
                    </div>

                    <div className="widget widget-filter">
                        <div className="block-title">{languageList['FILTER'][lang]}</div>

                        <div className="widget-brand">
                            <h5 className="widget-title">{languageList['Brand'][lang]}</h5>
                            {brand && brand.map((item, index) => {

                                return (

                                    <div className="custom-control custom-checkbox mb-2" key={index}>
                                        <input type="checkbox" className="custom-control-input" onChange={(e) => this.brandCheck(e, item.id)} id={`brandCheck${index}`} />
                                        <label className="custom-control-label" for={`brandCheck${index}`}>{lang=='en'?item.brand_name:item.multi_name? item.multi_name.arabic :item.brand_name}</label>
                                    </div>
                                );
                            })}

                        </div>

                        {/* <div className="widget-color">
                            <h5 className="widget-title">Colors</h5>
                            <div className="custom-control custom-checkbox mb-2">
                                <input type="checkbox" className="custom-control-input" id="colorCheck1" />
                                <label className="custom-control-label" for="colorCheck1"><span
                                    style={{ backgroundColor: 'rgb(60, 179, 113)' }}></span> Green</label>
                            </div>
                            <div className="custom-control custom-checkbox mb-2">
                                <input type="checkbox" className="custom-control-input" id="colorCheck2" />
                                <label className="custom-control-label" for="colorCheck2"><span
                                    style={{ backgroundColor: 'rgb(72, 118, 255)' }}></span> Blue</label>
                            </div>
                            <div className="custom-control custom-checkbox mb-2">
                                <input type="checkbox" className="custom-control-input" id="colorCheck3" />
                                <label className="custom-control-label" for="colorCheck3"><span
                                    style={{ backgroundColor: 'rgb(249, 79, 21)' }}></span> Orange</label>
                            </div>
                            <div className="custom-control custom-checkbox mb-2">
                                <input type="checkbox" className="custom-control-input" id="colorCheck4" />
                                <label className="custom-control-label" for="colorCheck4"><span
                                    style={{ backgroundColor: 'rgb(92, 36, 110)' }}></span> Purple</label>
                            </div>
                            <div className="custom-control custom-checkbox mb-2">
                                <input type="checkbox" className="custom-control-input" id="colorCheck5" />
                                <label className="custom-control-label" for="colorCheck5"><span
                                    style={{ backgroundColor: 'rgb(255, 195, 0)' }}></span> Yellow</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="colorCheck6" />
                                <label className="custom-control-label" for="colorCheck6"><span
                                    style={{ backgroundColor: 'rgb(192, 192, 192)' }}></span> Silver</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="colorCheck7" />
                                <label className="custom-control-label" for="colorCheck7"><span
                                    style={{ backgroundColor: 'rgb(41, 36, 33)' }}></span> Black</label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="colorCheck8" />
                                <label className="custom-control-label" for="colorCheck8"><span
                                    style={{ backgroundColor: 'rgb(128, 0, 0)' }}></span> Maroon</label>
                            </div>

                        </div> */}

                        <div className="widget-ratings">
                            <h5 className="widget-title">{languageList['Customer_Ratings'][lang]}</h5>
                            <div className="custom-control custom-checkbox mb-2">
                                <input type="checkbox" onChange={(e) => this.rattingChange(e, 4)} className="custom-control-input" id="rateCheck1" />
                                <label className="custom-control-label" for="rateCheck1">
                                    4 <i className="fa fa-star" aria-hidden="true"></i>
                                    {/* and above */}
                                </label>
                            </div>
                            <div className="custom-control custom-checkbox mb-2">
                                <input type="checkbox" onChange={(e) => this.rattingChange(e, 3)} className="custom-control-input" id="rateCheck2" />
                                <label className="custom-control-label" for="rateCheck2">
                                    3 <i className="fa fa-star" aria-hidden="true"></i>
                                    {/* and above */}
                                </label>
                            </div>
                            <div className="custom-control custom-checkbox mb-2">
                                <input type="checkbox" onChange={(e) => this.rattingChange(e, 2)} className="custom-control-input" id="rateCheck3" />
                                <label className="custom-control-label" for="rateCheck3">
                                    2 <i className="fa fa-star" aria-hidden="true"></i>
                                    {/* and above */}
                                </label>
                            </div>
                            <div className="custom-control custom-checkbox mb-2">
                                <input type="checkbox" onChange={(e) => this.rattingChange(e, 1)} className="custom-control-input" id="rateCheck4" />
                                <label className="custom-control-label" for="rateCheck4">
                                    1 <i className="fa fa-star" aria-hidden="true"></i>
                                    {/* and above */}
                                </label>
                            </div>

                        </div>

                        <div className="widget-price" style={{ padding: '20px',display:'none' }}>
                            <h5>Price Range</h5>
                            {/* <div className="wrapper">
                                <fieldset className="filter-price">

                                    <div className="price-field">
                                        <input type="range" min="100" max="500" value="135" id="lower" />
                                        <input type="range" min="100" max="500" value="500" id="upper" />
                                    </div>
                                    <div className="price-wrap">
                                        <div className="price-container">
                                            <div className="price-wrap-1">

                                                <label for="one">$</label>
                                                <input id="one" />
                                            </div>
                                            <div className="price-wrap_line">-</div>
                                            <div className="price-wrap-2">
                                                <label for="two">$</label>
                                                <input id="two" />

                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div> */}

                            <Range
                                values={this.state.values}
                                step={STEP}
                                min={MIN}
                                max={MAX}
                                rtl={rtl}
                                onChange={(values) => this.priceRangeChange(values)}
                                renderTrack={({ props, children }) => (
                                    <div
                                        onMouseDown={props.onMouseDown}
                                        onTouchStart={props.onTouchStart}
                                        style={{
                                            ...props.style,
                                            height: '36px',
                                            display: 'flex',
                                            width: '100%'
                                        }}
                                    >
                                        <div
                                            ref={props.ref}
                                            style={{
                                                height: barHeight,
                                                width: '100%',
                                                borderRadius: '4px',
                                                background: getTrackBackground({
                                                    values: this.state.values,
                                                    colors: ['#ccc', '#fdd922', '#ccc'],
                                                    min: MIN,
                                                    max: MAX,
                                                    rtl
                                                }),
                                                alignSelf: 'center'
                                            }}
                                        >
                                            {children}
                                        </div>
                                    </div>
                                )}
                                renderThumb={({ index, props, isDragged }) => (
                                    <div
                                        {...props}
                                        style={{
                                            ...props.style,
                                            height: iconHeight,
                                            width: iconWidth,
                                            borderRadius: '50px',
                                            backgroundColor: '#fdd922',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            boxShadow: '0px 2px 6px #AAA'
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '-28px',
                                                color: '#000',
                                                fontWeight: 'bold',
                                                fontSize: '10px',
                                                fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
                                                padding: '4px',
                                                borderRadius: '4px',
                                                backgroundColor: '#fdd922'
                                            }}
                                        >
                                            {this.state.values[index].toFixed(1)}
                                        </div>
                                        <div
                                            style={{
                                                height: '16px',
                                                width: '0px',
                                                backgroundColor: isDragged ? '#548BF4' : '#CCC'
                                            }}
                                        />
                                    </div>
                                )}
                            />

                            <button type="button" onClick={this.applyPrice} className="btn" style={{backgroundColor: '#fdd922', fontWeight: 'bold', marginTop: '15px'}}>Apply</button>
                        </div>
                    </div>

                    <div className="featured-add-box">
                        {banner && banner.map((item, index) => {

                            return (

                                <div className="featured-add-inner" key={index}>
                                    <a href="#">
                                        <img src={item.image_url} alt="f-img" />
                                    </a>
                                    <div className="banner-content">
                                        <div className="banner-text">{item.title1}</div>
                                        <div className="banner-text1">{item.title2}</div>
                                        <p>{item.title3}</p>
                                    </div>
                                </div>
                            );

                        })}

                    </div>

                    {/* <div className="widget-latest">
                        <div className="block-title"> Top Rated Products</div>
                        <div className="block-content">
                            <ul className="product-list">
                                <li className="item">
                                    <figure className="featured-thumb"> <a href="#"> <img src="products-images\product1.jpg"
                                        alt="product image" /> </a> </figure>
                                    
                                    <div className="content-info">
                                        <a href="grid.html" title="Lorem ipsum dolor sit amet">Anti Glare Side Narrow Border Display
                                            Laptop</a>
                                        <div className="star-rating">
                                            <span style={{ width: '60%' }}>Rated <strong className="rating">3.00</strong> out of 5</span>
                                        </div>
                                        <div className="item-content">
                                            <div className="item-price">
                                                <div className="price-box"> <span className="regular-price"> <span className="price">$179.00</span>
                                                </span> </div>
                                            </div>

                                        </div>
                                    </div>

                                </li>
                                <li className="item">
                                    <figure className="featured-thumb"> <a href="#"> <img src="products-images\product2.jpg"
                                        alt="product image" /> </a> </figure>
                                   
                                    <div className="content-info">
                                        <a href="grid.html" title="Lorem ipsum dolor sit amet"> Point and Shoot Camera with 12x Optical
                                            Zoom </a>
                                        <div className="star-rating">
                                            <span style={{ width: '60%' }}>Rated <strong className="rating">3.00</strong> out of 5</span>
                                        </div>
                                        <div className="item-content">
                                            <div className="item-price">
                                                <div className="price-box"> <span className="regular-price"> <span className="price">$229.00</span>
                                                </span> </div>
                                            </div>

                                        </div>
                                    </div>

                                </li>
                                <li className="item">
                                    <figure className="featured-thumb"> <a href="#"> <img src="products-images\product3.jpg"
                                        alt="product image" /> </a> </figure>
                                   
                                    <div className="content-info">
                                        <a href="grid.html" title="Lorem ipsum dolor sit amet">Siomons Galaxy N31 Ocean Blue, 8GB RAM</a>
                                        <div className="star-rating">
                                            <span style={{ width: '60%' }}>Rated <strong className="rating">3.00</strong> out of 5</span>
                                        </div>
                                        <div className="item-content">
                                            <div className="item-price">
                                                <div className="price-box"> <span className="regular-price"> <span className="price">$68.00</span> </span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </li>
                                <li className="item">
                                    <figure className="featured-thumb"> <a href="#"> <img src="products-images\product4.jpg"
                                        alt="product image" /> </a> </figure>
                                    
                                    <div className="content-info">
                                        <a href="grid.html" title="Lorem ipsum dolor sit amet">NoePlus 4K Ultra HD Android Smart LED
                                            Television </a>
                                        <div className="star-rating">
                                            <span style={{ width: '60%' }}>Rated <strong className="rating">3.00</strong> out of 5</span>
                                        </div>
                                        <div className="item-content">
                                            <div className="item-price">
                                                <div className="price-box"> <span className="regular-price"> <span className="price">$89.00</span> </span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </li>
                                <li className="item">
                                    <figure className="featured-thumb"> <a href="#"> <img src="products-images\product6.jpg"
                                        alt="product image" /> </a> </figure>
                                    
                                    <div className="content-info">
                                        <a href="grid.html" title="Lorem ipsum dolor sit amet">Health & Fitness watch with Heart Rate
                                            Monitor</a>
                                        <div className="star-rating">
                                            <span style={{ width: '60%' }}>Rated <strong className="rating">3.00</strong> out of 5</span>
                                        </div>
                                        <div className="item-content">
                                            <div className="item-price">
                                                <div className="price-box"> <span className="regular-price"> <span className="price">$57.00</span> </span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </li>
                            </ul>

                        </div>
                    </div> */}
                </aside>
            </>
        );
    }
}

export default withRouter(LeftBar);