import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CLIENTID, PAYMENTURL, PUBLIC_URL, SECRET } from '../common/CommonString';
import Footer from '../common/layout/Footer';
import Header from '../common/layout/Header';
import MobileMenu from '../common/layout/MobileMenu';
import SpinnerComponent from '../common/SpinnerComponent';
import MobileMenuIcons from '../common/layout/MobileMenuIcons';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { languageList } from '../../languageHelper';
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
let ciphertext = localStorage.getItem('user_token');

const Index = () => {

    const navigate = useNavigate();

    const [addressList, setAddressList] = useState([]);
    const [billingNewAddress, setBillingNewAddress] = useState(false);
    const [shippingNewAddress, setShippingNewAddress] = useState(false);
    const [billingSelectedAddress, setBillingSelectedAddress] = useState('');
    const [shippingSelectedAddress, setShippingSelectedAddress] = useState('');
    const [billing_firstname, setBilling_firstname] = useState('');
    const [billing_lastname, setBilling_lastname] = useState('');
    const [billing_street, setBilling_street] = useState('');
    const [billing_postcode, setBilling_postcode] = useState('');
    const [billing_city, setBilling_city] = useState('');
    const [billing_telephone, setBilling_telephone] = useState('');
    const [billing_email, setBilling_email] = useState('');
    const [billing_landmark, setbBlling_landmark] = useState('');
    const [shipping_firstname, setShipping_firstname] = useState('');
    const [shipping_lastname, setShipping_lastname] = useState('');
    const [shipping_street, setShipping_street] = useState('');
    const [shipping_postcode, setShipping_postcode] = useState('');
    const [shipping_city, setShipping_city] = useState('');

    const [shipping_telephone, setShipping_telephone] = useState('');
    const [shipping_email, setShipping_email] = useState('');
    const [shipping_landmark, setShipping_landmark] = useState('');
    const [paymentOption, setPaymentOption] = useState('');
    const [paymentType, setPaymentType] = useState(1);
    const [firstLoad, setFirstLoad] = useState(true);

    const [errorFirstname, setErrorFirstname] = useState('');
    const [errorLastname, setErrorLastname] = useState('');
    const [errorStreet, setErrorStreet] = useState('');
    const [errorPostcode, setErrorPostcode] = useState('');
    const [errorTelephone, setErrorTelephone] = useState('');
    const [errorEmail, setErrorEmail] = useState('');
    
    const [error, setError] = useState('');

    const [paymentAccessToken, setPaymentAccessToken] = useState('');
    const [paymentId, setPaymentId] = useState('');
    const [loading,setLoading]= useState(false);

    useEffect(() => {

        if (firstLoad == true) {

            axios({
                url: `${PUBLIC_URL}/getaddress`,
                method: 'POST',
                headers: { Authorization: "Bearer " + ciphertext },
                data: {
                    user_id: 1,
                }
            }).then(response => {

                if (response.data.status == 1) {

                    setAddressList(response.data.data);
                    
                    if(response.data.data.length>0)
                    {
                        let item=response.data.data[0];
                        setBillingSelectedAddress(item.id);
                        setShippingSelectedAddress(item.id);
                        setShipping_firstname(item.first_name);
                        setShipping_lastname(item.last_name);
                        setShipping_street(item.street_address);
                        setShipping_city(item.city);
                        setShipping_telephone(item.mobile);
                        setShipping_email(item.email);
                        setShipping_landmark(item.landmark);

                    }
                
                }

            }).catch((error) => { });

        }

        setFirstLoad(false);

    });

    const billingAddressChange = (e) => {

        if (e.target.value == 0) {

            setBillingNewAddress(true);
            setErrorFirstname('');
            setErrorLastname('');
            setErrorStreet('');
            setErrorPostcode('');
            setErrorTelephone('');
            setErrorEmail('');
            setError('');
        }
        else {

            setBillingSelectedAddress(e.target.value);
        }
    }

    const shippingAddressChange = (e) => {

        if (e.target.value == 0) {

            setShippingNewAddress(true);
            setErrorFirstname('');
            setErrorLastname('');
            setErrorStreet('');
            setErrorPostcode('');
            setErrorTelephone('');
            setErrorEmail('');
            setError('');
        }
        else {

            setShippingSelectedAddress(e.target.value);

            addressList.map((item) => {

                if (item.id == e.target.value) {

                    setShipping_firstname(item.first_name);
                    setShipping_lastname(item.last_name);
                    setShipping_street(item.street_address);
                    setShipping_city(item.city);
                    setShipping_telephone(item.mobile);
                    setShipping_email(item.email);
                    setShipping_landmark(item.landmark);
                }
            });
        }

    }

    const billingChange = (e) => {

        if (e.target.name === 'billing_firstname') {
            setBilling_firstname(e.target.value);
        }
        if (e.target.name === 'billing_lastname') {
            setBilling_lastname(e.target.value);
        }
        if (e.target.name === 'billing_street') {
            setBilling_street(e.target.value);
        }
        if (e.target.name === 'billing_city') {
            setBilling_city(e.target.value);
        }
        if (e.target.name === 'billing_telephone') {
            setBilling_telephone(e.target.value);
        }
        if (e.target.name === 'billing_email') {
            setBilling_email(e.target.value);
        }
        if (e.target.name === 'billing_landmark') {
            setbBlling_landmark(e.target.value);
        }

    }

    const shippingChange = (e) => {

        if (e.target.name === 'shipping_firstname') {
            setShipping_firstname(e.target.value);
        }
        if (e.target.name === 'shipping_lastname') {
            setShipping_lastname(e.target.value);
        }
        if (e.target.name === 'shipping_street') {
            setShipping_street(e.target.value);
        }
        if (e.target.name === 'shipping_city') {
            setShipping_city(e.target.value);
        }
        if (e.target.name === 'shipping_telephone') {
            setShipping_telephone(e.target.value);
        }
        if (e.target.name === 'shipping_email') {
            setShipping_email(e.target.value);
        }
        if (e.target.name === 'shipping_landmark') {
            setShipping_landmark(e.target.value);
        }
    }

    const billingSubmit = () => {

     
        if(!validateEmail(billing_email))
        {
            setErrorEmail('Please enter valid email');
            return false;
        }
        if(billing_firstname !== '' && billing_lastname !== '' && billing_street !== ''  && billing_telephone !== '' && billing_email !== ''){

            setErrorFirstname('');
            setErrorLastname('');
            setErrorStreet('');
            setErrorPostcode('');
            setErrorTelephone('');
            setErrorEmail('');
            setError('');

            let formData = new FormData();
            formData.append('user_id', 1);
            formData.append('first_name', billing_firstname);
            formData.append('last_name', billing_lastname);
            formData.append('street_address', billing_street);
            formData.append('city', billing_city);
            formData.append('mobile', billing_telephone);
            formData.append('email', billing_email);
            formData.append('landmark', billing_landmark);

            axios({
                url: `${PUBLIC_URL}/saveaddress`,
                method: 'POST',
                headers: { Authorization: "Bearer " + ciphertext },
                data: formData,
            }).then(response => {

                if (response.data.status == 1) {

                    axios({
                        url: `${PUBLIC_URL}/getaddress`,
                        method: 'POST',
                        headers: { Authorization: "Bearer " + ciphertext },
                        data: {
                            user_id: 1,
                        }
                    }).then(response => {

                        if (response.data.status == 1) {

                            setAddressList(response.data.data);
                            setBillingNewAddress(false);

                            toast.success("Address is added!", {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }

                    }).catch((error) => {


                    });

                }

            }).catch((error) => {

                if(error.response.data.status == 0){
                    setError(error.response.data.message);
                }
            });
        }
        else{

            if(billing_firstname === ''){
                setErrorFirstname('Field is required');
            }
            else{
                setErrorFirstname('');
            }

            if(billing_lastname === ''){
                setErrorLastname('Field is required');
            }
            else{
                setErrorLastname('');
            }

            if(billing_street === ''){
                setErrorStreet('Field is required');
            }
            else{
                setErrorStreet('');
            }

//            if(billing_postcode === ''){
//                setErrorPostcode('Field is required');
//            }
//            else{
//                setErrorPostcode('');
//            }

            if(billing_telephone === ''){
                setErrorTelephone('Field is required');
            }
            else{
                setErrorTelephone('');
            }

            if(billing_email === ''){
                setErrorEmail('Field is required');
            }
            else{
                setErrorEmail('');
            }
        }
    }
    const validateEmail = (email) => {
        return email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      };
    const shippingSubmit = () => {
        if(shipping_firstname !== '' && shipping_lastname !== '' && shipping_street !== '' && shipping_telephone !== '' && shipping_email !== ''){

            setErrorFirstname('');
            setErrorLastname('');
            setErrorStreet('');
            setErrorPostcode('');
            setErrorTelephone('');
            setErrorEmail('');
            setError('');


            let formData = new FormData();
            formData.append('user_id', 1);
            formData.append('first_name', shipping_firstname);
            formData.append('last_name', shipping_lastname);
            formData.append('street_address', shipping_street);
            formData.append('city', shipping_city);
            formData.append('mobile', shipping_telephone);
            formData.append('email', shipping_email);
            formData.append('landmark', shipping_landmark);

            axios({
                url: `${PUBLIC_URL}/saveaddress`,
                method: 'POST',
                headers: { Authorization: "Bearer " + ciphertext },
                data: formData,
            }).then(response => {

                if (response.data.status == 1) {

                    axios({
                        url: `${PUBLIC_URL}/getaddress`,
                        method: 'POST',
                        headers: { Authorization: "Bearer " + ciphertext },
                        data: {
                            user_id: 1,
                        }
                    }).then(response => {

                        if (response.data.status == 1) {

                            setAddressList(response.data.data);
                            setShippingNewAddress(false);

                            toast.success("Address is added!", {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }

                    }).catch((error) => {});

                }

            }).catch((error) => {

                if(error.response.data.status == 0){
                    setError(error.response.data.message);
                }
            });
        }
        else{

            if(shipping_firstname === ''){
                setErrorFirstname('Field is required');
            }
            else{
                setErrorFirstname('');
            }

            if(shipping_lastname === ''){
                setErrorLastname('Field is required');
            }
            else{
                setErrorLastname('');
            }

            if(shipping_street === ''){
                setErrorStreet('Field is required');
            }
            else{
                setErrorStreet('');
            }

            if(shipping_city === ''){
                setErrorPostcode('Field is required');
            }
            else{
                setErrorPostcode('');
            }

            if(shipping_telephone === ''){
                setErrorTelephone('Field is required');
            }
            else{
                setErrorTelephone('');
            }

            if(shipping_email === ''){
                setErrorEmail('Field is required');
            }
            else{
                setErrorEmail('');
            }

        }

    }

    const paymentChange = (e) => {

        if (e.target.value === 'card') {

            setPaymentOption(e.target.value);
            setPaymentType(3);
        }
        else {
            setPaymentOption(e.target.value);
            setPaymentType(1);

        }
    }

    const placeOrder = () => {
        // console.log('shipping_firstname '+shipping_firstname);
        // console.log('shipping_street '+shipping_street);
        // console.log('shipping_postcode '+shipping_postcode);
        // console.log('shipping_email '+shipping_email);
        // console.log('shipping_telephone '+shipping_telephone);
        // console.log('paymentOption '+paymentOption);
        // console.log('billingSelectedAddress '+billingSelectedAddress);
        // console.log('shippingSelectedAddress '+shippingSelectedAddress);

       if(shipping_firstname&&shipping_street&&shipping_email&&shipping_telephone&&paymentOption&&billingSelectedAddress&&shippingSelectedAddress)
       {
        
        if(paymentOption == 'card'){
           
            // setLoading(true);
            // let formData = new FormData();
            // formData.append('full_name', shipping_firstname);
            // formData.append('street_address', shipping_street);
            // formData.append('city', shipping_city);
            // formData.append('landmark', shipping_landmark);
            // formData.append('email', shipping_email);
            // formData.append('mobile', shipping_telephone);

            // axios({
            //     url: `${PUBLIC_URL}/online_payment`,
            //     method: 'POST',
            //     headers: { Authorization: "Bearer " + ciphertext },
            //     data: formData,
            // }).then(response => {
            //    console.log(response);
            //    console.log(response.data);
            //     if (response.data.success == 1) {
            //         setLoading(false);
            //             window.location=response.data.data.paymentUrl;
            //         // navigate('/thankyou?order=' + response.data.order_id);
            //     }else{
            //         setLoading(false);
            //         toast.error("Try later", {
            //             position: "top-right",
            //             autoClose: false,
            //             hideProgressBar: false,
            //             closeOnClick: true,
            //             pauseOnHover: true,
            //             draggable: false,
            //             progress: undefined,
            //         });
            //     }

            // }).catch((error) => { 
            //     setLoading(false);
            // });

            setLoading(true);
            let formData = new FormData();
            formData.append('user_id', 1);
            formData.append('payment_type', paymentType);
            formData.append('full_name', shipping_firstname);
            formData.append('street_address', shipping_street);
            formData.append('landmark', shipping_landmark);
            formData.append('city', shipping_city);
            formData.append('email', shipping_email);
            formData.append('mobile', shipping_telephone);

            axios({
                url: `${PUBLIC_URL}/order`,
                method: 'POST',
                headers: { Authorization: "Bearer " + ciphertext },
                data: formData,
            }).then(response => {

                if (response.data.status == 1) {
                    setLoading(false);
                    navigate('/thankyou?order=' + response.data.order_id);
                }

            }).catch((error) => { 
                setLoading(false);
            });
        }
        else{
            setLoading(true);
            let formData = new FormData();
            formData.append('user_id', 1);
            formData.append('payment_type', paymentType);
            formData.append('full_name', shipping_firstname);
            formData.append('street_address', shipping_street);
            formData.append('landmark', shipping_landmark);
            formData.append('city', shipping_city);
            formData.append('email', shipping_email);
            formData.append('mobile', shipping_telephone);

            axios({
                url: `${PUBLIC_URL}/order`,
                method: 'POST',
                headers: { Authorization: "Bearer " + ciphertext },
                data: formData,
            }).then(response => {

                if (response.data.status == 1) {
                    setLoading(false);
                    navigate('/thankyou?order=' + response.data.order_id);
                }

            }).catch((error) => { 
                setLoading(false);
            });
        }
    }else if (!billingSelectedAddress) {
        toast.error("Please fill the  Billing Address", {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
        });
    }else if (!shippingSelectedAddress){
        toast.error("Please fill the Shipping Address", {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
        });
    }else if(!paymentOption){
        toast.error("Please fill the Payment Options", {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
        });
    }else{
        toast.error("Please fill the Details", {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
        });
    }
    }

    return (
        <>
        <SpinnerComponent open={loading} />
            <div id="page">

                {/* <!-- Header --> */}
                <Header />
                {/* <!-- end header -->  */}

                {/* <!-- Main Container --> */}
                <section className="main-container col2-left-layout">
                    <div className="container">
                        <div className="row">

                            <div className="col-sm-9 col-sm-push-3">
                                <article className="col-main">
                                    <div className="page-title">
                                        <h2>{languageList['CHECKOUT'][lang]}</h2>
                                    </div>
                                    <div className="panel-group accordion-faq" id="faq-accordion" >
                                        <div className="panel">
                                            <div className="panel-heading"> 
                                               <a data-toggle="collapse" data-parent1="#faq-accordion" href="#question1" > 
                                               <span className="arrow-down">+</span> 
                                               <span className="arrow-up">&#8211;</span> {languageList['Checkout_Method'][lang]}</a> </div>
                                            <div id="question1" className="panel-collapse collapse in" >
                                                <div className="panel-body">
                                                    <form id="co-billing-form" action="">
                                                        <fieldset className="group-select">
                                                            <ul>
                                                                {billingNewAddress == false ?
                                                                    <li>
                                                                        <label for="billing-address-select">{languageList['Select_a_billing_address'][lang]}</label>
                                                                        <br />
                                                                        <select name="billing_address_id" id="billing-address-select" className="address-select" title="" onChange={billingAddressChange} value={billingSelectedAddress}>
                                                                            <option value="">Select Address</option>
                                                                            {addressList && addressList.map((item, index) => {
                                                                                return <option key={index} value={item.id} >{item.first_name + ' ' + item.last_name + ' ' + item.street_address + ' ' + item.city}</option>
                                                                            })}
                                                                            <option value="0">New Address</option>
                                                                        </select>
                                                                    </li> : ''}
                                                                <li id="billing-new-address-form" style={billingNewAddress == true ? { display: 'block' } : { display: 'none' }}>
                                                                    <fieldset>
                                                                        <legend>New Address</legend>

                                                                        <ul>
                                                                            <li>
                                                                                <div className="customer-name">
                                                                                    <div className="input-box name-firstname">
                                                                                        <label for="billing:firstname"> First Name <span className="required">*</span> </label>
                                                                                        <br />
                                                                                        <input type="text" id="billing:firstname" onChange={billingChange} name="billing_firstname" value={billing_firstname} title="First Name" className="input-text required-entry" />
                                                                                        {errorFirstname !== '' ? <div className="text-danger">{errorFirstname}</div> : ''}
                                                                                    </div>
                                                                                    <div className="input-box name-lastname">
                                                                                        <label for="billing:lastname"> Last Name <span className="required">*</span> </label>
                                                                                        <br />
                                                                                        <input type="text" id="billing:lastname" onChange={billingChange} name="billing_lastname" value={billing_lastname} title="Last Name" className="input-text required-entry" />
                                                                                        {errorLastname !== '' ? <div className="text-danger">{errorLastname}</div> : ''}
                                                                                    </div>
                                                                                </div>
                                                                            </li>

                                                                            <li>
                                                                                <label for="billing:street1">Street Address <span className="required">*</span></label>
                                                                                <br />
                                                                                <input type="text" title="Street Address" onChange={billingChange} name="billing_street" id="billing:street1  street1" value={billing_street} className="input-text required-entry" />
                                                                                {errorStreet !== '' ? <div className="text-danger">{errorStreet}</div> : ''}
                                                                            </li>


                                                                            <li>
                                                                                <div className="input-box">
                                                                                    <label for="billing:city">City </label>
                                                                                    <br />
                                                                                    <input type="text" title="City" onChange={billingChange} name="billing_city" id="billing:city" value={billing_city} className="input-text " />
                                                                                    {/* {errorPostcode !== '' ? <div className="text-danger">{errorPostcode}</div> : ''} */}
                                                                                </div>

                                                                            </li>
                                                                            <li>
                                                                            <div className="input-box" style={{width:'33%'}}>
                                                                                    <label for="billing:telephone">Telephone <span className="required">*</span></label>
                                                                                    <br />
                                                                                    <input type="number" name="billing_telephone" onChange={billingChange} value={billing_telephone} title="Telephone" className="input-text required-entry" id="billing:telephone" />
                                                                                    {errorTelephone !== '' ? <div className="text-danger">{errorTelephone}</div> : ''}
                                                                                </div>
                                                                                <div className="input-box" style={{width:'33%'}}>
                                                                                    <label for="Email">Email <span className="required">*</span></label>
                                                                                    <br />
                                                                                    <input type="text" name="billing_email" onChange={billingChange} value={billing_email} title="Email" className="input-text" id="Email" />
                                                                                    {errorEmail !== '' ? <div className="text-danger">{errorEmail}</div> : ''}
                                                                                </div>
                                                                                <div className="input-box" style={{width:'33%'}}>
                                                                                     <label for="billing:street1">Landmark</label>
                                                                                <br />
                                                                                <input type="text" title="Street Address" onChange={billingChange} name="billing_landmark" id="billing:street1  street1" value={billing_landmark} className="input-text required-entry" />
                                                                                </div>
                                                                            </li>

                                                                        </ul>
                                                                    </fieldset>
                                                                </li>
                                                            </ul>
                                                            {billingNewAddress == true ?
                                                                <>
                                                                    <p className="require"><em className="required">* </em>Required Fields</p>
                                                                    <button type="button" className="button continue" onClick={billingSubmit}><span>Save</span></button>
                                                                    <a href="javascript:void(0);" onClick={() => setBillingNewAddress(false)} className="back-link"> « Back</a>
                                                                </> : ''}
                                                        </fieldset>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel">
                                            <div className="panel-heading"> <a data-toggle="collapse" data-parent1="#faq-accordion"  href="#question3" > <span className="arrow-down">+</span> <span className="arrow-up">&#8211;</span> {languageList['Shipping_Info'][lang]}</a> </div>
                                            <div id="question3" className="panel-collapse collapse in" >
                                                <div className="panel-body">
                                                    <form action="" id="co-shipping-form">
                                                        <fieldset className="group-select">
                                                            <ul>
                                                                <li>
                                                                    <label for="shipping-address-select">{languageList['Select_a_shipping_address'][lang]}</label>
                                                                    <br />
                                                                    {shippingNewAddress == false ?
                                                                        <select name="shipping_address_id" id="shipping-address-select" className="address-select" title="" onChange={shippingAddressChange} value={shippingSelectedAddress}>
                                                                            <option value="">Select Address</option>
                                                                            {addressList && addressList.map((item, index) => {
                                                                                return <option key={index} value={item.id} >{item.first_name + ' ' + item.last_name + ' ' + item.street_address + ' ' + item.city}</option>
                                                                            })}
                                                                            <option value="0">New Address</option>
                                                                        </select> : ''}
                                                                </li>
                                                                <li id="shipping-new-address-form" style={shippingNewAddress == true ? { display: 'block' } : { display: 'none' }}>
                                                                    <fieldset>

                                                                        <ul>
                                                                            <li>
                                                                                <div className="customer-name">
                                                                                    <div className="input-box name-firstname">
                                                                                        <label for="shipping:firstname"> First Name <span className="required">*</span> </label>
                                                                                        <br />
                                                                                        <input type="text" id="shipping:firstname" onChange={shippingChange} name="shipping_firstname" value={shipping_firstname} title="First Name" className="input-text required-entry" />
                                                                                        {errorFirstname !== '' ? <div className="text-danger">{errorFirstname}</div> : ''}
                                                                                    </div>
                                                                                    <div className="input-box name-lastname">
                                                                                        <label for="shipping:lastname"> Last Name <span className="required">*</span> </label>
                                                                                        <br />
                                                                                        <input type="text" id="shipping:lastname" onChange={shippingChange} name="shipping_lastname" value={shipping_lastname} title="Last Name" className="input-text required-entry" />
                                                                                        {errorLastname !== '' ? <div className="text-danger">{errorLastname}</div> : ''}
                                                                                    </div>
                                                                                </div>
                                                                            </li>

                                                                            <li>
                                                                                <label for="shipping:street1">Street Address <span className="required">*</span></label>
                                                                                <br />
                                                                                <input type="text" title="Street Address" onChange={shippingChange} name="shipping_street" id="shipping:street1  street1" value={shipping_street} className="input-text required-entry" />
                                                                                {errorStreet !== '' ? <div className="text-danger">{errorStreet}</div> : ''}
                                                                            </li>


                                                                            <li>
                                                                                <div className="input-box">
                                                                                    <label for="shipping:city">City <span className="required">*</span></label>
                                                                                    <br />
                                                                                    <input type="text" title="City" onChange={shippingChange} name="shipping_city" id="shipping:city" value={shipping_city} className="input-text  required-entry" />
                                                                                    {errorPostcode !== '' ? <div className="text-danger">{errorPostcode}</div> : ''}
                                                                                </div>

                                                                            </li>
                                                                            <li>
                                                                                <div className="input-box">
                                                                                    <label for="shipping:telephone">Telephone <span className="required">*</span></label>
                                                                                    <br />
                                                                                    <input type="number" name="shipping_telephone" onChange={shippingChange} value={shipping_telephone} title="Telephone" className="input-text required-entry" id="shipping:telephone" />
                                                                                    {errorTelephone !== '' ? <div className="text-danger">{errorTelephone}</div> : ''}
                                                                                </div>
                                                                                <div className="input-box">
                                                                                    <label for="shipping:email">Email <span className="required">*</span></label>
                                                                                    <br />
                                                                                    <input type="email" name="shipping_email" onChange={shippingChange} value={shipping_email} title="Email" className="input-text" id="shipping:email" />
                                                                                    {errorEmail !== '' ? <div className="text-danger">{errorEmail}</div> : ''}
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <label for="shipping:Landmark">Landmark </label>
                                                                                <br />
                                                                                <input type="text" title="Landmark" onChange={shippingChange} name="shipping_landmark" id="shipping:Landmark  Landmark" value={shipping_landmark} className="input-text required-entry" />
                                                                            </li>
                                                                        </ul>
                                                                    </fieldset>
                                                                </li>
                                                            </ul>
                                                            {shippingNewAddress == true ?
                                                                <>
                                                                    <p className="require"><em className="required">* </em>Required Fields</p>
                                                                    <div className="buttons-set1" id="shipping-buttons-container">
                                                                        <button type="button" className="button" onClick={shippingSubmit}><span>Save</span></button>
                                                                        <a href="javascript:void(0);" onClick={() => setShippingNewAddress(false)} className="back-link"> « Back</a>
                                                                    </div>
                                                                </>
                                                                : ''}
                                                        </fieldset>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="panel">
                                            <div className="panel-heading"> <a data-toggle="collapse" data-parent1="#faq-accordion" href="#question5" > <span className="arrow-down">+</span> <span className="arrow-up">&#8211;</span> {languageList['Payment_Info'][lang]}</a> </div>
                                            <div id="question5" className="panel-collapse collapse in">
                                                <div className="panel-body">
                                                    <form action="" id="co-payment-form">
                                                        <dl id="checkout-payment-method-load">
                                                            <dt>
                                                                {paymentOption === 'cash' ? <input type="radio" id="p_method_checkmo" checked={true} value="cash" name="payment_method" title="Check / Money order" onClick={paymentChange} className="radio" /> :
                                                                    <input type="radio" id="p_method_checkmo" value="cash" name="payment_method" title="Check / Money order" onClick={paymentChange} className="radio" />}
                                                                <label for="p_method_checkmo">{languageList['Cash_On_Delivery'][lang]}</label>
                                                            </dt>
                                                            <dd>
                                                                <fieldset className="form-list">
                                                                </fieldset>
                                                            </dd>
                                                            <dt>
                                                                {paymentOption === 'card' ? <input type="radio" id="p_method_ccsave" value="card" name="payment_method" title="Card Payment)" onClick={paymentChange} className="radio" /> :
                                                                    <input type="radio" id="p_method_ccsave" value="card" name="payment_method" title="Card Payment)" onClick={paymentChange} className="radio" />}
                                                                <label for="p_method_ccsave">{languageList['Card_Payment'][lang]}</label>
                                                            </dt>

                                                        </dl>
                                                        <img src="/images/cart_image.png" alt="payment option" style={{height:'50px'}}/>
                                                    </form>
                                                    {/* <p className="require"><em className="required">* </em>Required Fields</p>
                                                        <div className="buttons-set1" id="payment-buttons-container">
                                                            <button type="button" className="button" onclick="payment.save()"><span>Continue</span></button> */}
                                                    {/* <a href="#" onclick="checkout.back(); return false;" className="back-link">« Back</a> */}
                                                    {/* </div>
                                                        <div style={{ clear: 'both' }}></div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel">
                                            <div className="panel-heading"> <a data-toggle="collapse" data-parent1="#faq-accordion" href="#question4" > 
                                            <span className="arrow-down">+</span> <span className="arrow-up">&#8211;</span>{languageList['order_review'][lang]}</a> </div>
                                            <div id="question4" className="panel-collapse collapse in">
                                                <div className="panel-body">
                                                    <div className="order-review" id="checkout-review-load"> </div>
                                                    <div className="buttons-set13" id="review-buttons-container">
                                                        <p className="f-left">{languageList['Forgot_an_Item?'][lang]} <Link to="/cart">{languageList['Edit_Your_Cart'][lang]}</Link></p>
                                                        <button type="submit" className="button" onClick={placeOrder}><span>{languageList['place_order'][lang]}</span></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                {/* <!--	======    End article  =========  -->  */}
                            </div>
                            <aside className="col-left sidebar col-sm-3 col-xs-12 col-sm-pull-9">

                                <div className="block block-progress">
                                    <div className="block-title ">{languageList['YOUR_CHECKOUT'][lang]}</div>
                                    <div className="block-content">
                                        <dl>
                                            <dt className="complete"> {languageList['Billing_Address'][lang]} </dt>
                                            <dd className="complete">
                                                {addressList && addressList.map((item, index) => {

                                                    if (item.id == billingSelectedAddress) {

                                                        return (

                                                            <address key={index}>
                                                                {item.first_name}<br />
                                                                {item.last_name}<br />
                                                                {item.street_address}<br />
                                                                {item.landmark}<br />
                                                                {item.city}<br />
                                                                M: {item.mobile}<br />
                                                                E: {item.email}
                                                            </address>
                                                        );
                                                    }
                                                })}

                                            </dd>
                                            <dt className="complete"> {languageList['Shipping_Address'][lang]} </dt>
                                            <dd className="complete">
                                                {addressList && addressList.map((item, index) => {

                                                    if (item.id == shippingSelectedAddress) {

                                                        return (

                                                            <address key={index}>
                                                                {item.first_name}<br />
                                                                {item.last_name}<br />
                                                                {item.street_address}<br />
                                                                {item.landmark}<br />
                                                                {item.city}<br />
                                                                M: {item.mobile}<br />
                                                                E: {item.email}
                                                            </address>
                                                        );
                                                    }

                                                })}
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                                {/* <div className="featured-add-box">
                                        <div className="featured-add-inner"> <a href="#"> <img src="images\hot-trends-banner.jpg" alt="f-img" /></a>
                                            <div className="banner-content">
                                                <div className="banner-text">Clearance Sale</div>
                                                <div className="banner-text1">Hot <span>Sale</span> </div>
                                                <p>save upto 20%</p>
                                            </div>
                                        </div>
                                    </div> */}
                            </aside>
                        </div>
                    </div>
                </section>
                {/* <!-- Main Container End --> */}

                {/* <!-- Footer --> */}
                <Footer />
                {/* <!-- End Footer -->  */}
            </div>
            {/* <!-- mobile menu --> */}
            <MobileMenu />

            <MobileMenuIcons />

            {/* <!-- JavaScript -->  */}
        </>
    );
};

export default Index;