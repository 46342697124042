import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../common/layout/Footer';
import Header from '../common/layout/Header';
import MobileMenu from '../common/layout/MobileMenu';
import MobileMenuIcons from '../common/layout/MobileMenuIcons';
import { useLocation } from 'react-router';
import axios from 'axios';
import { PUBLIC_URL } from '../common/CommonString';
import {getFloatVal}  from '../../commonFunction';
let ciphertext = localStorage.getItem('user_token');

const Index = () => {

    const location = useLocation();
    const orderId = ((new URLSearchParams(location.search).get('order')) != '') ? (new URLSearchParams(location.search).get('order')) : '';

    const [cart, setCart] = useState([]);
    const [total, setTotal] = useState(0);
    const [tax, setTax] = useState(0);
    const [delivery, setDelivery] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true);
    const [info, setInfo] = useState([]);

    useEffect(() => {

        if (firstLoad == true) {

            let formData = new FormData();
            formData.append('user_id', 1);
            formData.append('order_number', orderId)

            axios({
                url: `${PUBLIC_URL}/orderdetails`,
                method: 'POST',
                headers: { Authorization: "Bearer " + ciphertext },
                data: formData,
            }).then(response => {

                if (response.data.status == 1) {

                    setCart(response.data.order_data);
                    setTotal(response.data.order_info ? response.data.order_info.grand_total : 0);
                    let tax=response.data.order_info ? response.data.order_info.tax : 0;

                    setTax(getFloatVal(tax));
                    setDelivery(response.data.order_info ? response.data.order_info.shipping_cost : 0);
                    setSubTotal(response.data.order_info ? response.data.order_info.subtotal : 0);
                    setDiscount(response.data.order_info ? response.data.order_info.discount_amount : 0);
                    setInfo(response.data.order_info);
                }

            }).catch((error) => { });
        }

        setFirstLoad(false);

    });
    const getFloatVal=(input)=>{
       let output= parseFloat(input);
       if(isNaN(output))
       {
          return 0;
       }
       return output.toFixed(2);
    }
    return (
        <>
            <div id="page">
                {/* <!-- Header --> */}
                <Header />
                {/* <!-- end header -->  */}

                {/* <!-- Main Container --> */}
                <section className="main-container col1-layout">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-xs-12">
                                <article className="col-main">
                                    <div className="cart">
                                        <div className="page-title">
                                            <h2>Thank You For Your Order With Id:  <span style={{ color: 'red' }}>{orderId}</span></h2>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-8 col-lg-8">
                                                {cart.length == 0 ? 'No Data found' : ''}
                                                {cart && cart.map((item, index) => {
                                                    return (
                                                        <div className="uthssk" key={index}>
                                                            <div className="pous">
                                                                <img src={item.image_url} alt={item.product_name} />
                                                            </div>
                                                            <div className="pous1">
                                                                <h3>{item.product_name}</h3>
                                                                {item.variations && item.variations.map((row, index1) => {
                                                                    return<h4 key={index1}>{row.attribute} : {row.variation}</h4>
                                                                })}
                                                                {/* <small>Order in 7 hrs 13 mins</small>
                                                                    <small><b>Free delivery by Tomorrow, Dec 28</b></small>
                                                                    <h5>Sold by <b>noon Fashion</b></h5> */}
                                                                <br />
                                                            </div>
                                                            <div className="pous2">
                                                                <p>AED {getFloatVal(item.price)}</p>
                                                                <select>
                                                                    <option value={item.qty} selected>{item.qty}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    );
                                                })}

                                            </div>
                                            <div className="col-sm-4 col-lg-4">
                                                <div className="hcbyIU">
                                                    <h1>Order Summary</h1>
                                                    <hr />
                                                    <div className="po1s">
                                                        <div className="d-flex justify-content-between">
                                                            <div className="cilop">
                                                                <h5>sub total</h5>
                                                                <h5>discount</h5>
                                                                <h5>TAX</h5>
                                                                <h5>delivery charges</h5>
                                                            </div>
                                                            <div className="cilop1">
                                                                <h5>AED {getFloatVal(subTotal)}</h5>
                                                                <h5>AED -{getFloatVal(discount)}</h5>
                                                                <h5>AED +{getFloatVal(tax)}</h5>
                                                                <h5>AED +{getFloatVal(delivery)}</h5>
                                                            </div>
                                                        </div><hr />
                                                        <div className="d-flex justify-content-between">
                                                            <div className="cilop">
                                                                <h5>total </h5>
                                                            </div>
                                                            <div className="cilop1">
                                                                <h5>AED {getFloatVal(total)}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 col-lg-12">
                                                <h3><b>Shipping Address</b></h3>
                                                <div className="uthssk">
                                                    <div className="pous">
                                                        <img src="/images/location.png" alt="location" />
                                                    </div>
                                                    <div className="pous1">
                                                        <h3>{info.full_name}</h3>
                                                        <h4>{info.email}</h4>
                                                        <h4>{info.mobile}</h4>
                                                        <h4>{info.street_address}</h4>
                                                        <h4>{info.pincode}</h4>
                                                        <h4>{info.landmark}</h4>
                                                    </div>
                                                </div>
                                                <div className="gcvYcJ">
                                                    <Link to="/"><button type="button">Continue Shopping</button></Link>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </article>
                                {/* <!--	======    End article  =========  -->  */}
                            </div>

                        </div>
                    </div>
                </section>
                {/* <!-- Main Container End --> */}

                {/* <!-- Footer --> */}
                <Footer />
                {/* <!-- End Footer -->  */}
            </div>
            {/* <!-- mobile menu --> */}
            <MobileMenu />

            <MobileMenuIcons />

            {/* <!-- JavaScript -->  */}
        </>
    );
};

export default Index;