import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PUBLIC_URL } from '../../common/CommonString';
import Footer from '../../common/layout/Footer';
import Header from '../../common/layout/Header';
import MobileMenu from '../../common/layout/MobileMenu';
import MobileMenuIcons from '../../common/layout/MobileMenuIcons';
import AddNewAddress from './AddNewAddress';
import AddressItem from './AddressItem';
import { languageList } from '../../../languageHelper';
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
let user_token = localStorage.getItem('user_token') ? localStorage.getItem('user_token') : '';

const Index = () => {

    const [firstLoad, setFirstLoad] = useState(true);
    const [address, setAddress] = useState([]);

    useEffect(() => {

        if(firstLoad == true){

            let formData = new FormData();
            formData.append('user_id', 1);

            axios({
                url: `${PUBLIC_URL}/getaddress`,
                method: 'POST',
                headers: { Authorization: "Bearer " + user_token },
                data: formData,
            }).then(response => {
               // console.log(response);
                if(response.data.status == 1){

                    setAddress(response.data.data);
                }

            }).catch((error) => {});
        }

        setFirstLoad(false);

    });

    const callAddress = () => {

        let formData = new FormData();
            formData.append('user_id', 1);

            axios({
                url: `${PUBLIC_URL}/getaddress`,
                method: 'POST',
                headers: { Authorization: "Bearer " + user_token },
                data: formData,
            }).then(response => {
               
                if(response.data.status == 1){

                    setAddress(response.data.data);
                }

            }).catch((error) => {});

    }

    return (
        <div>
            <div id="page">

                {/* <!-- Header --> */}
                <Header />
                {/* <!-- end header --> */}

                <section className="main-container col1-layout">
                    <div className="container">
                        <div className="breadcrumbs">
                            <ul>
                                <li className="home"> <Link to="/" title="Go to Home Page">{languageList['home'][lang]}</Link> <span>/</span> </li>
                                <li> <strong>{languageList['Addresses'][lang]}</strong> </li>
                            </ul>
                        </div>

                        <div className="row">
                            <div className="col-md-3 account-tabs-section extr-margin-bottom">
                                <ul className="nav flex-column account-tabs">
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/profile">{languageList['profile'][lang]}</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/orders">{languageList['orders'][lang]}</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/wishlist">{languageList['Wishlist'][lang]}</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link active" to="/address">{languageList['Addresses'][lang]}</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-9">
                                <div className="tab-content account-profile">
                                    <fieldset className="group-select">
                                        <legend>{languageList['Addresses'][lang]}</legend>
                                    </fieldset>
                                    <div className="address-add">
                                        <button type="button" data-toggle="modal" data-target="#myModalAddAddress" className="button continue"><span>Add New Address</span></button>
                                    </div>
                                    <div className="address-area">
                                        {address.length == 0 ? <div className="text-center">No Data Found!</div> : ''}
                                        {address && address.map((item, index) => {

                                            return <AddressItem last={index == address.length - 1 ? true : false} address={item} callAddress={callAddress} />
                                        })}
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Footer --> */}
                <Footer />
                {/* <!-- End Footer -->  */}

            </div>

            {/* <!-- mobile menu --> */}
            <MobileMenu />

            <MobileMenuIcons />

            {/* <!-- JavaScript -->  */}

            <AddNewAddress callAddress={callAddress} />
        </div>
    );
};

export default Index;