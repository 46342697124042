import logo from './logo.svg';
import './App.css';
import Cart from './components/cart/Index';
import Checkout from './components/checkout/Index';
import Index from './components/home/Index';
import ProductDetails from './components/product/details/Index';
import ProductList from './components/product/listView/Index';
import ProductGrid from './components/product/GridView/Index';
import Profile from './components/account/profile/Index';
import Orders from './components/account/order/Index';
import Wishlist from './components/account/wishlist/Index';
import Address from './components/account/address/Index';
import About from './components/about/Index';
import Contact from './components/contact/Index';
import ThankYou from './components/thankyou/Index';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from './components/common/layout/ScrollToTop';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OrderDetails from './components/account/order/OrderDetails';
import Tracking from './components/account/order/Tracking';
import Faq from './components/termsconditions/Faq';
import Payment from './components/termsconditions/Payment';
import Shipment from './components/termsconditions/Shipment';
import Order from './components/termsconditions/Order';
import PaymentStatus from './components/payment_status/Index';
import React from 'react'

function App() {
  return (
    <div >
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/product-grid" element={<ProductList />} />
            <Route path="/factory_outlet" element={<ProductList factoryOutlet="1"/>} />
            <Route path="/product-list" element={<ProductGrid />} />
            <Route path="/product-details" element={<ProductDetails />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/order-details" element={<OrderDetails />} />
            <Route path="/order-tracking" element={<Tracking />} />
            <Route path="/wishlist" element={<Wishlist />} />
            <Route path="/address" element={<Address />} />
            <Route path="/thankyou" element={<ThankYou />} />
            <Route path="/payment_status" element={<PaymentStatus />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/shipment-guide" element={<Shipment />} />
            <Route path="/order-guide" element={<Order />} />
          </Routes>
          <ToastContainer />
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
}

export default App;
