import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, } from 'react-router-dom';
import Footer from '../../common/layout/Footer';
import Header from '../../common/layout/Header';
import MobileMenu from '../../common/layout/MobileMenu';
import MobileMenuIcons from '../../common/layout/MobileMenuIcons';
import { useLocation } from 'react-router';
import { PUBLIC_URL } from '../../common/CommonString';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import {getFloatVal}  from '../../../commonFunction';

let user_token = localStorage.getItem('user_token');

const OrderDetails = () => {

    const location = useLocation();

    const [firstLoad, setFirstLoad] = useState(true);
    const [order, setOrder] = useState([]);
    const [orderItems, setOrderItems] = useState([]);
    const [returnPopUp, setReturnPopUp] = useState(false);
    const [reson, setReson] = useState('');
    const [resonError, setResonError] = useState('');
    const [orderId, setOrderId] = useState('');

    useEffect(() => {

        let order = ((new URLSearchParams(location.search).get('order')) != '') ? (new URLSearchParams(location.search).get('order')) : '';

        if (firstLoad == true) {

            let formData = new FormData();
            formData.append('order_number', order);
            formData.append('user_id', 1);

            axios({
                url: `${PUBLIC_URL}/orderdetails`,
                method: 'POST',
                headers: { Authorization: "Bearer " + user_token },
                data: formData,

            }).then(response => {

                if (response.data.status == 1) {

                    setOrder(response.data.order_info);
                    setOrderItems(response.data.order_data);
                }

            }).catch((error) => { });
        }

        setFirstLoad(false);
    });

    const cancelOrder = (id) => {

        Swal.fire({
            icon: 'warning',
            title: 'Are you sure',
            text: 'Do you want to cancel the order?',
            showDenyButton: true,
            confirmButtonText: 'Confirm',
            denyButtonText: `close`,
            heightAuto: false,
            width: 600,
            height:400,
        }).then((result) => {

            if (result.isConfirmed) {

                let formData = new FormData();
                formData.append('user_id', 1);
                formData.append('order_id', id);
                formData.append('status', 6) //canceled by user

                axios({
                    url: `${PUBLIC_URL}/cancelorder`,
                    method: 'POST',
                    headers: { Authorization: "Bearer " + user_token },
                    data: formData,
                }).then(response => {

                    if (response.data.status == 1) {

                        let order = ((new URLSearchParams(location.search).get('order')) != '') ? (new URLSearchParams(location.search).get('order')) : '';

                        let formData = new FormData();
                        formData.append('order_number', order);
                        formData.append('user_id', 1);

                        axios({
                            url: `${PUBLIC_URL}/orderdetails`,
                            method: 'POST',
                            headers: { Authorization: "Bearer " + user_token },
                            data: formData,

                        }).then(response => {

                            if (response.data.status == 1) {

                                setOrder(response.data.order_info);
                                setOrderItems(response.data.order_data);
                            }

                        }).catch((error) => { });
                    }

                })
            }

        });
    }

    const returnOrder = (id) => {

        if(returnPopUp == true){

            if(reson !== ''){

                setResonError('');

                let formData = new FormData();
                formData.append('user_id', 1);
                formData.append('order_id', orderId);
                formData.append('return_reason', reson);
                formData.append('status', 7); // Return request

                axios({
                    url: `${PUBLIC_URL}/returnrequest`,
                    method: 'POST',
                    headers: { Authorization: "Bearer " + user_token },
                    data: formData,
                }).then(response => {

                    if(response.data.status == 1){

                        setReturnPopUp(false);
                        setReson('');

                        toast.success("Return request raised!", {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });

                        let order = ((new URLSearchParams(location.search).get('order')) != '') ? (new URLSearchParams(location.search).get('order')) : '';

                        let formData = new FormData();
                        formData.append('order_number', order);
                        formData.append('user_id', 1);

                        axios({
                            url: `${PUBLIC_URL}/orderdetails`,
                            method: 'POST',
                            headers: { Authorization: "Bearer " + user_token },
                            data: formData,

                        }).then(response => {

                            if (response.data.status == 1) {

                                setOrder(response.data.order_info);
                                setOrderItems(response.data.order_data);
                            }

                        }).catch((error) => { });
                        
                    }

                    if(response.data.status == 0){

                        toast.error(response.data.message, {
                            position: "top-right",
                            autoClose: false,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }

                }).catch((error) => {

                    if(error.response.data.status == 0){

                        toast.error(error.response.data.message, {
                            position: "top-right",
                            autoClose: false,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                });
            }
            else{
                setResonError('Return reason field is required');
            }
        }
        else{
            setReturnPopUp(true);
            setOrderId(id);
        }
    }

    const returnReson = (e) => {

        setReson(e.target.value);
    }

    return (
        <div>
            <div id="page">

                {/* <!-- Header --> */}
                <Header />
                {/* <!-- end header --> */}

                <section className="main-container col1-layout">
                    <div className="container">
                        <div className="breadcrumbs">
                            <ul>
                                <li className="home"> <Link to="/" title="Go to Home Page">Home</Link> <span>/</span> </li>
                                <li> <Link to="/orders" title="Go to Home Page">Order</Link> <span>/</span> </li>
                                <li> <strong>Order Details</strong> </li>
                            </ul>
                        </div>

                        <div className="row">
                            <div className="col-md-3 account-tabs-section extr-margin-bottom">
                                <ul className="nav flex-column account-tabs">
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/profile">Profile</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link active" to="/orders">Orders</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/wishlist">Wishlist</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/address">Address</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-9">
                                <div className="tab-content account-profile">
                                    <fieldset className="group-select">
                                        <legend>Orders</legend>
                                    </fieldset>
                                    <div className="row">

                                        <div className="col-sm-12 col-lg-12" >
                                            <div className="uthssk">

                                                <div className="pous1">
                                                    <h3>Order : {order.order_number}</h3>
                                                    <h4>Date : {order.date}</h4>
                                                    <h4>Status : {order.status}</h4>
                                                    {order.order_notes != null ? <h5>Note : {order.order_notes}</h5> : ''}
                                                </div>
                                                <div className="pous2" style={{ width: '30%' }}>
                                                    <p>Total : AED {getFloatVal(order.grand_total)}</p>
                                                    <h5>Sub Total : AED {getFloatVal(order.subtotal)}</h5>
                                                    <h5>Discount : AED {getFloatVal(order.discount_amount)}</h5>
                                                    <h5>Tax : AED {getFloatVal(order.tax)} </h5>
                                                    <h5>Delivery : AED {getFloatVal(order.shipping_cost)}</h5>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-lg-12" >
                                            {orderItems && orderItems.map((item, index) => {

                                                return (
                                                    <div className="uthssk" key={index}>
                                                        <div className="pous">
                                                            <img src={item.image_url} alt="product" />
                                                        </div>
                                                        <div className="pous1">
                                                            <h3><Link to={`/product-details?product=${item.product_id}`} style={{color: '#000'}}>{item.product_name}</Link></h3>
                                                            {item.attribute != null ? <h3>{item.attribute} : {item.variation}</h3> : ''}
                                                            <h4>Qty : {item.qty}</h4>
                                                            {item.variations && item.variations.map((row, index1) => {
                                                                return<h4 key={index1}>{row.attribute} : {row.variation}</h4>
                                                            })}
                                                            {(item.isreturn==1)? <h4>Return Day : {item.return_days}</h4>:''}
                                                            <h4>Status : {item.status}</h4>
                                                            {item.return_number ? <h4>Return Number: {item.return_number}</h4> : ''}
                                                            {item.returned_at ? <h4>Return at: {item.returned_at}</h4> : ''}
                                                            {item.rejected_at ? <h4>Rejected at: {item.rejected_at}</h4> : ''}
                                                            <Link to={`/order-tracking?order=${item.id}`}><span style={{ cursor: 'pointer', color: '#000' }}><i className="fa fa-map"></i> Track Order</span></Link>
                                                            {item.status == 'Order Placed' ? <span style={{ cursor: 'pointer', color: '#000', marginLeft: '10px' }} onClick={() => cancelOrder(item.id)} ><i className="fa fa-trash"></i> Cancel Order</span> : ''}
                                                            {(item.status == 'Order Confirmed' || item.status == 'Order Shipped' || item.status == 'Order Delivered') && item.isreturn == 1 ? <span style={{ cursor: 'pointer', color: '#000', marginLeft: '10px' }} onClick={() => returnOrder(item.id)} ><i class="fa fa-backward"></i> Return Order</span> : ''}
                                                        </div>
                                                        <div className="pous2" style={{ width: '30%' }}>
                                                            <p>AED {getFloatVal(item.price)}</p>
                                                        </div>
                                                    </div>
                                                );
                                            })}

                                        </div>

                                    </div>

                                    <>
                                        <div className="product-variant" style={returnPopUp == true ? { display: 'block' } : { display: 'none' }}>
                                            <div class="product-variant-modal-content">
                                                <span class="close-icon" onClick={() => setReturnPopUp(false)} style={{ top: -15 }}>&times;</span>
                                                <div className="container-fluid">
                                                    <h3>
                                                    Return Reason
                                                    </h3>
                                                    <hr />
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <textarea className="form-control" onChange={returnReson} placeholder="Return Reson">{reson}</textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {resonError !== '' ? <div className="text-danger">{resonError}</div> : ''}
                                                    <button class="btn btn-primary" onClick={() => returnOrder(orderId)} title="Submit" type="button">Return</button>
                                                </div>
                                            </div>
                                        </div>
                                    </>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Footer --> */}
                <Footer />
                {/* <!-- End Footer -->  */}

            </div>

            {/* <!-- mobile menu --> */}
            <MobileMenu />

            <MobileMenuIcons />

            {/* <!-- JavaScript -->  */}

        </div>
    );
};

export default OrderDetails;