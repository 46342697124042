import React, { Component } from 'react';
import BannerWithSlipping from './BannerWithSlipping';
import ProductItem from './ProductItem';
import { languageList } from '../../languageHelper';
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
class TopProduct extends Component {
    constructor(props) {
        super(props);
        

    }
    
    render() {

        let {sales, toprated, bannerSlipping} = this.props;
        
        return (
            <section className="bb5b">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-4 col-lg-4">
                            <div className="bv5v">
                                <h3>{languageList['SALE'][lang]}</h3>
                                <div className="products-grid yh32d">
                                    {sales && sales.map((item, index) => {
                                        return <ProductItem product={item} key={index} />
                                    })}
                                    
                                </div>
                            </div>
                        </div>

                        <BannerWithSlipping banner={bannerSlipping} />

                        <div className="col-md-6 col-sm-4 col-lg-4">
                            <div className="bv5v">
                                <h3>{languageList['TOP_RATED'][lang]}</h3>
                                <div className="products-grid yh32d">
                                    {toprated && toprated.map((item, index) => {
                                        return <ProductItem product={item} key={index} />
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default TopProduct;