import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { PUBLIC_URL } from '../CommonString';
import { languageList } from '../../../languageHelper';
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
const NewsLetter = () => {

    const [mail, setMail] = useState('');
    const [emailError, setEmailError] = useState('');

    const handleChange = (e) => {

        setMail(e.target.value);
    }

    const handleSubmit = () => {

        if(mail !== ''){

            if(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(mail)){

                let formData = new FormData();
                formData.append('email', mail);

                axios({
                    url: `${PUBLIC_URL}/subscribe`,
                    method: 'POST',
                    data: formData,
                }).then(response => {

                    if(response.data.status == 1){

                        toast.success("Email is added to news letter", {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });

                        setMail('');
                        setEmailError('');

                    }

                }).catch((error) => {

                    if(error.response.data.status == 0){

                        toast.error(error.response.data.message, {
                            position: "top-right",
                            autoClose: false,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });

                    }

                });
            }
            else{
                setEmailError('Enter an email');
            }
        }
        else{
            setEmailError('Email is required');
        }
    }

    return (
        <div className="news-letter-form">
            <div className="container">
                <div className="row">
                    <div className="newsletter-wrap">
                        <div className="col-xs-12">
                            <div className="newsletter">
                                <form>
                                    <div>
                                        <h4><span>{languageList['Sign_up_to_Newsletter'][lang]}</span></h4>
                                        <input type="text" placeholder={languageList['Enter_your_email_address'][lang]} onChange={handleChange} value={mail} className="input-text" title="Sign up for our newsletter" id="newsletter1" name="email" />
                                        <button className="subscribe" onClick={handleSubmit} title="Subscribe" type="button"><span>{languageList['Subscribe'][lang]}</span></button>
                                        {emailError !== '' ? <div className="text-danger" style={{marginLeft: '20%'}}>{emailError}</div> : ''}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewsLetter;