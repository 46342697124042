import axios from 'axios';
import React, { Component } from 'react';
import { ENCRYPTKEY, PUBLIC_URL } from '../CommonString';
import SpinnerComponent from '../SpinnerComponent';
const CryptoJS = require("crypto-js");
let cus_redirect = localStorage.getItem('cus_redirect') ? localStorage.getItem('cus_redirect') : '';

class Login extends Component {

    constructor(props){
        super(props);

        this.state = {
            email: '',
            password: '',
            error: '',
            passwordShow: false,
            token: 'vxgzxhshsh',
            loading: false,
            emailError: '',
            passwordError: '',
            verifyModal: false,
            otp:'',
            otpError:'',
            countTime: 120,
            resendOTP:false
        }
          this.timer = setInterval(() => this.tick(), 1000);
    }
      tick() {
    const current = this.state.countTime;
    if (current === 0) {
      this.transition();
    } else {
      this.setState({ countTime: current - 1 }); 
    } 
  }

  transition() {
    clearInterval(this.timer);
    this.setState({ resendOTP: true }); 
  }
    handleChange = (e) => {

        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    handleSubmit = () => {

        if(this.state.email !== '' && this.state.password !== ''){

            if(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.email)){

                this.setState({
                    loading: true,
                });
                       let cart_data = JSON.parse(localStorage.getItem('mycart'));
                if(!cart_data)
                {
                    cart_data=[];
                }
                let formData = new FormData();
                formData.append('email', this.state.email);
                formData.append('password', this.state.password);
                formData.append('token', this.state.token);
                cart_data.forEach(function(item,index){
                        formData.append('user_id[]', item.user_id);
                        formData.append('product_id[]', item.product_id);
                        formData.append('vendor_id[]', item.vendor_id);
                        formData.append('product_name[]', item.product_name);
                        formData.append('qty[]', item.qty);
                        formData.append('price[]', item.price);
                        formData.append('image[]', item.productObject.productimagewithout_url.image_url);
                        formData.append('discount_amount[]',item.discount_amount);
                        formData.append('tax[]', item.tax);
                        formData.append('shipping_cost[]', item.shipping_cost);
                        formData.append('variation[]', item.variation);
                })
                axios({
                    url: `${PUBLIC_URL}/login`,
                    method: 'POST',
                    data: formData,
                }).then(response => {

                    if(response.data.status == 1){
                        localStorage.removeItem('user_token');
                        localStorage.removeItem('mycart');
                        localStorage.setItem('user_token', response.data.data.access_token);
                        localStorage.setItem('user_name', response.data.data.name);
                        let token=response.data.data.access_token;
                        if(cus_redirect)
                       {
                        localStorage.removeItem('cus_redirect');
                           window.location=cus_redirect;
                       }else{
                        window.location.reload();
                       }
                    }else
                    {
                          this.setState({
                            error: response.data.data.message,
                        });
                    }

                    this.setState({
                        loading: false,
                    });

                }).catch((error) => {
                     console.log(error.response);
                    if((error.response.data.status == 0)||(error.response.data.status == 2)){

                        this.setState({
                            error: error.response.data.message,
                        });
                        if(error.response.data.status == 2){
                             this.setState({
                            verifyModal: true,
                        });
                         this.setState({ countTime: 120 }); 
                         this.setState({
                            resendOTP: false,
                        }); 
                         this.timer = setInterval(() => this.tick(), 1000);
                        }
                    }

                    this.setState({
                        loading: false,
                    });

                });
            }
            else{

                this.setState({
                    emailError: 'Enter a valid email',
                });
            }
        }
        else{

            if(this.state.email === ''){
                this.setState({
                    emailError: 'Email is required',
                });
            }
            else{
                if(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.email)){
                    this.setState({
                        emailError: '',
                    });
                }
                else{
                    this.setState({
                        emailError: 'Enter a valid email',
                    });
                }
            }

            if(this.state.password === ''){
                this.setState({
                    passwordError: 'Password is required',
                });
            }
            else{
                this.setState({
                    passwordError: '',
                });
            }
        }
    }
otpSubmit = () => {

        if(this.state.otp.length == 6 && this.state.otp > 0){

            this.setState({
                loading: true,
            });

            let formData = new FormData();
            formData.append('email', this.state.email);
            formData.append('token', this.state.otp);            
    let cart_data = JSON.parse(localStorage.getItem('mycart'));
                if(!cart_data)
                {
                    cart_data=[];
                }
                 cart_data.forEach(function(item,index){
                        formData.append('user_id[]', item.user_id);
                        formData.append('product_id[]', item.product_id);
                        formData.append('vendor_id[]', item.vendor_id);
                        formData.append('product_name[]', item.product_name);
                        formData.append('qty[]', item.qty);
                        formData.append('price[]', item.price);
                        formData.append('image[]', item.productObject.productimagewithout_url.image_url);
                        formData.append('discount_amount[]',item.discount_amount);
                        formData.append('tax[]', item.tax);
                        formData.append('shipping_cost[]', item.shipping_cost);
                });
            axios({
                url: `${PUBLIC_URL}/emailverify`,
                method: 'POST',
                data: formData,
            }).then(response => {

                if(response.data.status == 1){

                    // let tokenCipher = CryptoJS.AES.encrypt(JSON.stringify(response.data.access_token), ENCRYPTKEY).toString();

                    localStorage.removeItem('user_token');
                     localStorage.removeItem('mycart');
                    localStorage.setItem('user_token', response.data.data.access_token);
                if(cus_redirect)
                       {
                            localStorage.removeItem('cus_redirect');
                           window.location=cus_redirect;
                           
                       }else{
                        window.location.reload();
                       }
                }

                this.setState({
                    loading: false,
                });

            }).catch((error) => {
                
                this.setState({
                    loading: false,
                });
            });
        }
    }
        otpChange = (e) => {
        
        if(e.target.value >= 0 && e.target.value.length >= 0 && e.target.value.length <= 6){

            this.setState({
                otp: e.target.value,
            });

        }
    }
    resendOtp = () => {

            this.setState({
                loading: true,
            });

            let formData = new FormData();
            formData.append('email', this.state.email);      

                
            axios({
                url: `${PUBLIC_URL}/resendemailverification`,
                method: 'POST',
                data: formData,
            }).then(response => {

                if(response.data.status == 1){
                  
                  this.setState({
                        resendOTP: false,
                    });
                    this.setState({ countTime: 120 }); 
                         this.timer = setInterval(() => this.tick(), 1000);
                }

                this.setState({
                    loading: false,
                });

            }).catch((error) => {
                
                this.setState({
                    loading: false,
                });
            });
    }
    render() {

        let {email, password, error, passwordShow, loading, emailError, passwordError,verifyModal,otp,otpError,countTime} = this.state;

        return (
            <>
                <SpinnerComponent open={loading} />

                <div className="modal fade" id="myModalsignin" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header v5c">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            {verifyModal == false ?
                            <div className="modal-body">
                                <div className="yhd0d">
                                    <h1>Welcome back!</h1>
                                    <h2>Sign in to your account</h2>
                                    <h3>Don't have an account? <a href="#" data-toggle="modal" data-target="#myModalsignup">Sign Up</a></h3>
                                </div>
                                <div className="mmc5c">
                                    <label>Email</label>
                                    <input onChange={this.handleChange} type="text" name="email" value={email} />
                                    {emailError !== '' ? <div className="text-danger">{emailError}</div>: '' }
                                </div>
                                <div className="mmc5c">
                                    <label>Password</label>
                                    <input onChange={this.handleChange} type={passwordShow == false ? 'password' : 'text'} name="password" value={password} />
                                    {passwordShow == false ? <span onClick={() => this.showPassword(this.setState({passwordShow: !passwordShow}))}><i className="fa fa-eye-slash"></i></span> : <span onClick={() => this.showPassword(this.setState({passwordShow: !passwordShow}))}><i className="fa fa-eye"></i></span> }
                                    {passwordError !== '' ? <div className="text-danger">{passwordError}</div>: '' }
                                </div>
                                <a className="ipEvhD" href="#" data-toggle="modal" data-target="#myModalforGot">Forgot your password?</a>
                                {error !== '' ? <div className="alert alert-danger">{error}</div>: '' }
                                <button onClick={this.handleSubmit} type="button" className="jcdgCW">Sign In</button>
                            </div>
                            : 
                            <div className="modal-body" style={{minHeight: '500px'}}>
                                    <div className="yhd0d">
                                        <h2>Verify Your Email</h2>
                                        <br />
                                        <h5 className="text-danger">An otp has been send to Your registered email</h5>
                                    </div>
                                    
                                    <div className="mmc5c">
                                        <label>OTP</label>
                                        <input onChange={this.otpChange} type="number" name="otp" value={otp} />
                                        <p className="text-info">{this.state.countTime} remaining</p>
                                    </div>
                                    {otpError !== '' ? <div className="alert alert-danger">{otpError}</div> : '' }
                                    <br />
                                    {this.state.resendOTP?<button   className="jcdgCW" onClick={this.resendOtp}>Resend OTP</button>:<button onClick={this.otpSubmit} data-toggle="modal" data-target="#myModalVerifyEmail" className="jcdgCW">Verify OTP</button>}
                                    <br />
                                    <div className="text-center">
                                        <a href="javascript:void(0);" onClick={() => this.setState({verifyModal: !verifyModal})}>Back</a>
                                    </div>
                                </div>
                           }
                                   </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Login;