import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ProductItemWithDetails from './ProductItemWithDetails';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


var jQuery = require("jquery");

class HotDeal extends Component {
    constructor(props) {
        super(props);

        
    }

    // cartEvent = () => {
    //     alert("hiiiii");
    //     this.props.cartEvent();
    // }

    render() {

        let { hotDeal, cartEvent, title } = this.props;

        const option = {
            items:5, 
            nave:false,
            responsive:{
                0:{
                    items:1
                },
                600:{
                    items:2
                },
                1000:{
                    items:4
                }
            }
        }
        // console.log(hotDeal.length);
        return (
            <div className="container">
                <div className="bestsell-pro">
                    <div>
                        <div className="slider-items-products">
                            <div className="bestsell-block">
                                <div className="block-title">
                                    <h2>{title}</h2>
                                </div>
                                <div id="bestsell-slider" className="product-flexslider hidden-buttons">
                                    <div className="slider-items slider-width-col4 products-grid block-content">
                                {hotDeal.length > 0 ?
                                    <OwlCarousel 
                                        className='owl-theme' 
                                        {...option}
                                    >
                                            {hotDeal && hotDeal.map((item, index) => {

                                                return <ProductItemWithDetails item={item} cartEvent={()=>this.props.cartEvent()} />
                                            })}
                                    </OwlCarousel>
                                    : ''}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HotDeal;