import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PUBLIC_URL } from '../../common/CommonString';

let ciphertext = localStorage.getItem('user_token') ? localStorage.getItem('user_token') : '';
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';

class ProductItem extends Component {

    constructor(props) {
        super(props);


    }

    addToCart = () => {
       if ((ciphertext === '')||(ciphertext==='null')) {
        toast.error("Please login before added to cart!", {
                position: "top-right",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
           return;
       }
        let product = this.props.product;

        let taxvalue = 0;
        let proPrice=product.discounted_price == 0 || product.discounted_price == null || product.discounted_price == 'null' ? product.product_price : product.discounted_price;
        if(product.tax_type === 'percent'){

            taxvalue = proPrice * (product.tax / 100);

        }
        else{
            taxvalue = product.tax;
        }

        let formData = new FormData();
        formData.append('user_id', 1);
        formData.append('product_id', product.id);
        formData.append('vendor_id', product.vendor_id);
        formData.append('product_name', product.product_name);
        formData.append('qty', 1);
        formData.append('price', proPrice);
        formData.append('image', product.productimagewithout_url.image_url);
        formData.append('discount_amount', 0);
        formData.append('tax', taxvalue);
        formData.append('shipping_cost', product.shipping_cost);

        axios({
            url: `${PUBLIC_URL}/addtocart`,
            method: 'POST',
            headers: { Authorization: "Bearer " + ciphertext },
            data: formData,

        }).then(response => {

            if (response.data.status == 1) {

                toast.success("Product is added to cart!", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                this.props.cartEvent();
            }else{
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            }

        }).catch((error) => {
            
         });
    }

    addToWishlist = (id) => {
   if ((ciphertext === '')||(ciphertext==='null')) {
        toast.error("Please login before added to wishlist!", {
                position: "top-right",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
           return;
       }
        let formData = new FormData();
        formData.append('user_id', 1);
        formData.append('product_id', id);
        
        axios({
            url: `${PUBLIC_URL}/addtowishlist`,
            method: 'POST',
            headers: { Authorization: "Bearer " + ciphertext },
            data: formData,

        }).then(response => {

            if (response.data.status == 1) {

                toast.success("Product is added to wishlist!", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

        }).catch((error) => {});
    }

    render() {

        let { iteration, product } = this.props;

        let rating = product.rattings.length > 0 ? product.rattings[0].avg_ratting : 0;

        return (
            <>
                <li className={iteration == 0 ? 'item first' : iteration % 2 == 0 ? 'item even' : 'item odd'}>
                    <div className="product-image">
                        <Link to={`/product-details?product=${product.id}`} title={product.product_name}>
                            {/* <img className="myfeatured-products" src={product.productimage.image_url} alt={product.product_name} /> */}
                            <div style={{height: '200px', width: '200px',backgroundSize:'contain',backgroundRepeat:'no-repeat',backgroundPosition:'center',backgroundImage:`url("${product.productimage.image_url}")`}} ></div>
                        </Link>

                    </div>
                    <div className="product-shop">
                        <h2 className="product-name">
                            <Link to={`/product-details?product=${product.id}`} title={product.product_name}>{lang== 'en'?product.product_name: product.multi_name ? product.multi_name.arabic:product.product_name }</Link>
                        </h2>
                        <div className="ratings">
                            <div className="rating-box">
                                <div style={{ width: `${rating * 20}%` }} className="rating"></div>
                            </div>
                            <p className="rating-links"> <a href="#">1 Review(s)</a> <span className="separator">|</span> <a
                                href="#review-form">Add Your Review</a> </p>
                        </div>
                        <div className="desc std">
                            <p>{product.description ? product.description.substring(0, 150) + '...' : ''}
                                <Link className="link-learn" title="" to={`/product-details?product=${product.id}`}>Learn More</Link>
                            </p>
                        </div>
                        <div className="price-box">
                            {product.discounted_price == 0 || product.discounted_price == null || product.discounted_price === 'null' ? '' 
                            :<p className="old-price"> <span className="price-label"></span> <span className="price"> AED {product.discounted_price == 0 || product.discounted_price == null || product.discounted_price === 'null' ? 0 : product.product_price} </span></p>}
                            <p className="special-price"> <span className="price-label"></span> <span className="price">AED {product.discounted_price == 0 || product.discounted_price == null || product.discounted_price === 'null' ? product.product_price : product.discounted_price} </span> </p>
                        </div>
                        <div className="actions">
                            <button onClick={this.addToCart} className="button btn-cart ajx-cart" title="Add to Cart" type="button">
                                <span>Add to Cart</span>
                            </button>
                            <span className="add-to-links">
                                <a title="Add to Wishlist" onClick={() => this.addToWishlist(product.id)} className="button link-wishlist" href="javascript:void(0);"></a>
                                {/* <a title="Add to Compare" className="button link-compare" href="compare.html"></a>  */}
                            </span>
                        </div>
                    </div>
                </li>
            </>
        );
    }
}

export default ProductItem;