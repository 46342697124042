import React, { Component } from 'react';
import { languageList } from '../../languageHelper';

let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';

class InfoBox extends Component {
    render() {
        return (
            <div className="info-boxes wow fadeInUp">
                <div className="container info-boxes-inner">
                    <div className="row">
                        <div className="col-md-6 col-sm-4 col-lg-4">
                            <div className="info-box">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <img src="images/discount.png" alt="" />
                                        <h4 className="info-box-heading green">{languageList['EXCITING_OFFERS'][lang]}</h4>
                                    </div>
                                </div>
                                <h6 className="text">{languageList['Up_to_50%_discount'][lang]}</h6>
                            </div>
                        </div>
                        {/* <!-- .col --> */}

                        <div className="hidden-md col-sm-4 col-lg-4">
                            <div className="info-box">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <img src="images/free-shipping.jpg" alt="" />
                                        <h4 className="info-box-heading green">{languageList['FREE_SHIPPING'][lang]}</h4>
                                    </div>
                                </div>
                                <h6 className="text">{languageList['Shipping_on_orders_over_AED_99'][lang]}</h6>
                            </div>
                        </div>
                        {/* <!-- .col --> */}

                        <div className="col-md-6 col-sm-4 col-lg-4">
                            <div className="info-box">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <img src="images/SpecialSale.png" alt="" />
                                        <h4 className="info-box-heading green">{languageList['Special_Sale'][lang]}</h4>
                                    </div>
                                </div>
                                <h6 className="text">{languageList['Extra_AED_5_off_on_all_items'][lang]} </h6>
                            </div>
                        </div>
                        {/* <!-- .col -->  */}
                    </div>
                    {/* <!-- /.row -->  */}
                </div>
            </div>
        );
    }
}

export default InfoBox;