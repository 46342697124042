import axios from 'axios';
import { Modal } from 'react-bootstrap';
import React, { Component } from 'react';
import { ENCRYPTKEY, PUBLIC_URL } from '../CommonString';
import SpinnerComponent from '../SpinnerComponent';
const CryptoJS = require("crypto-js");
let cus_redirect = localStorage.getItem('cus_redirect') ? localStorage.getItem('cus_redirect') : '';
class Register extends Component {

    constructor(props){
        super(props);

        this.state = {
            email: '',
            password: '',
            name: '',
            last_name:'',
            mobile: '',
            passwordShow: false,
            token: 'vxgzxhshsh',
            type: 'email',
            registerType: 'email',
            loginType: 'email',
            wallet: 0,
            referral: 'EnoTSqC68l',
            error: '',
            verifyModal: false,
            otp: '',
            otpError: '',
            loading: false,
            nameError: '',
            emailError: '',
            mobileError: '',
            passwordError: '',
            countryCode:'+971',
            countTime: 120,
            resendOTP:false
        }
        this.timer = setInterval(() => this.tick(), 1000);
    }
      tick() {
    const current = this.state.countTime;
    if (current === 0) {
      this.transition();
    } else {
      this.setState({ countTime: current - 1 }); 
    } 
  }

  transition() {
    clearInterval(this.timer);
    this.setState({ resendOTP: true }); 
  }
    handleChange = (e) => {

        if(e.target.name === 'mobile'){

            if(e.target.value >= 0 ){
                this.setState({
                    [e.target.name]: e.target.value,
                });
            }
            
        }
        else{
            this.setState({
                [e.target.name]: e.target.value,
            });
        }
    }

    handleSubmit = () => {

        let state = this.state;

        if(state.name !== '' && state.email !== '' && state.password !== '' && state.mobile !== ''){
            if(state.mobile.length!==9)
            {
                this.setState({
                    mobileError: 'Enter a valid mobile number',
                });
                return;
            }
            if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(state.email)) {

                this.setState({
                    loading: true,
                });

                let formData = new FormData();
                formData.append('name', state.name);
                formData.append('last_name', state.last_name);
                formData.append('email', state.email);
                formData.append('password', state.password);
                formData.append('mobile', state.mobile);
                formData.append('country_code', state.countryCode);
                formData.append('token', state.token);
                formData.append('type', state.type);
                formData.append('register_type', state.registerType);
                formData.append('login_type', state.loginType);

                axios({
                    url: `${PUBLIC_URL}/register`,
                    method: 'POST',
                    data: formData,

                }).then(response => {

                    if(response.data.status == 1){
                        this.setState({
                            verifyModal: true,
                        });
                         this.setState({ countTime: 120 }); 
                         this.timer = setInterval(() => this.tick(), 1000);
                         this.setState({
                            resendOTP: false,
                        }); 
                    }else{
                        this.setState({
                            error: response.data.message,
                        }); 
                    }

                    this.setState({
                        loading: false,
                    });
                }).catch((error) => {
                    
                    if(error.response.data.status == 0){

                        this.setState({
                            error: error.response.data.message,
                        });
                    }

                    this.setState({
                        loading: false,
                    });
                });
            }
            else{
                this.setState({
                    emailError: 'Enter a valid email',
                    nameError: '',
                    mobileError: '',
                    passwordError: '',
                });
            }
        }
        else{

            if(state.name === ''){
                this.setState({
                    nameError: 'Name is requred',
                });
            }
            else{
                this.setState({
                    nameError: '',
                });
            }

            if(state.email === ''){
                this.setState({
                    emailError: 'Email is required',
                });
            }
            else{

                this.setState({
                    emailError: '',
                });
            }

            if(state.mobile === ''){
                this.setState({
                    mobileError: 'Mobile is required',
                });
            }
            else{

                this.setState({
                    mobileError: '',
                });
            }

            if(state.password === ''){
                this.setState({
                    passwordError: 'Password is required',
                });
            }
            else{

                this.setState({
                    passwordError: '',
                });
            }
            
        }

    }

    otpChange = (e) => {
        
        if(e.target.value >= 0 && e.target.value.length >= 0 && e.target.value.length <= 6){

            this.setState({
                otp: e.target.value,
            });

        }
    }

    otpSubmit = () => {

        if(this.state.otp.length == 6 && this.state.otp > 0){

            this.setState({
                loading: true,
            });

            let formData = new FormData();
            formData.append('email', this.state.email);
            formData.append('token', this.state.otp);            
    let cart_data = JSON.parse(localStorage.getItem('mycart'));
                if(!cart_data)
                {
                    cart_data=[];
                }
                 cart_data.forEach(function(item,index){
                        formData.append('user_id[]', item.user_id);
                        formData.append('product_id[]', item.product_id);
                        formData.append('vendor_id[]', item.vendor_id);
                        formData.append('product_name[]', item.product_name);
                        formData.append('qty[]', item.qty);
                        formData.append('price[]', item.price);
                        formData.append('image[]', item.productObject.productimagewithout_url.image_url);
                        formData.append('discount_amount[]',item.discount_amount);
                        formData.append('tax[]', item.tax);
                        formData.append('shipping_cost[]', item.shipping_cost);
                        formData.append('variation[]', item.variation);
                });
            axios({
                url: `${PUBLIC_URL}/emailverify`,
                method: 'POST',
                data: formData,
            }).then(response => {

                if(response.data.status == 1){

                    // let tokenCipher = CryptoJS.AES.encrypt(JSON.stringify(response.data.access_token), ENCRYPTKEY).toString();

                    localStorage.removeItem('user_token');
                     localStorage.removeItem('mycart');
                    localStorage.setItem('user_token', response.data.data.access_token);
                if(cus_redirect)
                       {
                            localStorage.removeItem('cus_redirect');
                           window.location=cus_redirect;
                           
                       }else{
                        window.location.reload();
                       }
                }

                this.setState({
                    loading: false,
                });

            }).catch((error) => {
                
                this.setState({
                    loading: false,
                });
            });
        }
    }
resendOtp = () => {

            this.setState({
                loading: true,
            });

            let formData = new FormData();
            formData.append('email', this.state.email);      

                
            axios({
                url: `${PUBLIC_URL}/resendemailverification`,
                method: 'POST',
                data: formData,
            }).then(response => {

                if(response.data.status == 1){
                  
                  this.setState({
                        resendOTP: false,
                    });
                    this.setState({ countTime: 120 }); 
                         this.timer = setInterval(() => this.tick(), 1000);
                }

                this.setState({
                    loading: false,
                });

            }).catch((error) => {
                
                this.setState({
                    loading: false,
                });
            });
    }


    render() {

        let {
            email,
            password,
            name,
            last_name,
            mobile,
            passwordShow,
            token,
            type,
            registerType,
            loginType,
            wallet,
            referral,
            error,
            verifyModal,
            otp,
            otpError,
            loading,
            nameError,
            emailError,
            mobileError,
            passwordError,
            countryCode,
            countTime
        } = this.state;


        return (
            <>
                <SpinnerComponent open={loading} />

                <div className="modal fade" id="myModalsignup" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header v5c">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            {verifyModal == false ?
                                <div className="modal-body">
                                    <div className="yhd0d">
                                        <h2>Create an account</h2>
                                        <h3>Already have an account? <a href="#" data-dismiss="modal">Sign In</a></h3>
                                    </div>
                                    <div className="mmc5c">
                                        <label>Email</label>
                                        <input onChange={this.handleChange} type="text" name="email" value={email} />
                                        {emailError !== '' ? <div className="text-danger">{emailError}</div> : ''}
                                    </div>
                                    <div className="mmc5c">
                                        <label>Password</label>
                                        <input onChange={this.handleChange} type={passwordShow == false ? 'password' : 'text'} name="password" value={password} />
                                        {passwordShow == false ? <span onClick={() => this.showPassword(this.setState({passwordShow: !passwordShow}))}><i className="fa fa-eye-slash"></i></span> : <span onClick={() => this.showPassword(this.setState({passwordShow: !passwordShow}))}><i className="fa fa-eye"></i></span> }
                                        {passwordError !== '' ? <div className="text-danger">{passwordError}</div> : ''}
                                    </div>
                                    <div className="mmc5c">
                                      <div style={{width:'50%',display:'inline-block'}}>
                                        <label>First Name</label>
                                        <input onChange={this.handleChange} type="text" name="name" value={name} />
                                        {nameError !== '' ? <div className="text-danger">{nameError}</div> : ''}
                                      </div>
                                      <div style={{width:'50%',display:'inline-block'}}>
                                        <label>Last Name</label>
                                        <input onChange={this.handleChange} type="text" name="last_name" value={last_name} />
                                        {nameError !== '' ? <div className="text-danger">{nameError}</div> : ''}
                                      </div>
                                    </div>
                                    <div className="mmc5c">
                                      <div style={{width:'30%',display:'inline-block'}}>
                                        <label>Code</label>
                                        <select onChange={this.handleChange} type="text" name="countryCode" value={countryCode} style={{width:'90%'}}>
                                           <option value="+971">UAE(+971)</option>
                                        </select>
                                      </div>
                                       <div style={{width:'70%',display:'inline-block'}}>
                                        <label>Mobile</label>
                                        <input onChange={this.handleChange} type="number" name="mobile" value={mobile} />
                                        {mobileError !== '' ? <div className="text-danger">{mobileError}</div> : ''}
                                        </div>
                                    </div>
                                    {error !== '' ? <div className="alert alert-danger">{error}</div>: '' }
                                    <button onClick={this.handleSubmit} className="jcdgCW">Create an account</button>
                                </div>
                                : 
                                <div className="modal-body" style={{minHeight: '500px'}}>
                                    <div className="yhd0d">
                                        <h2>Verify Your Email</h2>
                                        <br />
                                        <h5 className="text-danger">An otp has been send to Your registered email</h5>
                                    </div>
                                    
                                    <div className="mmc5c">
                                        <label>OTP</label>
                                        <input onChange={this.otpChange} type="number" name="otp" value={otp} />
                                        <p className="text-info">{this.state.countTime} remaining</p>
                                    </div>
                                    {otpError !== '' ? <div className="alert alert-danger">{otpError}</div> : '' }
                                    <br />
                                    {this.state.resendOTP?<button   className="jcdgCW" onClick={this.resendOtp}>Resend OTP</button>:<button onClick={this.otpSubmit} data-toggle="modal" data-target="#myModalVerifyEmail" className="jcdgCW">Verify OTP</button>}
                                    
                                    <br />
                                    
                                    <div className="text-center">
                                        <a href="javascript:void(0);" onClick={() => this.setState({verifyModal: !verifyModal})}>Back</a>
                                    </div>
                                </div>
                                }
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Register;