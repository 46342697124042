import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../common/layout/Footer';
import Header from '../common/layout/Header';
import MobileMenu from '../common/layout/MobileMenu';
import MobileMenuIcons from '../common/layout/MobileMenuIcons';

const Index = () => {
    return (
        <>
            <div id="page">
                {/* <!-- Header --> */}
                <Header />
                {/* <!-- end header -->  */}

                {/* <!-- Main Container --> */}
                <section className="main-container col1-layout">
                    <div className="container">
                        {/* <!-- Breadcrumbs --> */}
                        <div className="breadcrumbs">
                            <ul>
                                <li className="home"> <Link to="/" title="Go to Home Page">Home</Link> <span>/</span> </li>
                                <li> <strong>About</strong> </li>
                            </ul>
                        </div>
                        {/* <!-- Breadcrumbs End --> */}
                        <div className="row">
                            <div className="col-sm-12 col-xs-12">
                                <article className="col-main">
                                    <div className="cart">
                                        <div className="page-title">
                                            <h2>About</h2>
                                        </div>
                                        <div className="row">
                                            <div className="text-center">
                                                <h3>Buyforless</h3>
                                                <h4>Providing you with the most actionable App Store data.</h4>
                                            </div>
                                            <br /><br />
                                            <div className="col-sm-12 col-md-6">
                                                <img src="/images/blog-img1.jpg" style={{ maxWidth: '100%'}} className="img-fluid" alt="about" />
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ita credo. Paria sunt </p>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ita credo. Paria sunt igitur. 
                                                    Qui ita affectus, beatum esse numquam probabis; Sumenda potius quam expetenda. Que Manilium, 
                                                    ab iisque M. Quae diligentissime contra Aristonem dicuntur a Chryippo
                                                </p>
                                                <p>
                                                    Primum divisit ineleganter; Habes, inquam, Cato, formam eorum, de quibus loquor, philosophorum. 
                                                    Nihilo magis. Nihil opus est exemplis hoc facere longius.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Main Container End --> */}

                {/* <!-- Footer --> */}
                <Footer />
                {/* <!-- End Footer -->  */}
            </div>
            {/* <!-- mobile menu --> */}
            <MobileMenu />

            <MobileMenuIcons />

            {/* <!-- JavaScript -->  */}
        </>
    );
};

export default Index;