import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Banner extends Component {

    constructor(props) {
        super(props);

    }

    render() {

        let {banner} = this.props;

        return (
            <>
                <div className="category-description std">
                    <div className="slider-items-products">
                        <div id="category-desc-slider" className="product-flexslider hidden-buttons">
                            <div className="slider-items slider-width-col1 owl-carousel owl-theme">

                                {banner && banner.map((item, index) => {

                                    return (
                                        <div className="item" key={index}>
                                            <Link to={`/product-details?product=${item.product_id}`}>
                                                <img alt={item.title1} src={item.image_url} />
                                            </Link>
                                            <div className="cat-img-title cat-bg cat-box">
                                                <div className="small-tag">{item.title1}</div>
                                                <h2 className="cat-heading">{item.title2}</h2>
                                                <p>{item.title3} </p>
                                            </div>
                                        </div>
                                    );
                                })}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Banner;