import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../common/layout/Footer';
import Header from '../common/layout/Header';
import MobileMenu from '../common/layout/MobileMenu';
import MobileMenuIcons from '../common/layout/MobileMenuIcons';
import { useLocation } from 'react-router';
import axios from 'axios';
import { PUBLIC_URL } from '../common/CommonString';

let ciphertext = localStorage.getItem('user_token');

const Index = () => {

    const location = useLocation();
    const payment_id = ((new URLSearchParams(location.search).get('payment_id')) != '') ? (new URLSearchParams(location.search).get('payment_id')) : '';

    const [transactionStatus, setTransactionStatus] = useState(null);
     const [data, setdata] = useState({});
    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {

        if (firstLoad == true) {

            let formData = new FormData();
            formData.append('payment_id', payment_id)

            axios({
                url: `${PUBLIC_URL}/payment_status`,
                method: 'POST',
                headers: { Authorization: "Bearer " + ciphertext },
                data: formData,
            }).then(response => {
                 console.log(response.data);
                if (response.data.found == 1) {
                    setdata(response.data.data);
                    setTransactionStatus(response.data.data.status);
                }

            }).catch((error) => { });
        }

        setFirstLoad(false);

    });
    let content;
    if(transactionStatus==1)
    {
        content=    (<div className="cart">
    <div className="page-title">
        <h2 style={{textAlign:'center',width:'100%',fontSize:'50px',color:'green'}}>Payment Success</h2>
    </div>
    <div className="row">
        <div className="col-sm-12 col-lg-12"  style={{textAlign:'center',width:'100%',fontSize:'25px',color:'green'}}>
            Your  Order: {data.order_number} Placed with Transaction id: {data.payment_id}<br/>
            <a href={'thankyou?order='+data.order_number}>view details</a>
        </div>
       
    </div>
</div>)
    }else if (transactionStatus==3)
    {
        content=    (<div className="cart">
    <div className="page-title">
        <h2 style={{textAlign:'center',width:'100%',fontSize:'50px',color:'red'}}>Payment Failed</h2>
    </div>
    <div className="row">
        <div className="col-sm-12 col-lg-12"  style={{textAlign:'center',width:'100%',fontSize:'25px',color:'red'}}>
              Your Card Payment has been failed ,Please check the details and try again
        </div>
       
    </div>
</div>)
        
    }else{
        content= '';
    }
    return (
        <>
            <div id="page">
                {/* <!-- Header --> */}
                <Header />
                {/* <!-- end header -->  */}

                {/* <!-- Main Container --> */}
                <section className="main-container col1-layout">
                    <div className="container">
                        <div className="row">
                            
                            <div className="col-sm-12 col-xs-12">
                                <article className="col-main">
                                    {content}
                                </article>
                                {/* <!--	======    End article  =========  -->  */}
                            </div>

                        </div>
                    </div>
                </section>
                {/* <!-- Main Container End --> */}

                {/* <!-- Footer --> */}
                <Footer />
                {/* <!-- End Footer -->  */}
            </div>
            {/* <!-- mobile menu --> */}
            <MobileMenu />

            <MobileMenuIcons />

            {/* <!-- JavaScript -->  */}
        </>
    );
};

export default Index;