import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import RevSlider, { Slide, Caption } from 'react-rev-slider';
import { languageList } from '../../languageHelper';
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';

class Banner extends Component {

    constructor(props) {
        super(props);

    }

    render() {

        let { slider } = this.props;
        const config = {
            // delay:6, 
            // startwidth:1170,
            // startheight:500,
            // hideThumbs:10,
            // fullWidth:"on",
            // forceFullWidth:"on"

            // delay: 3,
            startheight: 500,
            startwidth: 960,
            fullScreenAlignForce: "off",
            autoHeight: "off",
            hideTimerBar: "off",
            hideThumbs: 200,
            hideNavDelayOnMobile: 1500,
            thumbWidth: 100,
            thumbHeight: 50,
            thumbAmount: 3,
            navigationType: "bullet",
            navigationArrows: "solo",
            hideThumbsOnMobile: "off",
            hideBulletsOnMobile: "off",
            hideArrowsOnMobile: "off",
            hideThumbsUnderResoluition: 0,
            navigationStyle: "round",
            navigationHAlign: "center",
            navigationVAlign: "bottom",
            navigationHOffset: 0,
            navigationVOffset: 20,
            soloArrowLeftHalign: "left",
            soloArrowLeftValign: "center",
            soloArrowLeftHOffset: 20,
            soloArrowLeftVOffset: 0,
            soloArrowRightHalign: "right",
            soloArrowRightValign: "center",
            soloArrowRightHOffset: 20,
            soloArrowRightVOffset: 0,
            keyboardNavigation: "on",
            touchenabled: "on",
            onHoverStop: "on",
            stopAtSlide: -1,
            stopAfterLoops: -1,
            hideCaptionAtLimit: 0,
            hideAllCaptionAtLimit: 0,
            hideSliderAtLimit: 0,
            shadow: 0,
            fullWidth: "off",
            fullScreen: "off",
            minFullScreenHeight: 0,
            fullScreenOffsetContainer: "",
            dottedOverlay: "none",
            forceFullWidth: "off",
            spinner: "spinner0",
            swipe_velocity: .4,
            swipe_max_touches: 1,
            swipe_min_touches: 1,
            drag_block_vertical: false,
            isJoomla: false,
            parallax: "off",
            parallaxLevels: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85]

        };

        return (

            <div className="container-fluid">
                <div className="row">
                    <div className="home-slider5">
                        <div id="thmg-slideshow" class="thmg-slideshow">
                            <div id='rev_slider_4_wrapper' class='rev_slider_wrapper fullwidthbanner-container'>
                                <div style={{ position: 'relative', width: '100%', height: 'auto', marginTop: '0px', marginBottom: '0px' }} className="forcefullwidth_wrapper_tp_banner">
                                    <RevSlider config={config}>
                                        {/* <Slide
                                            src="https://i.ytimg.com/vi/dFnvYtPePRA/maxresdefault.jpg"
                                            alt="slidebg1"
                                            data-bgfit="cover"
                                            data-bgposition="left top"
                                            data-bgrepeat="no-repeat"
                                            slideProperties={{
                                                'data-transition': "fade",
                                                'data-slotamount': "7",
                                                'data-masterspeed': "1500"
                                            }}
                                        >   
                                            <Caption
                                                class="tp-caption skewfromrightshort fadeout"
                                                data-x="85"
                                                data-y="224"
                                                data-speed="500"
                                                data-start="1200"
                                                data-easing="Power4.easeOut"
                                            >
                                                This is a caption
                                            </Caption>
                                        </Slide> */}
                                        {slider && slider.map((item, index) => {
                                           if(item.web_or_app=='app')
                                           {
                                            return true;
                                           }
                                           return (
                                                <Slide
                                                    key={index}
                                                    src={item.image}
                                                    alt="slidebg1"
                                                    data-bgfit="cover"
                                                    data-bgposition="center center"
                                                    data-bgrepeat="no-repeat"
                                                    slideProperties={{
                                                        'data-transition': "random",
                                                        'data-slotamount': "7",
                                                        'data-masterspeed': "1000"
                                                    }}
                                                >
                                                    <div class="col-lg-3 col-sm-3 col-md-3">&nbsp;</div>
                                                    <div class="col-lg-9 col-sm-9 col-md-9">
                                                        <div className="info">
                                                            <Caption
                                                                class="tp-caption ExtraLargeTitle sft  tp-resizeme"
                                                                data-endspeed={500}
                                                                data-speed={500}
                                                                data-start={1100}
                                                                data-easing="Linear.easeNone"
                                                                data-splitin="none"
                                                                data-splitout="none"
                                                                data-elementdelay="0.1"
                                                                data-endelementdelay="0.1"
                                                            >
                                                                <span>{lang=='en'?item.title1:item.multi_title1? item.multi_title1.arabic :item.title1}</span>
                                                                {/* <Caption>
                                                                    <div classname="info">
                                                                    <div classname="tp-caption ExtraLargeTitle sft  tp-resizeme " data-endspeed={500} data-speed={500} data-start={1100} data-easing="Linear.easeNone" data-splitin="none" data-splitout="none" data-elementdelay="0.1" data-endelementdelay="0.1" style={{zIndex: 2, whiteSpace: 'nowrap'}}><span>Digital World</span> </div>
                                                                    <div classname="tp-caption LargeTitle sfl  tp-resizeme " data-endspeed={500} data-speed={500} data-start={1300} data-easing="Linear.easeNone" data-splitin="none" data-splitout="none" data-elementdelay="0.1" data-endelementdelay="0.1" style={{zIndex: 3, whiteSpace: 'nowrap'}}><span style={{fontWeight: 'normal', display: 'block'}}>Smartphone</span> INCREDIBLE </div>
                                                                    <div classname="tp-caption sfb  tp-resizeme " data-endspeed={500} data-speed={500} data-start={1500} data-easing="Linear.easeNone" data-splitin="none" data-splitout="none" data-elementdelay="0.1" data-endelementdelay="0.1" style={{zIndex: 4, whiteSpace: 'nowrap'}}><a href="#" className="buy-btn">Shop Now</a> </div>
                                                                </div> */}
                                                            </Caption>
                                                            <Caption
                                                                class="tp-caption LargeTitle sfl  tp-resizeme "
                                                                data-endspeed={500}
                                                                data-speed={500}
                                                                data-start={1300}
                                                                data-easing="Linear.easeNone"
                                                                data-splitin="none"
                                                                data-splitout="none"
                                                                data-elementdelay="0.1"
                                                                data-endelementdelay="0.1"

                                                            >
                                                                <span style={{ fontWeight: 'normal', display: 'block' }}>{lang=='en'?item.title2:item.multi_title2? item.multi_title2.arabic :item.title2}</span> {lang=='en'?item.title3:item.multi_title3? item.multi_title3.arabic :item.title3}
                                                            </Caption>
                                                            <Caption
                                                                class="tp-caption sfb  tp-resizeme  "
                                                                data-endspeed={500}
                                                                data-speed={500}
                                                                data-start={1500}
                                                                data-easing="Linear.easeNone"
                                                                data-splitin="none"
                                                                data-splitout="none"
                                                                data-elementdelay="0.1"
                                                                data-endelementdelay="0.1"

                                                            >
                                                                {item.type === 'category' ? 
                                                                <Link to={`/product-grid?category=${item.cat_id}&innersubcategory=&subcategory=&category_name=${item.category ? item.category.category_name : ''}`} className="buy-btn">{languageList['Shop_now'][lang]}</Link>
                                                                : <Link to={`/product-details?product=${item.product_id}`} className="buy-btn">{languageList['Shop_now'][lang]}</Link>}
                                                            </Caption>

                                                        </div>
                                                    </div>
                                                </Slide>
                                            );
                                        })}
                                    </RevSlider>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Banner;