import React from 'react';
import { Link } from 'react-router-dom';
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';

const ProductItem = (props) => {

    let {product} = props;
    let rating = product.rattings.length > 0 ? product.rattings[0].avg_ratting : 0;

    return (
       
        <div className="item">
            <div className="item-inner">
                <div className="item-img">
                    <div className="item-img-info">
                        <Link to={`/product-details?product=${product.id}`} title="Retis lapen casen" className="product-image">
                            <img src={product.productimage ? product.productimage.image_url : ''} alt={product.product_name} className="myfeatured-products" />
                        </Link>
                    </div>
                </div>
                <div className="item-info">
                    <div className="info-inner">
                        <div className="item-title"> <Link to={`/product-details?product=${product.id}`} title="Retis lapen casen">{lang=='en'?product.product_name ? product.product_name.substring(0, 30) + '...' : '':product.multi_name ? product.multi_name.arabic.substring(0, 30) + '...' : product.product_name.substring(0, 30) + '...'}</Link> </div>
                        <div className="brand">{product.brand ? product.brand.brand_name : ''}</div>
                        <div className="item-content">
                            <div className="star-rating">
                                <span style={{ width: `${rating * 20}%` }}>Rated <strong className="rating">{rating}</strong> out of 5</span>
                            </div>
                            <div className="item-price">
                                <div className="price-box"> <span className="regular-price"> <span className="price">AED {product.discounted_price == 0 || product.discounted_price == null || product.discounted_price === 'null' ? product.product_price : product.discounted_price}</span> </span> <span className="old-price"><span className="price">AED {product.discounted_price == 0 || product.discounted_price == null || product.discounted_price === 'null' ? 0 : product.product_price}</span></span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    );
};

export default ProductItem;