export const getFloatVal=(input)=>{
   let output= parseFloat(input);
   if(isNaN(output))
   {
    return 0.00
   }else{
    return output.toFixed(2);
   }
}
 function getDateDMY(input){
   let separator='/';
   let newDate=new Date(input);
   let date = newDate.getDate();
   let month = newDate.getMonth() + 1;
   let year = newDate.getFullYear();

   return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`
   }
   function timehia (time) {
      // Check correct time format and split into components
      time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) { // If time format correct
        time = time.slice (1);  // Remove full string match value
        time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join (''); // return adjusted time or original string
    }
export const convetTimeStamp=(input)=>
{
   if(input)
   {
      let date_time= input.split(' ');
      return getDateDMY(date_time[0])+' '+timehia(date_time[1]);
   }else{
      return '';
   }
}

