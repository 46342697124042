import React from 'react';

const PaginationLink = (props) => {

    const HtmlToReactParser = require('html-to-react').Parser;
    const htmlToReactParser = new HtmlToReactParser();

    let {links} = props;

    const pageUpdate = (page) => {

        props.pageUpdate(page);
    }


    let to = (links.total / 10).toString();
    to = to.split('.');
    to = parseInt(to);

    if(links.total % links.per_page > 0){
        to = to + 1;
    }

    let rows = [];
                        
    for (let i = 1; i <= to; i++) {

        let path = links.path + '?page=' + i;
        
        if(links.current_page == i)
            rows.push(<li onClick={() => pageUpdate(path)} className="active"><a href="javascript:void(0);">{i}</a></li>);
        else
            rows.push(<li onClick={() => pageUpdate(path)} ><a href="javascript:void(0);">{i}</a></li>);
    }
    

    return (
        <>
            <div className="pages">
                <ul className="pagination">
                    {links.prev_page_url ? <li onClick={() => pageUpdate(links.prev_page_url)}><a href="javascript:void(0);">&laquo;</a></li> :
                    <li><a href="javascript:void(0);">&laquo;</a></li>}
                    {rows}
                    {links.next_page_url ? <li onClick={() => pageUpdate( links.next_page_url )}><a href="javascript:void(0);">&raquo;</a></li> : 
                    <li><a href="javascript:void(0);">&raquo;</a></li>}
                </ul>
            </div>
        </>
    );
};

export default PaginationLink;