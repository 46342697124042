import axios from 'axios';
import React from 'react';
import { toast } from 'react-toastify';
import { PUBLIC_URL } from '../../common/CommonString';

let user_token = localStorage.getItem('user_token') ? localStorage.getItem('user_token') : '';

const AddressItem = (props) => {

    let { last, address } = props;
console.log(last);
    const removeAddress = () => {

        let formData = new FormData();
        formData.append('user_id', 1);
        formData.append('address_id', address.id);

        axios({
            url: `${PUBLIC_URL}/deleteaddress`,
            method: 'POST',
            headers: { Authorization: "Bearer " + user_token },
            data: formData,
        }).then(response => {

            if(response.data.status == 1){

                toast.success("Address is removed!", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                props.callAddress();

            }

        }).catch((error) => {});
    }

    return (
        <div className={last == true ? 'col-sm-12 col-lg-12 extr-margin-bottom' : 'col-sm-12 col-lg-12'}>
            <div className="uthssk">
                <div className="pous">
                    <img src="/images/location.png" alt="" />
                </div>
                <div className="pous1">
                    <h3>{address.first_name} {address.last_name}</h3>
                    <h4>{address.street_address} {address.landmark}</h4>
                    <h4>City: {address.city} </h4>
                    <h4>Mobile: {address.mobile} </h4>
                    <h4>Email: {address.email} </h4>
                </div>
                <div className="pous2">
                    <span style={{ cursor: 'pointer', color: 'red', }} onClick={removeAddress} ><i className="fa fa-trash"></i></span>
                </div>
            </div>
        </div>
    );
};

export default AddressItem;