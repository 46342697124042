import React from 'react';
import { Link } from 'react-router-dom';
import { languageList } from '../../languageHelper';
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
const BannerWithParallax = (props) => {

    let { banner } = props;

    const parallax = {
        background: `${banner.image ? banner.image : 'url(../images/blog-img7.jpg)'} no-repeat top center`,
        padding: '6% 0 8% 0',
        textAlign: 'center',
        marginTop: '0px',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed'
    }

    return (
        <div className="ads-block" style={parallax}>
            <div className="container">
                <div className="row">
                    <div className="banner-text-big"><span>{banner.category ? banner.category.category_name : 'apple'}</span>
                    {/* {lang=='en'?banner.category ? banner.category.category_name : 'apple':banner.category.multi_name? banner.category.multi_name.arabic :banner.category.category_name} */}
                     <br />
                        {lang=='en'?banner.title:banner.multi_name? banner.multi_name.arabic :banner.title}</div>
                    <div className="banner-text-normal">
                        <Link to={`/product-grid?category=${banner.cat_id}&innersubcategory=&subcategory=&category_name=${banner.category ? banner.category.category_name : ''}`}>{languageList['Shop_now'][lang]}</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BannerWithParallax;