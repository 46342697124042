import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { PUBLIC_URL } from '../../common/CommonString';
import Footer from '../../common/layout/Footer';
import Header from '../../common/layout/Header';
import MobileMenu from '../../common/layout/MobileMenu';
import MobileMenuIcons from '../../common/layout/MobileMenuIcons';
import {getFloatVal,convetTimeStamp}  from '../../../commonFunction';


let user_token = localStorage.getItem('user_token');

const Tracking = (props) => {

    const location = useLocation();

    const [firstLoad, setFirstLoad] = useState(true);
    const [order, setOrder] = useState([]);

    const orderId = props.orderNumber;

    useEffect(() => {

        let order = ((new URLSearchParams(location.search).get('order')) != '') ? (new URLSearchParams(location.search).get('order')) : '';

        if (firstLoad == true) {

            let formData = new FormData();
            formData.append('user_id', 1);
            formData.append('order_id', order);

            axios({
                url: `${PUBLIC_URL}/trackorder`,
                method: 'POST',
                headers: { Authorization: "Bearer " + user_token },
                data: formData,

            }).then(response => {

                if (response.data.status == 1) {

                    setOrder(response.data.order_info);
                }

            }).catch((error) => { });
        }

        setFirstLoad(false);
    });

    let item = [];

    return (
        <>
            <div id="page">

                {/* <!-- Header --> */}
                <Header />
                {/* <!-- end header --> */}

                <section className="main-container col1-layout">
                    <div className="container">
                        <div className="breadcrumbs">
                            <ul>
                                <li className="home"> <Link to="/" title="Go to Home Page">Home</Link> <span>/</span> </li>
                                <li className="home"> <Link to={`/order-details?order=${order.order_number}`} title="Go to Home Page">Orders</Link> <span>/</span> </li>
                                <li> <strong>Track Order</strong> </li>
                            </ul>
                        </div>

                        <div className="row">
                            <div className="col-md-3 account-tabs-section extr-margin-bottom">
                                <ul className="nav flex-column account-tabs">
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/profile">Profile</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link active" to="/orders">Orders</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/wishlist">Wishlist</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/address">Address</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-9">
                                <div className="tab-content account-profile">
                                    <fieldset className="group-select">
                                        <legend>Track Orders</legend>
                                    </fieldset>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12" style={{marginBottom: '50px'}}>
                                            <div className="uthssk" >
                                                <div className="pous">
                                                    <img src={order.image_url} alt="product" />
                                                </div>
                                                <div className="pous1">
                                                    <h3>{order.product_name}</h3>
                                                    {order.attribute != null ? <h3>{order.attribute} : {order.variation}</h3> : ''}
                                                    {order.variations && order.variations.map((row, index1) => {
                                                        return<h4 key={index1}>{row.attribute} : {row.variation}</h4>
                                                    })}
                                                    <h4>Qty : {order.qty}</h4>

                                                </div>
                                                <div className="pous2" style={{ width: '30%' }}>
                                                    <p>AED {getFloatVal(order.price)}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12" style={{marginBottom: '100px'}}>
                                            <div className="row d-flex justify-content-between">
                                                <div class={order.created_at != null ? 'order-tracking completed' : 'order-tracking'}>
                                                    <span className="is-complete"></span>
                                                    <p>Ordered<br /><span>{convetTimeStamp(order.created_at)}</span></p>
                                                </div>
                                                {
                                                [5,6].includes(order.status)?
                                               order.status==5?
                                              
                                                    <div className="order-tracking completed" style={{width:'50.33%'}}>
                                                    <span className="is-complete"></span>
                                                    <p>Rejected<br /><span>{(order.status==5)?convetTimeStamp(order.rejected_at):""}</span></p>
                                                   </div> :
                                                    
                                                    <div className="order-tracking completed" style={{width:'50.33%'}}>
                                                    <span className="is-complete"></span>
                                                    <p>Cancelled<br /><span>{(order.status==6)?convetTimeStamp(order.cancelled_at):""}</span></p>
                                                   </div> 

                                                   :
                                               <>
                                                   <div className={(order.confirmed_at||[2,3,4,7,8,9,10].includes(order.status)) ? 'order-tracking completed' : 'order-tracking'}>
                                                   <span className="is-complete"></span>
                                                   <p>Confirmed<br /><span>{(order.status>=2)?convetTimeStamp(order.confirmed_at):""}</span></p>
                                                 </div>
                                            
                                             
                                               <div className={order.shipped_at||[3,4,7,8,9,10].includes(order.status) ? 'order-tracking completed' : 'order-tracking'}>
                                                   <span className="is-complete"></span>
                                                   <p>Shipped<br /><span>{convetTimeStamp(order.shipped_at)}</span></p>
                                               </div>
                                              
                                              
                                               <div className={(order.delivered_at != null)&&(order.status>=4) ? 'order-tracking completed' : 'order-tracking'}>
                                                   <span className="is-complete"></span>
                                                   <p>Delivered<br /><span>{convetTimeStamp(order.delivered_at)}</span></p>
                                               </div>
                                               </>
                                                }

                                               
                                            
                                               
                                            
                                              
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Footer --> */}
                <Footer />
                {/* <!-- End Footer -->  */}

            </div>

            {/* <!-- mobile menu --> */}
            <MobileMenu />

            <MobileMenuIcons />

            {/* <!-- JavaScript -->  */}

        </>
    );
};

export default Tracking;