import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../common/layout/Footer';
import Header from '../common/layout/Header';
import MobileMenu from '../common/layout/MobileMenu';
import MobileMenuIcons from '../common/layout/MobileMenuIcons';

const Index = () => {
    return (
        <>
            <div id="page">
                {/* <!-- Header --> */}
                <Header />
                {/* <!-- end header -->  */}

                {/* <!-- Main Container --> */}
                <section className="main-container col1-layout">
                    <div className="container">
                        {/* <!-- Breadcrumbs --> */}
                        <div className="breadcrumbs">
                            <ul>
                                <li className="home"> <Link to="/" title="Go to Home Page">Home</Link> <span>/</span> </li>
                                <li> <strong>Contact</strong> </li>
                            </ul>
                        </div>
                        {/* <!-- Breadcrumbs End --> */}
                        <div className="row">
                            <div className="col-sm-12 col-xs-12">
                                <article className="col-main">
                                    <div className="cart">
                                        <div className="text-center">
                                            <h1><b>Contact</b></h1>
                                        </div>
                                        <div className="row">
                                            <div className="text-center">
                                                <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ita credo. Paria sunt igitur. Qui ita affectus, beatum esse</h4>
                                            </div>
                                            <br /><br />
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-6">
                                                        <p>At Apptopia, we want to solve the biggest problem in mobile: everyone is guessing.</p>
                                                        <p>
                                                            Publishers need to know what apps to build, how to monetize them, and where to price them. 
                                                            Advertisers and brands need to identify their target users, 
                                                            and determine where to allocate resources in order to reach them most effectively. 
                                                            Investors need to know which apps and genres are growing the quickest, and where users are really spending their time (and money).
                                                        </p>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6">
                                                        <div className="contacts-info">
                                                            <address>
                                                                <i className="add-icon"></i>ThemesGround, 789 Main rd,<br />
                                                                Anytown, CA 12345 USA<br />
                                                            </address>
                                                            <div className="phone-footer"><i className="phone-icon"></i>+ 888 456-7890</div>
                                                            <div className="email-footer"><i className="email-icon"></i><a href="mailto:abc@example.com">citroen@abc.com</a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-xs-12">
                                                <div class="embed-responsive embed-responsive-16by9">
                                                    <iframe class="embed-responsive-item" src="https://www.google.com/maps/d/embed?mid=1QlqZ-rT0_7n6LT3mbDDRxsyxass&ehbc=2E312F" height="400" allowfullscreen></iframe>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Main Container End --> */}

                {/* <!-- Footer --> */}
                <Footer />
                {/* <!-- End Footer -->  */}
            </div>
            {/* <!-- mobile menu --> */}
            <MobileMenu />

            <MobileMenuIcons />

            {/* <!-- JavaScript -->  */}
        </>
    );
};

export default Index;