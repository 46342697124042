import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PUBLIC_URL } from '../CommonString';

let ciphertext = localStorage.getItem('user_token') ? localStorage.getItem('user_token') : '';

class MobileMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            category: [],
            search: '',
            loading: false,
            logedUser: false,
        }
    }

    componentDidMount = () => {

        axios({
            url: `${PUBLIC_URL}/categoryWithSubcategory`,
            method: 'POST',

        }).then(response => {

            if (response.data.status == 1) {

                this.setState({
                    category: response.data.category,
                    loading: false,
                });
            }

        }).catch((error) => {

            this.setState({
                loading: false,
            });
            
        });

        if (ciphertext !== '') {

            this.setState({
                logedUser: true,
            });
        }
        else {

            this.setState({
                logedUser: false,
            });
        }

    }

    logout = () => {

        this.setState({
            loading: true,
        });

        // console.log(ciphertext, '++++++++');
        // let bytes = CryptoJS.AES.decrypt(ciphertext, ENCRYPTKEY);
        // console.log(bytes, '==========');
        // let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        // console.log(bytes, decryptedData, '------------');

        axios({
            url: `${PUBLIC_URL}/logout`,
            method: 'POST',
            headers: { Authorization: "Bearer " + ciphertext },
        }).then(response => {

            if (response.data.status == 1) {

                localStorage.removeItem('user_token');
                this.setState({
                    logedUser: false,
                });
                window.location.reload();
            }

            this.setState({
                loading: false,
            });

        }).catch((error) => {
            this.setState({
                loading: false,
            });
        });
    }
    searchChange = (e) => {

        this.setState({
            search: e.target.value,
        });
    }
    searchClick = () => {
      
        if (this.state.search != '') {
            window.location='/product-grid?search=' + this.state.search;
        }
    }
    render() {

        let {category, search, loading, logedUser} = this.state;

        return (
            <div id="mobile-menu">
                <ul>
                    <li>
                        <div className="mm-search">
                            <form id="search1" name="search">
                                <div className="input-group">
                                    <div className="input-group-btn">
                                        <button className="btn btn-default" type="button" onClick={this.searchClick}><i className="fa fa-search"></i> </button>
                                    </div>
                                    <input type="text" className="form-control simple"  placeholder="Search ..." name="srch-term" id="srch-term"  onChange={this.searchChange} value={search}/>
                                </div>
                            </form>
                        </div>
                    </li>
                    <li><Link to="/">Home</Link></li>

                    {category && category.map((item, index) => {
                        
                        if(item.subcategory.length != 0){
                            return(
                                <li key={index}>
                                    {/* <a href={`/product-grid?category=${item.id}&innersubcategory=&subcategory=&category_name=${item.category_name}`}>{item.category_name}</a> */}
                                    
                                    <a href="#">{item.category_name}</a>

                                    <ul>
                                        {item.subcategory && item.subcategory.map((item1, index1) => {
                                            if(item1.inner_category.length != 0){
                                                return(
                                                    <li key={index1}>
                                                        <Link to={`/product-grid?subcategory=${item1.id}&innersubcategory=&category=&category_name=${item1.subcategory_name}`}>{item1.subcategory_name}</Link>
                                                        <ul>
                                                            {item1.inner_category && item1.inner_category.map((item2, index2) => {
                                                                return(
                                                                    <li key={index2}>
                                                                        <Link to={`/product-grid?innersubcategory=${item2.id}&subcategory=&category=&category_name=${item2.innersubcategory_name}`}><span>{item2.innersubcategory_name}</span></Link>
                                                                    </li>
                                                                )
                                                            })}
                                                            
                                                        </ul>
                                                    </li>
                                                )
                                            }
                                            else{
                                                return(
                                                    <li>
                                                        <Link to={`/product-grid?subcategory=${item1.id}&innersubcategory=&category=&category_name=${item1.subcategory_name}`}>{item1.subcategory_name}</Link>
                                                    </li>
                                                )
                                            }
                                        })}
                                        
                                    </ul>
                                </li>
                            )
                        }
                        else{
                            return (
                                <li key={index}>
                                    <Link to={`/product-grid?category=${item.id}&innersubcategory=&subcategory=&category_name=${item.category_name}`}>{item.category_name}</Link>
                                </li>
                            );
                        }
                    })}
                    
                    {/* <li> <a href="#">Computers</a>
                        <ul>
                            <li> <a href="#" className="">Laptop</a>
                                <ul>
                                    <li> <a href="grid.html"><span>Microsoft</span></a> </li>
                                    <li> <a href="grid.html"><span>Lenova</span></a> </li>
                                    <li> <a href="grid.html"><span>Apple</span></a> </li>
                                    <li> <a href="grid.html"><span>Touchscreen</span></a> </li>
                                </ul>
                            </li>
                            <li> <a href="grid.html">Printers</a>
                                <ul>
                                    <li> <a href="grid.html">Scanners</a> </li>
                                    <li> <a href="grid.html">3D Printers</a> </li>
                                    <li> <a href="grid.html">Fax Machines</a> </li>
                                    <li> <a href="grid.html">Connectors</a> </li>
                                </ul>
                            </li>
                            <li> <a href="grid.html">Components</a>
                                <ul>
                                    <li> <a href="grid.html">Hard Drives</a> </li>
                                    <li> <a href="grid.html">Motherboards</a> </li>
                                    <li> <a href="grid.html">Graphic Cards </a> </li>
                                    <li> <a href="grid.html">Processors</a> </li>
                                </ul>
                            </li>
                            <li> <a href="grid.html">Accessories</a>
                                <ul>
                                    <li> <a href="grid.html">Keyboards </a> </li>
                                    <li> <a href="grid.html">WebCams</a> </li>
                                    <li> <a href="grid.html">Batteries</a> </li>
                                    <li> <a href="grid.html">Speakers</a> </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li> <a href="grid.html">Appliances</a>
                        <ul>
                            <li> <a href="grid.html" className="">Kitchen</a>
                                <ul className="level1">
                                    <li> <a href="grid.html">Refrigerators</a> </li>
                                    <li> <a href="grid.html">Dishwashers</a> </li>
                                    <li> <a href="grid.html">Microwaves</a> </li>
                                    <li> <a href="grid.html">Tosters</a> </li>
                                </ul>
                            </li>
                            <li> <a href="grid.html">Cameras</a>
                                <ul className="level1">
                                    <li> <a href="grid.html"><span>Accessories</span></a> </li>
                                    <li> <a href="grid.html"><span>Binoculars</span></a> </li>
                                    <li> <a href="grid.html"><span>Telescopes</span></a> </li>
                                    <li> <a href="grid.html"><span>Camcorders</span></a> </li>
                                </ul>
                            </li>
                            <li> <a href="grid.html">Desktops</a>
                                <ul className="level1">
                                    <li> <a href="grid.html"><span>Routers &amp; Modems</span></a> </li>
                                    <li> <a href="grid.html"><span>CPUs, Processors</span></a> </li>
                                    <li> <a href="grid.html"><span>PC Gaming Store</span></a> </li>
                                    <li> <a href="grid.html"><span>Components</span></a> </li>
                                </ul>
                            </li>
                            <li> <a href="#.html">Mobile Phones</a>
                                <ul className="level1">
                                    <li> <a href="grid.html"><span>Apple</span></a> </li>
                                    <li> <a href="grid.html"><span>Samsung</span></a> </li>
                                    <li> <a href="grid.html"><span>Motorola</span></a> </li>
                                    <li> <a href="grid.html"><span>Lenovo</span></a> </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li> <a href="grid.html"> Televisions </a></li>
                    <li> <a href="grid.html"> Cameras</a></li>
                    <li><a href="grid.html"> Mobiles, Tablets</a> </li>
                    <li><a href="grid.html"> Refrigerators</a> </li>
                    <li><a href="grid.html"> Watches</a> </li> */}
                </ul>
                <div className="top-links">
                    <ul className="links">
                        {logedUser == false ? '' :
                            <>
                                <li><Link title="My Account" to="/profile">My Account</Link> </li>
                                <li><Link title="Wishlist" to="/wishlist">Wishlist</Link> </li>
                                <li><Link title="Checkout" to="/orders">Order</Link> </li>
                                {/* <li><a title="Blog" href="blog.html"><span>Blog</span></a> </li> */}
                                <li className="last"><a href="#" onClick={this.logout} title="Logout"><span>Logout</span></a></li>
                            </>}
                    </ul>
                </div>
            </div>
        );
    }
}

export default MobileMenu;