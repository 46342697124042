import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { PUBLIC_URL } from '../../common/CommonString';

let user_token = localStorage.getItem('user_token') ? localStorage.getItem('user_token') : '';

const AddNewAddress = (props) => {

    const [billing_firstname, setbilling_firstname] = useState('');
    const [billing_lastname, setbilling_lastname] = useState('');
    const [billing_street, setbilling_street] = useState('');
    const [billing_postcode, setbilling_postcode] = useState('');
    const [billing_city, setbilling_city] = useState('');
    const [billing_telephone, setbilling_telephone] = useState('');
    const [billing_email, setbilling_email] = useState('');
    const [billing_landmark, setbilling_landmark] = useState('');
    const [error, setError] = useState('');
    const [fNameError, setFNameError] = useState('');
    const [lNameError, setLNameError] = useState('');
    const [streeError, setStreetError] = useState('');
    const [postalError, setPostalError] = useState('');
    const [telphoneError, setTelphoneError] = useState('');
    const [emailError, setEmailError] = useState('');

    const billingChange = (e) => {


        if (e.target.name === 'billing_firstname') {

            setbilling_firstname(e.target.value);
        }
        else if (e.target.name === 'billing_lastname') {
            setbilling_lastname(e.target.value);
        }
        else if (e.target.name === 'billing_street') {
            setbilling_street(e.target.value);
        }
        else if (e.target.name === 'billing_city') {
            setbilling_city(e.target.value);
        }
        else if (e.target.name === 'billing_telephone') {

            if(e.target.value >= 0){
                setbilling_telephone(e.target.value);
            }
        }
        else if (e.target.name === 'billing_email') {
            setbilling_email(e.target.value);
        }
        else if (e.target.name === 'billing_landmark') {
            setbilling_landmark(e.target.value);
        }

    }

    const billingSubmit = () => {

        if(billing_firstname !== '' && billing_lastname !== '' && billing_street !== ''  && billing_email !== '' && billing_telephone !== ''){

            let formData = new FormData();
            formData.append('user_id', 1);
            formData.append('first_name', billing_firstname);
            formData.append('last_name', billing_lastname);
            formData.append('street_address', billing_street);
            formData.append('city', billing_city);
            formData.append('mobile', billing_telephone);
            formData.append('email', billing_email);
            formData.append('landmark', billing_landmark);

            axios({
                url: `${PUBLIC_URL}/saveaddress`,
                method: 'POST',
                headers: { Authorization: "Bearer " + user_token },
                data: formData,

            }).then(response => {

                if (response.data.status == 1) {

                    toast.success("Address is added!", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    setbilling_firstname('');
                    setbilling_lastname('');
                    setbilling_street('');
                    setbilling_city('');
                    setbilling_telephone('');
                    setbilling_email('');
                    setbilling_landmark('');

                    setFNameError('');
                    setLNameError('');
                    setStreetError('');
                    setPostalError('');
                    setTelphoneError('');
                    setEmailError('');

                    props.callAddress();
                       
                }

            }).catch((error) => {

                if(error.response.data.status == 0){
                    setError(error.response.data.message);
                }

            });

        }
        else{

            if(billing_firstname === ''){
                let error = 'First name is required';
                setFNameError(error);
            }

            if(billing_lastname === ''){

                let error = 'Last name is required';
                setLNameError(error);
            }

            if(billing_street === ''){

                let error = 'street address is required';
                setStreetError(error);
            }

            // if(billing_postcode === ''){

            //     let error = 'Postal code is required';
            //     setPostalError(error);
            // }

            if(billing_email === ''){

                let error = 'Email is required';
                setEmailError(error);
            }

            if(billing_telephone === ''){

                let error = 'Telphone is required';
                setTelphoneError(error);
            }
        }
    }

    return (
        <div className="modal fade" id="myModalAddAddress" role="dialog">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header v5c">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div className="modal-body">
                        <fieldset className="group-select">
                            <legend>Add new address</legend>
                            <ul>
                                <li>
                                    <div className="customer-name">
                                        <div className="input-box name-firstname">
                                            <label for="billing:firstname"> First Name <span className="required">*</span> </label>
                                            <br />
                                            <input type="text" id="billing:firstname" onChange={billingChange} name="billing_firstname" value={billing_firstname} title="First Name" className="input-text required-entry" />
                                            {fNameError !== '' ? <div className="text-danger">{fNameError}</div>: ''}
                                        </div>
                                        <div className="input-box name-lastname">
                                            <label for="billing:lastname"> Last Name <span className="required">*</span> </label>
                                            <br />
                                            <input type="text" id="billing:lastname" onChange={billingChange} name="billing_lastname" value={billing_lastname} title="Last Name" className="input-text required-entry" />
                                            {lNameError !== '' ? <div className="text-danger">{lNameError}</div>: ''}
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <label for="billing:street1">Street Address <span className="required">*</span></label>
                                    <br />
                                    <input type="text" title="Street Address" onChange={billingChange} name="billing_street" id="billing:street1  street1" value={billing_street} className="input-text required-entry" />
                                    {streeError !== '' ? <div className="text-danger">{streeError}</div>: ''}
                                </li>


                                <li>
                                    <div className="input-box">
                                        <label for="billing:postcode">City</label>
                                        <br />
                                        <input type="text" title="City" onChange={billingChange} name="billing_city" id="billing:city" value={billing_city} className="input-text required-entry" />
                                        {/* {postalError !== '' ? <div className="text-danger">{postalError}</div>: ''} */}
                                    </div>

                                </li>
                                <li>
                                    <div className="input-box">
                                        <label for="billing:telephone">Telephone <span className="required">*</span></label>
                                        <br />
                                        <input type="text" name="billing_telephone" onChange={billingChange} value={billing_telephone} title="Telephone" className="input-text required-entry" id="billing:telephone" />
                                        {telphoneError !== '' ? <div className="text-danger">{telphoneError}</div>: ''}
                                    </div>
                                    <div className="input-box">
                                        <label for="Email">Email <span className="required">*</span></label>
                                        <br />
                                        <input type="text" name="billing_email" onChange={billingChange} value={billing_email} title="Email" className="input-text" id="Email" />
                                        {emailError !== '' ? <div className="text-danger">{emailError}</div>: ''}
                                    </div>
                                </li>
                                <li>
                                    <label for="billing:street1">Landmark</label>
                                    <br />
                                    <input type="text" title="Street Address" onChange={billingChange} name="billing_landmark" id="billing:street1  street1" value={billing_landmark} className="input-text required-entry" />
                                </li>
                            </ul>
                            {error !== '' ? <p className="text-danger">{error}</p> : ''}
                            <p className="require"><em className="required">* </em>Required Fields</p>
                            <button type="button" className="button continue" onClick={billingSubmit}><span>Save</span></button>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddNewAddress;