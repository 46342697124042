import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PUBLIC_URL } from '../common/CommonString';
import Footer from '../common/layout/Footer';
import Header from '../common/layout/Header';
import MobileMenu from '../common/layout/MobileMenu';
import MobileMenuIcons from '../common/layout/MobileMenuIcons';
import {getFloatVal} from '../../commonFunction';
import { languageList } from '../../languageHelper';
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';

let user_token = localStorage.getItem('user_token');

class Index extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            cart: [],
            coupon: '',
            coupon_error:''
        }
    }

    componentDidMount = () => {
 if ((user_token === '')||(!user_token))
        {
                 let cart_data = JSON.parse(localStorage.getItem('mycart'));
                if(!cart_data)
                {
                    cart_data=[];
                }
              //  console.log(cart_data);
            this.setState({
                    cart: cart_data,
                });
        }else{
           
           // console.log(user_token);
        axios({
            url: `${PUBLIC_URL}/getcart`,
            method: 'POST',
            headers: { Authorization: "Bearer " + user_token },
            data: {
                user_id: 1,
            }

        })
                .then(response => {
                   

            if(response.data.status == 1) {
                // console.log(response.data);
                this.setState({
                    cart: response.data.data,
                });
            }

        }).catch((error) => {});
      }
    }

    removeItem = (id) => {
       

             let mycart = JSON.parse(localStorage.getItem('mycart'));
    if(!mycart)
    {
       mycart=[];
    }
        if ((user_token === '')||(!user_token))
        {
            mycart.forEach((item,index)=>{
                if(item.product_id===id)
                {
                    mycart.splice(index,1);
                }
            });
            localStorage.setItem('mycart',JSON.stringify(mycart));
               this.setState({
                            cart: mycart,
                        });
        toast.success("Product is removed from cart!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }else{
        let formData = new FormData();
        formData.append('user_id', 1);
        formData.append('cart_id', id);

        axios({
            url: `${PUBLIC_URL}/deleteproduct`,
            method: 'POST',
            headers: { Authorization: "Bearer " + user_token },
            data: formData,
        }).then(response => {

            toast.success("Product is removed from cart!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            if(response.data.status == 1){

                axios({
                    url: `${PUBLIC_URL}/getcart`,
                    method: 'POST',
                    headers: { Authorization: "Bearer " + user_token },
                    data: {
                        user_id: 1,
                    }
        
                }).then(response => {
        
                    if(response.data.status == 1) {
        
                        this.setState({
                            cart: response.data.data,
                        });
                    }
        
                }).catch((error) => {});

            }

        }).catch((error) => {});
    }
    }

    quantityChange = (e, id) => {
        if(e.target.value<1)
        {
          return; 
        }
          let mycart = JSON.parse(localStorage.getItem('mycart'));
    if(!mycart)
    {
       mycart=[];
    }
        if ((user_token === '')||(!user_token))
        {
            mycart.forEach((item,index)=>{
                if(item.product_id===id)
                {
                    mycart[index].qty=e.target.value;
                }
            });
            localStorage.setItem('mycart',JSON.stringify(mycart));
                this.setState({
                            cart: mycart,
                        });
        }else        
        {
        let formData = new FormData();
        formData.append('cart_id', id);
        formData.append('qty', e.target.value);

        axios({
            url: `${PUBLIC_URL}/qtyupdate`,
            method: 'POST',
            headers: { Authorization: "Bearer " + user_token },
            data: formData,
        }).then(response => {

            toast.success("Quantity is updated!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            if(response.data.status == 1){

                axios({
                    url: `${PUBLIC_URL}/getcart`,
                    method: 'POST',
                    headers: { Authorization: "Bearer " + user_token },
                    data: {
                        user_id: 1,
                    }
        
                }).then(response => {
        
                    if(response.data.status == 1) {
        
                        this.setState({
                            cart: response.data.data,
                        });
                    }
        
                }).catch((error) => {});

            }

        }).catch((error) => {});
    }
    }

    handleChange = (e) => {

        this.setState({
            coupon: e.target.value,
        });
    }

    couponSubmit = () => {

        let formData = new FormData();
        formData.append('user_id', 1);
        formData.append('coupon_name', this.state.coupon);

        axios({
            url: `${PUBLIC_URL}/checkout`,
            method: 'POST',
            headers: { Authorization: "Bearer " + user_token },
            data: formData,
        }).then(response => {

            if(response.data.status == 1){
                this.setState({
                    cart: response.data.cartdata,
                    coupon:response.data.coupon_name
                });
            }else{
              this.setState({
                coupon_error:response.data.message 
              });
            }

        }).catch((error) => {});
    }
    removeCoupon=(id)=>{
        let formData = new FormData();
        formData.append('cart_id', id);

        axios({
            url: `${PUBLIC_URL}/remove_coupon`,
            method: 'POST',
            headers: { Authorization: "Bearer " + user_token },
            data: formData,
        }).then(response => {
              
            if(response.data.status == 1){
                this.setState({
                    cart: response.data.cartdata
                });
            }

        }).catch((error) => {});
    }
    render() {

        let {cart, coupon} = this.state;

        let total = 0;
        let delivery = 0;
        let descount = 0;
        let tax = 0;

        const cartQty = [
            {
                value: 1,
            },
            {
                value: 2,
            },
            {
                value: 3,
            },
            {
                value: 4,
            },
            {
                value: 5,
            },
        ];

        return (
            <>
                <div id="page">
                    {/* <!-- Header --> */}
                    <Header />
                    {/* <!-- end header -->  */}

                    {/* <!-- Main Container --> */}
                    <section className="main-container col1-layout">
                        <div className="container">
                            {/* <!-- Breadcrumbs --> */}
                            <div className="breadcrumbs">
                                <ul>
                                    <li className="home"> <Link to="/" title="Go to Home Page">{languageList['home'][lang]}</Link> <span>/</span> </li>
                                    <li> <strong>{languageList['Shopping_Cart'][lang]}</strong> </li>
                                </ul>
                            </div>
                            {/* <!-- Breadcrumbs End --> */}
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <article className="col-main">
                                        <div className="cart">
                                            <div className="page-title">
                                                <h2>{languageList['Shopping_Cart'][lang]}</h2>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-8 col-lg-8">
                                                    {cart.length == 0 ? 'No Data found':''}
                                                    {cart && cart.map((item, index) => {

                                                        total += parseInt(item.qty * item.price);
                                                        if(item.tax != null){
                                                            // tax += getFloatVal(item.tax);
                                                            tax += parseFloat(item.qty * item.tax);

                                                        }

                                                        descount += parseInt(item.discount_amount);
                                                        // descount=0;
                                                        if(item.shipping_cost>delivery){

                                                            delivery = parseInt(item.shipping_cost);
                                                        }

                                                        return(
                                                            <div className="uthssk" key={index}>
                                                                <div className="pous">
                                                                    <img src={item.image_url} alt={item.product_name}  />
                                                                </div>
                                                                <div className="pous1">
                                                                <Link to={`/product-details?product=${item.product_id}`} title={item.product_name}> <h3>{lang== 'en'?item.product_name: item.arabic ? item.arabic:item.product_name }</h3></Link>
                                                                    {item.variations && item.variations.map((row, index1) => {

                                                                        return <h4 key={index1}>{row.attribute} : {row.variation}</h4>
                                                                    })}
                                                                    {item.coupon_name?(<><small style={{background:'green',color:'white',padding:'0px 5px',borderRadius:'50px'}}>{item.coupon_name+' Applied'}</small><button type="button" style={{background:'red',color:'white',padding:'0px 5px',borderRadius:'50px'}} onClick={()=>this.removeCoupon(item.id)}>x</button></>):(<></>)}
                                                                    <p>{languageList['Tax'][lang]} : {getFloatVal(parseFloat(item.qty * item.tax))} AED</p>
                                                                    {/* <p>{languageList['Delivery_Charges'][lang]} : {getFloatVal(parseInt(item.shipping_cost))} AED</p> */}
                                                                  
                                                                    <br /><br />
                                                                    <span onClick={() => this.removeItem(item.id?item.id:item.product_id)} style={{cursor: 'pointer'}} className="btn btn-danger"><i className="fa fa-trash"></i></span>
                                                                        <button className="btn btn-success" style={{marginLeft:'5px'}}  onClick={(e) => this.quantityChange({target:{value:parseInt(item.qty)+1}}, item.id?item.id:item.product_id)}><i className="fa fa-plus"></i></button>
                                                                    <button className="btn btn-info ml-2" style={{marginLeft:'5px'}}   onClick={(e) => this.quantityChange({target:{value:parseInt(item.qty)-1}}, item.id?item.id:item.product_id)}><i className="fa fa-minus"></i></button>
                                                                </div>
                                                                <div className="pous2">
                                                                    <p>AED {getFloatVal(item.qty * item.price)}</p>
                                                                    <input className="form-control" type="number" value={item.qty} onChange={(e) => this.quantityChange(e, item.id?item.id:item.product_id)}/>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                    
                                                    <div className="gcvYcJ">
                                                        <Link to="/"><button type="button">{languageList['continue_shopping'][lang]}</button></Link>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 col-lg-4">
                                                    <div className="hcbyIU">
                                                        <h1>{languageList['Order_Summary'][lang]}</h1>
                                                        {
                                                            user_token?(<div className="coupon thm-coupon-cart">
                                                            <input type="text" name="coupon_code" value={coupon}  onChange={this.handleChange} className="form-control unicase-form-control input-text" id="coupon_code" placeholder="Coupon code" />
                                                            <button type="button" className="btn btn-upper btn-primary" onClick={this.couponSubmit} name="apply_coupon" value="Apply coupon">{languageList['apply'][lang]}</button>
                                                            <p className='text-danger'>{this.state.coupon_error}</p>
                                                             </div>):<></>
                                                        }
                                                        
                                                        <div className="po1s">
                                                            <div className="d-flex justify-content-between">
                                                                <div className="cilop">
                                                                    <h5>{languageList['sub_total'][lang]}</h5>
                                                                    <h5>{languageList['Tax'][lang]}</h5>
                                                                    <h5>{languageList['discount'][lang]}</h5>
                                                                    <h5>{languageList['Delivery_Charges'][lang]}</h5>
                                                                </div>
                                                                <div className="cilop1">
                                                                    <h5>AED {getFloatVal(total)}</h5>
                                                                    <h5>AED +{getFloatVal(tax)}</h5>
                                                                    <h5>AED -{getFloatVal(descount)}</h5>
                                                                    <h5>AED +{getFloatVal(delivery)}</h5>
                                                                </div>
                                                            </div><hr />
                                                            <div className="d-flex justify-content-between">
                                                                <div className="cilop">
                                                                    <h5>  {languageList['Total'][lang]} </h5>
                                                                </div>
                                                                <div className="cilop1">
                                                                    <h5>AED {getFloatVal(total + delivery - descount + parseFloat(tax))}</h5>
                                                                </div>
                                                            </div>
                                                            <div className="ctllRv">
                                                                {
                                ((user_token)&&(cart.length>0))?
                            (<Link to="/checkout" style={{color: '#000'}}><button type="button">{languageList['CHECKOUT'][lang]}</button></Link>)
                    :((!user_token)&&(cart.length>0))?
                         (<a href="javascript:void(0);" style={{color: '#000'}}><button type="button"
                      onClick={()=>{
                          localStorage.setItem('cus_redirect','checkout');
                           window.jQuery('#myModalsignin').modal('show');
                          }}>
                                  {languageList['CHECKOUT'][lang]}</button></a>):(<a href="javascript:void(0);" style={{color: '#000'}}><button type="button">{languageList['CHECKOUT'][lang]}</button></a>)
                        }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="bestsell-pro mt-0 mb-60">
                                                <div>
                                                    <div className="slider-items-products">
                                                        <div className="bestsell-block">
                                                            <div className="block-title">
                                                                <h2>You May also intrested</h2>
                                                            </div>
                                                            <div id="bestsell-slider" className="product-flexslider hidden-buttons">
                                                                <div className="slider-items slider-width-col4 products-grid block-content">
                                                                    <div className="item">
                                                                        <div className="item-inner">
                                                                            <div className="item-img">
                                                                                <div className="item-img-info"> <a className="product-image" title="Retis lapen casen" href="product_detail.html"> <img alt="" src="products-images\product12.jpg" /> </a>
                                                                                    <div className="new-label new-top-left">new</div>
                                                                                    <div className="box-hover">
                                                                                        <ul className="add-to-links">
                                                                                            <li><a className="link-quickview" href="quick_view.html"></a> </li>
                                                                                            <li><a className="link-wishlist" href="wishlist.html"></a> </li>
                                                                                            <li><a className="link-compare" href="compare.html"></a> </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="item-info">
                                                                                <div className="info-inner">
                                                                                    <div className="item-title"> <a title="Retis lapen casen" href="product_detail.html">Anti Glare Side Narrow Border Display Laptop</a> </div>
                                                                                    <div className="brand">Tonrex</div>
                                                                                    <div className="star-rating">
                                                                                        <span style={{width: '60%'}}>Rated <strong className="rating">3.00</strong> out of 5</span>
                                                                                    </div>
                                                                                    <div className="item-content">
                                                                                        <div className="item-price">
                                                                                            <div className="price-box"> <span className="regular-price"> <span className="price">$88.00</span> </span> </div>
                                                                                        </div>
                                                                                        <div className="action">
                                                                                            <button className="button btn-cart" type="button" title="" data-original-title="Add to Cart"><i className="fa fa-shopping-basket"></i></button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="item">
                                                                        <div className="item-inner">
                                                                            <div className="item-img">
                                                                                <div className="item-img-info"> <a className="product-image" title="Retis lapen casen" href="product_detail.html"> <img alt="" src="products-images\product11.jpg" /> </a>
                                                                                    <div className="box-hover">
                                                                                        <ul className="add-to-links">
                                                                                            <li><a className="link-quickview" href="quick_view.html"></a> </li>
                                                                                            <li><a className="link-wishlist" href="wishlist.html"></a> </li>
                                                                                            <li><a className="link-compare" href="compare.html"></a> </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="item-info">
                                                                                <div className="info-inner">
                                                                                    <div className="item-title"> <a title="Retis lapen casen" href="product_detail.html"> Stovekraft Induction Stove with Feather touch </a> </div>
                                                                                    <div className="brand">Unicorn</div>
                                                                                    <div className="item-content">
                                                                                        <div className="star-rating">
                                                                                            <span style={{ width: '60%' }}>Rated <strong className="rating">3.00</strong> out of 5</span>
                                                                                        </div>
                                                                                        <div className="item-price">
                                                                                            <div className="price-box"> <span className="regular-price"> <span className="price">$325.00</span> </span> </div>
                                                                                        </div>
                                                                                        <div className="action">
                                                                                            <button className="button btn-cart" type="button" title="" data-original-title="Add to Cart"><i className="fa fa-shopping-basket"></i></button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="item">
                                                                        <div className="item-inner">
                                                                            <div className="item-img">
                                                                                <div className="item-img-info"> <a className="product-image" title="Retis lapen casen" href="product_detail.html"> <img alt="" src="products-images\product10.jpg" /> </a>
                                                                                    <div className="box-hover">
                                                                                        <ul className="add-to-links">
                                                                                            <li><a className="link-quickview" href="quick_view.html"></a> </li>
                                                                                            <li><a className="link-wishlist" href="wishlist.html"></a> </li>
                                                                                            <li><a className="link-compare" href="compare.html"></a> </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="item-info">
                                                                                <div className="info-inner">
                                                                                    <div className="item-title"> <a title="Retis lapen casen" href="product_detail.html"> Home Security Camera with Alarm System </a> </div>
                                                                                    <div className="brand">Harrier</div>
                                                                                    <div className="item-content">
                                                                                        <div className="star-rating">
                                                                                            <span style={{ width: '60%' }}>Rated <strong className="rating">3.00</strong> out of 5</span>
                                                                                        </div>
                                                                                        <div className="item-price">
                                                                                            <div className="price-box"> <span className="regular-price"> <span className="price">$245.00</span> </span> </div>
                                                                                        </div>
                                                                                        <div className="action">
                                                                                            <button className="button btn-cart" type="button" title="" data-original-title="Add to Cart"><i className="fa fa-shopping-basket"></i></button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="item">
                                                                        <div className="item-inner">
                                                                            <div className="item-img">
                                                                                <div className="item-img-info"> <a className="product-image" title="Retis lapen casen" href="product_detail.html"> <img alt="" src="products-images\product6.jpg" /> </a>
                                                                                    <div className="new-label new-top-left">new</div>
                                                                                    <div className="box-hover">
                                                                                        <ul className="add-to-links">
                                                                                            <li><a className="link-quickview" href="quick_view.html"></a> </li>
                                                                                            <li><a className="link-wishlist" href="wishlist.html"></a> </li>
                                                                                            <li><a className="link-compare" href="compare.html"></a> </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="item-info">
                                                                                <div className="info-inner">
                                                                                    <div className="item-title"> <a title="Retis lapen casen" href="product_detail.html"> Fitness Smartwatch with Heart Rate Monitor </a> </div>
                                                                                    <div className="brand">Cruiser</div>
                                                                                    <div className="star-rating">
                                                                                        <span style={{ width: '60%' }}>Rated <strong className="rating">3.00</strong> out of 5</span>
                                                                                    </div>
                                                                                    <div className="item-content">
                                                                                        <div className="item-price">
                                                                                            <div className="price-box"> <span className="regular-price"> <span className="price">$88.00</span> </span> </div>
                                                                                        </div>
                                                                                        <div className="action">
                                                                                            <button className="button btn-cart" type="button" title="" data-original-title="Add to Cart"><i className="fa fa-shopping-basket"></i></button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="item">
                                                                        <div className="item-inner">
                                                                            <div className="item-img">
                                                                                <div className="item-img-info"> <a className="product-image" title="Retis lapen casen" href="product_detail.html"> <img alt="" src="products-images\product8.jpg" /> </a>
                                                                                    <div className="box-hover">
                                                                                        <ul className="add-to-links">
                                                                                            <li><a className="link-quickview" href="quick_view.html"></a> </li>
                                                                                            <li><a className="link-wishlist" href="wishlist.html"></a> </li>
                                                                                            <li><a className="link-compare" href="compare.html"></a> </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="item-info">
                                                                                <div className="info-inner">
                                                                                    <div className="item-title"> <a title="Retis lapen casen" href="product_detail.html">Mixer Grinder with 3 Stainless Steel Jar</a> </div>
                                                                                    <div className="brand">Flipmart</div>
                                                                                    <div className="star-rating">
                                                                                        <span style={{ width: '60%' }}>Rated <strong className="rating">3.00</strong> out of 5</span>
                                                                                    </div>
                                                                                    <div className="item-content">
                                                                                        <div className="item-price">
                                                                                            <div className="price-box"> <span className="regular-price"> <span className="price">$88.00</span> </span> </div>
                                                                                        </div>
                                                                                        <div className="action">
                                                                                            <button className="button btn-cart" type="button" title="" data-original-title="Add to Cart"><i className="fa fa-shopping-basket"></i></button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                   
                                                                    <div className="item">
                                                                        <div className="item-inner">
                                                                            <div className="item-img">
                                                                                <div className="item-img-info"> <a className="product-image" title="Retis lapen casen" href="product_detail.html"> <img alt="" src="products-images\product7.jpg" /> </a>
                                                                                    <div className="box-hover">
                                                                                        <ul className="add-to-links">
                                                                                            <li><a className="link-quickview" href="quick_view.html"></a> </li>
                                                                                            <li><a className="link-wishlist" href="wishlist.html"></a> </li>
                                                                                            <li><a className="link-compare" href="compare.html"></a> </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="item-info">
                                                                                <div className="info-inner">
                                                                                    <div className="item-title"> <a title="Retis lapen casen" href="product_detail.html">5 Star Direct Cool Single Door Refrigerator</a> </div>
                                                                                    <div className="brand">Nexus</div>
                                                                                    <div className="star-rating">
                                                                                        <span style={{ width: '60%' }}>Rated <strong className="rating">3.00</strong> out of 5</span>
                                                                                    </div>
                                                                                    <div className="item-content">
                                                                                        <div className="item-price">
                                                                                            <div className="price-box"> <span className="regular-price"> <span className="price">$88.00</span> </span> </div>
                                                                                        </div>
                                                                                        <div className="action">
                                                                                            <button className="button btn-cart" type="button" title="" data-original-title="Add to Cart"><i className="fa fa-shopping-basket"></i></button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                   
                                                                    <div className="item">
                                                                        <div className="item-inner">
                                                                            <div className="item-img">
                                                                                <div className="item-img-info"> <a className="product-image" title="Retis lapen casen" href="product_detail.html"> <img alt="" src="products-images\product9.jpg" /> </a>
                                                                                    <div className="box-hover">
                                                                                        <ul className="add-to-links">
                                                                                            <li><a className="link-quickview" href="quick_view.html"></a> </li>
                                                                                            <li><a className="link-wishlist" href="wishlist.html"></a> </li>
                                                                                            <li><a className="link-compare" href="compare.html"></a> </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="item-info">
                                                                                <div className="info-inner">
                                                                                    <div className="item-title"> <a title="Retis lapen casen" href="product_detail.html">Direct Wireless Network Laser Printer</a> </div>
                                                                                    <div className="brand">Dealsdot</div>
                                                                                    <div className="star-rating">
                                                                                        <span style={{ width: '60%' }}>Rated <strong className="rating">3.00</strong> out of 5</span>
                                                                                    </div>
                                                                                    <div className="item-content">
                                                                                        <div className="item-price">
                                                                                            <div className="price-box"> <span className="regular-price"> <span className="price">$88.00</span> </span> </div>
                                                                                        </div>
                                                                                        <div className="action">
                                                                                            <button className="button btn-cart" type="button" title="" data-original-title="Add to Cart"><i className="fa fa-shopping-basket"></i></button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </article>
                                    {/* <!--	======    End article  =========  -->  */}
                                </div>

                            </div>
                        </div>
                    </section>
                    {/* <!-- Main Container End --> */}

                    {/* <!-- Footer --> */}
                    <Footer />
                    {/* <!-- End Footer -->  */}
                </div>
                {/* <!-- mobile menu --> */}
                <MobileMenu />

                <MobileMenuIcons />

                {/* <!-- JavaScript -->  */}
            </>
        );
    }
}

export default Index;