import axios from 'axios';
import React from 'react';
import { toast } from 'react-toastify';
import { PUBLIC_URL } from '../../common/CommonString';
import {getFloatVal} from '../../../commonFunction';

let user_token = localStorage.getItem('user_token') ? localStorage.getItem('user_token') : '';

const ProductItems = (props) => {

    let {wishlist} = props;

    const removeWishlist = () => {
    // console.log(wishlist.id);
        let formData = new FormData();
        formData.append('user_id', 1);
        formData.append('product_id', wishlist.id);

        axios({
            url: `${PUBLIC_URL}/removefromwishlist`,
            method: 'POST',
            headers: { Authorization: "Bearer " + user_token },
            data: formData,
        }).then(response => {

            if(response.data.status == 1){

                toast.success("Product is removed from wishlist!", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                props.callWishlist();
            }

        }).catch((error) => {});
    }

    return (
        <div className="col-sm-12 col-lg-12">
           
            <div className="uthssk">
                <div className="pous">
                    <img src={wishlist.productimage ? wishlist.productimage.image_url : ''} alt="product" />
                </div>
                <div className="pous1">
                <a href={'/product-details?product='+wishlist.id} style={{textDecoration:'none',color:'inherit'}}>
                    <h3>{wishlist.product_name}</h3>
                    </a>
                    <h4>SKU: {wishlist.sku}</h4>
                    <br />
                    <span style={{ cursor: 'pointer' }} onClick={removeWishlist}><i className="fa fa-trash"></i> Remove</span>
                </div>
                <div className="pous2">
                    <p>AED {getFloatVal(wishlist.product_price)}</p>
                </div>
            </div>

        </div>
    );
};

export default ProductItems;