import React from 'react';

const ReviewMessage = (props) => {

    let {review} = props;

    let rating = review.ratting ? parseInt(review.ratting) : 0;

    return (
        <li className="comment">
            <div>
                <img alt="" src={review.users ? review.users.image_url : 'images/member1.png'} className="avatar avatar-60 photo" />
                <div className="comment-text">
                    <div className="star-rating">
                        <span style={{ width: `${rating * 20}%` }}>Rated <strong className="rating">{rating}</strong> out of 5</span>
                    </div>
                    <p className="meta">
                        <strong>{review.users ? review.users.name : ''}</strong>
                        <span>–</span> {review.created_at}
                    </p>
                    <div className="description"><p>{review.comment}</p>
                    </div>
                </div>
            </div>
        </li>
    );
};

export default ReviewMessage;