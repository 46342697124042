import axios from 'axios';
import React, { Component } from 'react';
import { PUBLIC_URL } from '../common/CommonString';
import Footer from '../common/layout/Footer';
import Header from '../common/layout/Header';
import MobileMenu from '../common/layout/MobileMenu';
import MobileMenuIcons from '../common/layout/MobileMenuIcons';
import Banner from './Banner';
import BannerWithBottom from './BannerWithBottom';
import BannerWithCoupon from './BannerWithCoupon';
import BannerWithDeal from './BannerWithDeal';
import BannerWithMiddle from './BannerWithMiddle';
import BannerWithOffer from './BannerWithOffer';
import BannerWithParallax from './BannerWithParallax';
import BestSeller from './BestSeller';
import HotDeal from './HotDeal';

import CategoryProductGroup from './CategoryProductGroup';
import CategoryProductTab from './CategoryProductTab';
import InfoBox from './InfoBox';
import TopProduct from './TopProduct';
import { languageList } from '../../languageHelper';
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
class Index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      featured: [],
      slider: [],
      sales: [],
      toprated: [],
      cartAction: false,
      bottomCategoriesWithProduct: [],
      bannerCoupons: [],
      bannerOffer: [],
      bannerDeal: [],
      bannerBottom: [],
      bannerSlipping: [],
      bannerMiddle: [],
      bannerParallax: [],
      hotDeal:[]
    }
  }

  componentDidMount = () => {

    axios({
      url: `${PUBLIC_URL}/homefeeds`,
      method: 'POST',
    }).then((response) => {

      if (response.data.status == 1) {
       //  console.log(response.data);
        this.setState({
          featured: response.data.featured_products ? response.data.featured_products : [],
          hotDeal: response.data.hot_deal_products ? response.data.hot_deal_products : [],
          slider: response.data.slider ? response.data.slider : [],
          sales: response.data.sale ? response.data.sale : [],
          toprated: response.data.top_rated ? response.data.top_rated : [],
          bottomCategoriesWithProduct: response.data.bottom_categories_with_product ? response.data.bottom_categories_with_product : [],
          bannerCoupons: response.data.banner_coupons ? response.data.banner_coupons : [],
          bannerOffer: response.data.offer_banner ? response.data.offer_banner : [],
          bannerDeal: response.data.deal_banner ? response.data.deal_banner : [],
          bannerBottom: response.data.bottom_banner ? response.data.bottom_banner : [],
          bannerSlipping: response.data.slipping_banner ? response.data.slipping_banner : [],
          bannerMiddle: response.data.middle_banner ? response.data.middle_banner : [],
          bannerParallax: response.data.parallax_banner ? response.data.parallax_banner : [],
        });

      }

    }).catch((error) => {});
  }

  cartEvent = () => {
  
    this.setState({
      cartAction: true,
    });
  }

  render() {

    let {
      featured,
      hotDeal,
      slider,
      sales,
      toprated,
      cartAction,
      bottomCategoriesWithProduct,
      bannerCoupons,
      bannerOffer,
      bannerDeal,
      bannerBottom,
      bannerSlipping,
      bannerMiddle,
      bannerParallax,

    } = this.state;

    return (
      <div>
        <div id="page">

          {/* <!-- Header --> */}
          <Header cartAction={cartAction} />
          {/* <!-- end header --> */}

          {slider.length > 0 ? (<Banner slider={slider} />) : ''}

          {/* <!-- ============================================== INFO BOXES ============================================== --> */}
          <InfoBox />
          {/* <!-- /.info-boxes-inner --> */}

          {/* <!-- ============================================== SCROLL TABS ============================================== --> */}
          <CategoryProductTab cartEvent={this.cartEvent} />
          {/* <!-- ============================================== SCROLL TABS : END ============================================== --> */}

          {bannerCoupons.length > 0 ? <BannerWithCoupon banner={bannerCoupons} /> : ''}

          {/* {bannerOffer.length > 0 ? <BannerWithOffer banner={bannerOffer} /> : ''} */}

          <BestSeller featured={featured} cartEvent={this.cartEvent} title={languageList['FEATURED_PRODUCTS'][lang]} />

          {bannerDeal.length > 0 ? <BannerWithDeal banner={bannerDeal} /> : ''}



          <HotDeal hotDeal={hotDeal} cartEvent={this.cartEvent} title={languageList['HOT_DEALS'][lang]} />


          {/* <CategoryProductGroup category={bottomCategoriesWithProduct} /> */}

          {bannerMiddle.length > 0 ? <BannerWithMiddle banner={bannerMiddle} /> : ''}

          <BannerWithParallax banner={bannerParallax} />

          <TopProduct sales={sales} toprated={toprated} bannerSlipping={bannerSlipping} />

          {/* <!-- Latest Blog --> */}

          <BannerWithBottom banner={bannerBottom} />

          {/* <!-- End Latest Blog -->  */}

          {/* <!-- Footer --> */}
          <Footer />
          {/* <!-- End Footer -->  */}
        </div>
        {/* <!-- mobile menu --> */}
        <MobileMenu />

        <MobileMenuIcons />

        {/* <!-- JavaScript -->  */}

      </div>
    );
  }
}

export default Index;