import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { PUBLIC_URL } from '../CommonString';
import SpinnerComponent from '../SpinnerComponent';

const ForgotPassword = () => {

    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [user, setUser] = useState('');
    const [newPassword, setNewPass] = useState('');
    const [passError, setPassError] = useState('');
    const [error, setError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [loading, setLoading] = useState(false);
    const [resetModal, setResetModal] = useState(false);
    const [isSend, setIsSend] = useState(false);



    const handleChange = (e) => {

        setEmail(e.target.value);
         setEmailError('');

    }
    const otpChange = (e) =>{
        setOtp(e.target.value);
    }
    const passchange = (e) =>{
        setNewPass(e.target.value)
    }
    const handleSubmitOtp = () => {

        if (email !== '') {

            let formData = new FormData();
            formData.append('email', email);

            setLoading(true);

            axios({
                url: `${PUBLIC_URL}/forgotPassword`,
                method: 'POST',
                data: formData,
            }).then(response => {

                if (response.data.status == 1) {
                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    // setEmail('');
                    setIsSend(true);
                 
                }

                setLoading(false);

            }).catch((error) => {

                if(error.response.data.status == 0){

                    toast.error('No registered email found!', {
                        position: "top-right",
                        autoClose: false,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

                setLoading(false);
            });
        }
        else {

            setEmailError('Email is required');
        }

    }

    const handleVerifyOtp = () => {
        if (email !== '' && otp !== '' ) {

            let formData = new FormData();
            formData.append('email', email);
            formData.append('otp', otp);

            setLoading(true);

            axios({
                url: `${PUBLIC_URL}/verifyOtp`,
                method: 'POST',
                data: formData,
            }).then(response => {
             // console.log(response);
                if (response.data.status == 1) {
                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    // setEmail('');
                       // this.setState({
                    //     verifyModal: true,
                    // });
                    setResetModal(true);
                    setUser(response.data.data.id);
                }

                setLoading(false);

            }).catch((error) => {

                if(error.response.data.status == 0){

                    toast.error('No match otp!', {
                        position: "top-right",
                        autoClose: false,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

                setLoading(false);
            });
        }
        else {

            alert('field required !');
        }

    }

    const handlePassChange = () => {
        if (newPassword !== '') {
            
            let formData = new FormData();
            formData.append('new_pass', newPassword);
            formData.append('user_id', user);

            setLoading(true);

            axios({
                url: `${PUBLIC_URL}/passwordChange`,
                method: 'POST',
                data: formData,
            }).then(response => {
              console.log(response);
                if (response.data.status == 1) {
                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    // setEmail('');
                       // this.setState({
                    //     verifyModal: true,
                    // });
                     setResetModal(false);
                     setEmail('');
                     setOtp('');
                     setNewPass('');
                     setUser('');
                     window.location.reload(true);

                }

                setLoading(false);

            }).catch((error) => {

                if(error.response.data.status == 0){

                    toast.error('No match otp!', {
                        position: "top-right",
                        autoClose: false,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

                setLoading(false);
            });
        }
        else {

            setNewPass('field required !');
        }

    }

    return (
        <>
            <SpinnerComponent open={loading} />

            <div className="modal fade" id="myModalforGot" role="dialog">

               {resetModal == false ? <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header v5c">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body" style={{ minHeight: '500px' }}>
                            <div className="yhd0d mt-5">
                                <h2>Reset Password</h2>
                                <h3>Back to login? <a href="#" data-dismiss="modal">Sign In</a></h3>
                            </div>
                            {isSend == false &&
                            <div  className="mmc5c">
                                <label>Email</label>
                                
                                <input onChange={handleChange} type="text" name="email" value={email} />
                                {/* <a style={{ marginLeft: "auto" }} onClick={handleSubmitOtp} >Send Otp</a> */}
                                <button onClick={handleSubmitOtp} className="jcdgCW">Send OTP</button>

                               
                                {emailError !== '' ? <div className="text-danger">{emailError}</div> : ''}
                            </div> }
                            {error !== '' ? <div className="alert alert-danger">{error}</div> : ''}
                            {isSend == true &&
                            <div className="mmc5c">
                                <label>Otp</label>
                                <input onChange={otpChange} type="text" name="otp" value={otp} />
                                {/* {emailError !== '' ? <div className="text-danger">{emailError}</div> : ''} */}
                                <button onClick={handleVerifyOtp} className="jcdgCW">Submit</button>

                            </div>
                            }  
                        </div>
                    </div>
                </div>
                :
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header v5c">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body" style={{ minHeight: '500px' }}>
                            <div className="yhd0d mt-5">
                                <h2>Reset Password</h2>
                                <h3>Back to login? <a href="#" data-dismiss="modal">Sign In</a></h3>
                            </div>
                            <div  className="mmc5c">
                                <label>New Password</label>
                                
                                <input onChange={passchange} type="text" name="new_password" value={newPassword} />
                               
                                {passError !== '' ? <div className="text-danger">{passError}</div> : ''}
                            </div>
                            {/* {error !== '' ? <div className="alert alert-danger">{error}</div> : ''} */}

                           
                            <button onClick={handlePassChange} className="jcdgCW">Submit</button>
                        </div>
                    </div>
                </div>
               }
            </div>
        </>
    );
};

export default ForgotPassword;