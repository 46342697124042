import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { PUBLIC_URL } from '../../common/CommonString';
import SpinnerComponent from '../../common/SpinnerComponent';

let user_token = localStorage.getItem('user_token');

const ChangePassword = () => {

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState('');
    const [showOld, setShowOld] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const [spinner, setSpinner] = useState(false);

    const inputChange = (e) => {

        if (e.target.name === 'oldPassword') {

            setOldPassword(e.target.value);
        }

        if (e.target.name === 'newPassword') {
            setNewPassword(e.target.value);
        }
    }

    const handleSubmit = () => {

        if (oldPassword !== '' && newPassword !== '') {

            if (oldPassword == newPassword) {
                setError('New Password and Old Password Must be different')
            }
            else {

                setSpinner(true);

                let formData = new FormData();
                formData.append('user_id', 1);
                formData.append('old_password', oldPassword);
                formData.append('new_password', newPassword);

                axios({
                    url: `${PUBLIC_URL}/changepassword`,
                    method: 'POST',
                    headers: { Authorization: "Bearer " + user_token },
                    data: formData,

                }).then(response => {

                    if (response.data.status == 1) {

                        toast.success("Password changed successfully!", {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });

                        setOldPassword('');
                        setNewPassword('');
                    }

                    else{
                        toast.error(response.data.message, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }

                    setSpinner(false);

                }).catch((error) => {

                    if (error.response.data.status == 0) {

                        setError(error.response.data.message);
                    }

                    setSpinner(false);
                });
            }
        }
        else {

            setError('Both fields are required');
        }
    }

    return (
        <>
            <SpinnerComponent open={spinner} />
            <div className="modal fade" id="myModalChangePassword" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header v5c">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <fieldset className="group-select">
                                <legend>Change Password</legend>
                                <ul>
                                    <li>
                                        <div className="customer-name">
                                            <div className="input-box name-firstname">
                                                <div className="mmc5c">
                                                    <label for="OldPassword"> Old Password <span className="required">*</span> </label>
                                                    <input type={showOld == false ? 'password' : 'text'} id="OldPassword" onChange={inputChange} name="oldPassword" value={oldPassword} title="Old Password" className="input-text required-entry" />
                                                    {showOld == false ? <span onClick={() => setShowOld(!showOld)} style={{ marginRight: '5px', marginTop: '3px' }}><i className="fa fa-eye-slash"></i></span> : <span onClick={() => setShowOld(!showOld)} style={{ marginRight: '5px', marginTop: '3px' }}><i className="fa fa-eye"></i></span>}
                                                </div>
                                            </div>
                                            <div className="input-box name-lastname">
                                                <div className="mmc5c">
                                                    <label for="NewPassword"> New Password <span className="required">*</span> </label>
                                                    <input type={showNew == false ? 'password' : 'text'} id="NewPassword" onChange={inputChange} name="newPassword" value={newPassword} title="NewPassword" className="input-text required-entry" />
                                                    {showNew == false ? <span onClick={() => setShowNew(!showNew)} style={{ marginRight: '5px', marginTop: '3px' }}><i className="fa fa-eye-slash"></i></span> : <span onClick={() => setShowNew(!showNew)} style={{ marginRight: '5px', marginTop: '3px' }}><i className="fa fa-eye"></i></span>}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                {error !== '' ? <p className="text-danger">{error}</p> : ''}
                                <p className="require"><em className="required">* </em>Required Fields</p>
                                <button type="button" className="button continue" onClick={handleSubmit}><span>Save</span></button>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChangePassword;