import React from 'react';
import { Link } from 'react-router-dom';

import { languageList } from '../../languageHelper';
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';

const BannerWithBottom = (props) => {

    let { banner } = props;
    let mobilemockups='images/mobile-app.png';
    let playstorePng='images/appstore.png';
    let pstorePng='images/g-play.png';
    return (
         <>
        {/* <section className="page-section" id="app-section">
            <div className="container">
                <div className="row v-center">
                    <div className="col-md-6 app-mobile-img reveal fade-left active">
                        <img src={mobilemockups} alt="" style={{width:'100%'}} />
                    </div>
                    <div className="col-md-6 reveal fade-right active mbb-3">
                       <h2 className="section-heading">Get the BUYFORLESS App</h2>
                       <p>Find your perfect products effortlessly. Download it now!</p>
                       <div className="app-img">
                            <a href="#" alt="" title="App store" className="mr-20 "><img src={pstorePng} alt="" /></a>
                            <a href="#" alt="" title="Play store"><img src={playstorePng} /></a>
                       </div>
                    </div>
                </div>
            </div>
        </section>         */}

        <section className="home-articles spacer-medium">
            <div className="container">
                <div className="css-grid--columns-2">
                    
                    {banner && banner.map((item, index) => {

                        if(item.type === 'category'){

                            return (
                                <div className="article-home" key={index}>
                                    <div className="article-home__image parallax-parent">
                                        <img src={item.image} className="parallax-child--second" alt={item.title} />
                                    </div>
                                    <div className="article-home__content">
                                        <div className="inside">
                                            <h4 className="title">{lang=='en'?item.title:item.multi_name? item.multi_name.arabic :item.title}</h4>
                                            <Link to={`/product-grid?category=${item.cat_id}&innersubcategory=&subcategory=&category_name=${item.category ? item.category.category_name : ''}`} className="link">{languageList['Shop_now'][lang]} <i className="fa fa-chevron-circle-right"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                        else if(item.type === 'product'){

                            return (
                                <div className="article-home" key={index}>
                                    <div className="article-home__image parallax-parent">
                                        <img src={item.image} className="parallax-child--second" alt={item.title} />
                                    </div>
                                    <div className="article-home__content">
                                        <div className="inside">
                                            <h4 className="title">{lang=='en'?item.title:item.multi_name? item.multi_name.arabic :item.title}</h4>
                                            <Link to={`/product-details?product=${item.product_id}`} className="link"> {languageList['Shop_now'][lang]} <i className="fa fa-chevron-circle-right"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            );

                        }
                        else{

                            return (
                                <div className="article-home" key={index}>
                                    <div className="article-home__image parallax-parent">
                                        <img src={item.image} className="parallax-child--second" alt={item.title} />
                                    </div>
                                    <div className="article-home__content">
                                        <div className="inside">
                                            <h4 className="title">{lang=='en'?item.title:item.multi_name? item.multi_name.arabic :item.title}</h4>
                                            <a href="javascript:void(0);" className="link">{languageList['Shop_now'][lang]} <i className="fa fa-chevron-circle-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    })}

                </div>
            </div>
        </section>
        </>
    );
};

export default BannerWithBottom;