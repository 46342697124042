export const languageList={
    'all_category':{
        'en':'All Category',
        'ar':'كل الفئات'
    },
    'category':{
        'en':'Category',
        'ar':'فئة'
      },

    'home':{
        'en':'Home',
        'ar':'مسكن'
    },
    'EXCITING_OFFERS':{
        'en':'EXCITING OFFERS',
        'ar':'عروض مثيرة'
    },

    'Up_to_50%_discount':{
        'en':'Up to 50% discount',
        'ar':'%خصم يصل إلى 50'
    },

    'FREE_SHIPPING':{
        'en':'FREE SHIPPING',
        'ar':'الشحن مجانا'
    },
    'Shipping_on_orders_over_AED_99':{
        'en':'Shipping on orders over AED 99',
        'ar':'شحن للطلبات التي تزيد عن 99 درهم إماراتي '
    },
    'Special_Sale':{
        'en':'SPECIAL SALE',
        'ar':'بيع خاص'
    },
    'Extra_AED_5_off_on_all_items':{
        'en':'Extra AED 5 off on all items',
        'ar':'خصم إضافي 5 دراهم على جميع المنتجات'
    },
    'FEATURED_PRODUCTS':{
        'en':'FEATURED PRODUCTS',
        'ar':'منتجات مميزة'
    },
    'HOT_DEALS':{
        'en':'HOT DEALS',
        'ar':'صفقات ساخنة'
    },
    'SALE':{
        'en':'SALE',
        'ar':'تخفيض السعر '
    },
    'TOP_RATED':{
        'en':'TOP RATED',
        'ar':'أعلى التقييمات'
    },
    'RELATED_PRODUCTS':{
        'en':'RELATED PRODUCTS',
        'ar':'منتجات ذات صله'
    },
    'Description':{
        'en':'Description',
        'ar':'وصف'
    },
    'Seller':{
        'en':'Seller',
        'ar':'تاجر '
    },
    'Estimated_Shipping_Days':{
        'en':'Estimated Shipping Days',
        'ar':'أيام الشحن المقدرة '
    },
    'Days_Return_Policy':{
        'en':'Days Return Policy',
        'ar':'سياسة إرجاع الأيام '
    },
    'No_Return':{
        'en':'No return',
        'ar':'لا رجوع '
    },
    'Shipping_Cost':{
        'en':'Shipping Cost',
        'ar':'تكلفة الشحن'
    },
    'Tax':{
        'en':'Tax',
        'ar':'ضريبة'
    },
    'Product_Description':{
        'en':'Product Description',
        'ar':'وصف المنتج'
    },
    'Reviews':{
        'en':'Reviews',
        'ar':'المراجعات'
    },
    'PROFILE':{
        'en':'PROFILE',
        'ar':'الملف الشخصي'
    },
    'profile':{
        'en':'Profile',
        'ar':'الملف الشخصي'
    },
    'INFO':{
        'en':'INFO',
        'ar':'معلومات  '
    },
    'MORE':{
        'en':'MORE',
        'ar':'أكثر'
    },
    'Contact_Us':{
        'en':'Contact Us',
        'ar':'اتصل بنا '
    },
    'Your_Account':{
        'en':'Your Account',
        'ar':'الحساب الخاص بك'
    },
    'Addresses':{
        'en':'Address',
        'ar':'تبوك '
    },
    'Wishlist':{
        'en':'Wishlist',
        'ar':'قائمة الرغبات '
    },
    'Orders_History':{
        'en':'Orders History',
        'ar':'تاريخ الطلبات'
    },
    'About_Us':{
        'en':'About Us',
        'ar':'معلومات عنا'
    },
    'Sell_your_products_here':{
        'en':'Sell your products here',
        'ar':'قم ببيع منتجاتك هنا'
    },
    'View_all':{
        'en':'View all',
        'ar':'مشاهدة الكل'
    },
    'BROWSE_CATEGORIES':{
        'en':'BROWSE CATEGORIES',
        'ar':'تصفح الفئات'
    },
    'FILTER':{
        'en':'FILTER',
        'ar':'منقي'
    },
    'Brand':{
        'en':'Brand',
        'ar':'ماركة'
    },
    'Customer_Ratings':{
        'en':'Customer Ratings',
        'ar':'تقييمات العملاء'
    },
    'Shopping_Cart':{
        'en':'Shopping Cart',
        'ar':'عربة التسوق'
    },
    'Order_Summary':{
        'en':'Order Summary',
        'ar':'ملخص الطلب'
    },
    'sub_total':{
        'en':'sub total',
        'ar':'المجموع الفرعي'
    },
    'discount':{
        'en':'discount',
        'ar':'تخفيض'
    },
    'Delivery_Charges':{
        'en':'Delivery Charges',
        'ar':'رسوم التوصيل'
    },
    'Total':{
        'en':'Total',
        'ar':'المجموع'
    },
    'YOUR_CHECKOUT':{
        'en':'YOUR CHECKOUT',
        'ar':'الدفع الخاص بك'
    },
    'CHECKOUT':{
        'en':'CHECKOUT',
        'ar':'الدفع'
    },
    'Checkout_Method':{
        'en':'Checkout Method ',
        'ar':'أسلوب المراجعة'
    },
    'Shipping_Info':{
        'en':' Shipping Information',
        'ar':'معلومات الشحن'
    },
    'Payment_Info':{
        'en':' Payment Information',
        'ar':'معلومات الدفع'
    },
    'order_review':{
        'en':'Order Review',
        'ar':'مراجعة الطلب '
    },
    'Billing_Address':{
        'en':'Billing Address',
        'ar':'عنوان وصول الفواتير'
    },
    'Shipping_Address':{
        'en':'Shipping Address',
        'ar':'عنوان الشحن'
    },
    'orders':{
        'en':'Orders',
        'ar':'ترتيب'
    },
    'continue_shopping':{
        'en':'continue shopping',
        'ar':'مواصلة التسوق'
    },
    'apply':{
        'en':'APPLY',
        'ar':'يتقدم'
    },
    'place_order':{
        'en':'place order',
        'ar':'مكان الامر'
    },
    'Edit_Your_Cart':{
        'en':'Edit Your Cart',
        'ar':'تعديل عربة التسوق'
    },
    'Forgot_an_Item?':{
        'en':'Forgot an Item?',
        'ar':'هل نسيت عنصر؟'
    },
    'Cash_On_Delivery':{
        'en':'Cash On Delivery',
        'ar':'الدفع عند الاستلام'
    },
    'Card_Payment':{
        'en':'Card Payment',
        'ar':'بطاقه ائتمان'
    },
    'Select_a_shipping_address':{
        'en':'Select a shipping address from your address book or enter a new address.',
        'ar':'.حدد عنوان شحن من دفتر العناوين الخاص بك أو أدخل عنوانًا جديدًا'
    },
    'Select_a_billing_address':{
        'en':'Select a billing address from your address book or enter a new address.',
        'ar':'.حدد عنوان إرسال الفواتير من دفتر العناوين الخاص بك أو أدخل عنوانًا جديدًا'
    },
    'Sign_up_to_Newsletter':{
        'en':'Sign up to Newsletter',
        'ar':'اشترك في النشرة الإخبارية'
    },
    'Subscribe':{
        'en':'Subscribe',
        'ar':'الإشتراك'
    },
    'Enter_your_email_address':{
        'en':'Enter your email address',
        'ar':'أدخل عنوان بريدك الالكتروني'
    },
    'Shop_now':{
        'en':'Shop now',
        'ar':'تسوق الآن'
    },
    'My_Account':{
        'en':'My Account',
        'ar':'حسابي'
    },
    'Logout':{
        'en':'Logout',
        'ar':'تسجيل خروج'
    },
    'Product_List':{
        'en':'Product List',
        'ar':'قائمة المنتجات'
    },
    'Add_to_Cart':{
        'en':'Add to Cart',
        'ar':'أضف إلى السلة'
    },
    'Buy_Now':{
        'en':'Buy Now',
        'ar':'اشتري الآن'
    },
    'Add_to_Wishlist':{
        'en':'Add to Wishlist',
        'ar':'أضف إلى قائمة الامنيات'
    },
    'Select_Varient':{
        'en':'Select Varient',
        'ar':'حدد متغير'
    },
    'Specifications':{
        'en':'Specifications',
        'ar':'تحديد'
    },
}