import React from 'react';
import { Link } from 'react-router-dom';
import { languageList } from '../../languageHelper';
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
const BannerWithCoupon = (props) => {

    let { banner } = props;

    return (
        <div className="container">
            <div className="wide-banner">
                <div className="row">
                    {banner && banner.map((item, index) => {

                        return (

                            <div className="figure banner-with-effects effect-sadie1 banner-width  with-button" key={index}>
                                <img src={item.image} alt={item.title} />
                                <div className="static-text">
                                    
                                    <span className="banner-text" style={{width: '50%'}}>{lang=='en'?item.title:item.multi_name? item.multi_name.arabic :item.title}</span>
                                    <span className="banner-text-normal" style={{width: '50%'}}>
                                        {lang=='en'?item.description:item.multi_description? item.multi_description.arabic :item.description}
                                    </span>
                                </div>
                            </div>
                        );
                    })}

                </div>
            </div>
        </div>
    );
};

export default BannerWithCoupon;