import React from 'react';

const BannerWithSlipping = (props) => {

    let { banner } = props;

    return (
        <div className="col-md-6 col-sm-4 col-lg-4">
            <div className="sd58s">
                <div id="myCarousel" className="carousel slide" data-ride="carousel">

                    <ol className="carousel-indicators">
                        {banner && banner.map((item, index) => {

                            if (index == 0) {

                                return (
                                    <li data-target="#myCarousel" data-slide-to="0" className="active" key={index}></li>
                                );
                            }
                            else {
                                return (
                                    <li data-target="#myCarousel" data-slide-to={index} key={index}></li>
                                );
                            }
                        })}

                    </ol>


                    <div className="carousel-inner">
                        
                        {banner && banner.map((item, index) => {

                            if (index == 0) {
                                return (

                                    <div className="item active" key={index}>
                                        <img src={item.image} alt={item.image} />
                                    </div>
                                );
                            }
                            else {

                                return (

                                    <div className="item" key={index}>
                                        <img src={item.image} alt={item.image} />
                                    </div>
                                );
                            }
                        })}
                        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BannerWithSlipping;