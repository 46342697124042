import React, { useEffect, useState } from 'react';
import Header from '../../common/layout/Header';
import Footer from '../../common/layout/Footer';
import MobileMenu from '../../common/layout/MobileMenu';
import MobileMenuIcons from '../../common/layout/MobileMenuIcons';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ChangePassword from './ChangePassword';
import axios from 'axios';
import { PUBLIC_URL } from '../../common/CommonString';
import { toast } from 'react-toastify';
import { languageList } from '../../../languageHelper';
let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';

let ciphertext = localStorage.getItem('user_token');

const Index = () => {

    const [firstLoad, setFirstLoad] = useState(true);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [profilePic, setProfilePic] = useState('');
    const [profilePicFile, setProfilePicFile] = useState('');
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {

        if(firstLoad == true){

            let formData = new FormData();
            formData.append('user_id', 1);

            axios({
                url: `${PUBLIC_URL}/getprofile`,
                method: 'POST',
                headers: { Authorization: "Bearer " + ciphertext },
                data: formData,
            }).then(response => {

                if(response.data.status == 1){

                    setName(response.data.data ? response.data.data.name : '');
                    setEmail(response.data.data ? response.data.data.email : '');
                    setMobile(response.data.data ? response.data.data.mobile : '');
                    setProfilePic(response.data.data ? response.data.data.profile_pic : '');
                }

            }).catch((error) => {});
        }

        setFirstLoad(false);

    });

    const handleChange = (e) => {

        if(e.target.name === 'name'){

            setName(e.target.value);
        }
        if(e.target.name === 'email'){
            setEmail(e.target.value);
        }
        if(e.target.name === 'mobile'){
            setMobile(e.target.value);
        }
        if(e.target.name === 'profile'){
            
            setProfilePicFile(e.target.files[0]);

            let reader = new FileReader();

            reader.onloadend = () => {
                
                setProfilePic(reader.result);
            }

            reader.readAsDataURL(e.target.files[0]);
        }
        
    }

    const handleSubmit = () => {

        if(name !== '' && email !== '' && mobile !== ''){

            let formData = new FormData();
            formData.append('user_id', 21);
            formData.append('name', name);
            formData.append('mobile', mobile);
            formData.append('image', profilePicFile ? profilePicFile : '');

            axios({
                url: `${PUBLIC_URL}/editprofile`,
                method: 'POST',
                headers: { Authorization: "Bearer " + ciphertext },
                data: formData,
            }).then(response => {

                if(response.data.status == 1){

                    toast.success("Profile is updated!", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

            }).catch((error) => {

                if(error.response.data.status == 0){

                    setError(error.response.data.message);
                }
            });
        }
        else{
            if(name === '')
                setNameError('Name is required');
            if(mobile === '')
                setMobileError('Mobile is required');
            if(email === ''){
                setEmailError('Email is required');
            }
        }
    }

    return (
        <div>
            <div id="page">

                {/* <!-- Header --> */}
                <Header />
                {/* <!-- end header --> */}

                <section className="main-container col1-layout">
                    <div className="container">
                        <div className="breadcrumbs">
                            <ul>
                                <li className="home"> <Link to="/" title="Go to Home Page">{languageList['home'][lang]}</Link> <span>/</span> </li>
                                <li> <strong>{languageList['My_Account'][lang]}</strong> </li>
                            </ul>
                        </div>

                        <div className="row">
                            <div className="col-md-3 account-tabs-section extr-margin-bottom">
                                <ul className="nav flex-column account-tabs">
                                    <li className="nav-item">
                                        <Link className="nav-link active" to="/profile">{languageList['profile'][lang]}</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/orders">{languageList['orders'][lang]}</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/wishlist">{languageList['Wishlist'][lang]}</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/address">{languageList['Addresses'][lang]}</Link>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-md-9">
                                <div className="tab-content account-profile">
                                    <fieldset className="group-select">
                                        <legend>{languageList['profile'][lang]}</legend>
                                        <img src={profilePic !== '' ? profilePic : '/images/member1.png'} alt={name} className="img-fluid avatar" />
                                        <div className="address-add">
                                            <button type="button" data-toggle="modal" data-target="#myModalChangePassword" className="button continue"><span>Change Password</span></button>
                                        </div>
                                        <ul>
                                            <li>
                                                <div className="customer-name">
                                                    <div className="input-box name-firstname">
                                                        <label for="billing:firstname"> Name <span className="required">*</span> </label>
                                                        <br />
                                                        <input type="text" id="billing:firstname" onChange={handleChange} name="name" value={name} title="Name" className="input-text required-entry" />
                                                        {nameError !== '' ? <div className="text-danger">{nameError}</div> : ''}
                                                    </div>
                                                    <div className="input-box name-lastname">
                                                        <label for="billing:lastname"> Email <span className="required">*</span> </label>
                                                        <br />
                                                        <input type="text" id="billing:lastname" onChange={handleChange} name="email" value={email} title="Email" className="input-text required-entry" readOnly />
                                                        {emailError !== '' ? <div className="text-danger">{emailError}</div> : ''}
                                                    </div>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="input-box " style={{width:'20%'}}>
                                                    
                                                    <label for="billing:street1">Code <span className="required">*</span></label>
                                                    <br />
                                                    <select type="text" name="countryCode" style={{width: '90%'}}><option value="+971">UAE(+971)</option></select>
                                                   
                                                </div>
                                                <div className="input-box name-lastname" style={{width:'40%'}}>
                                                    <label for="billing:street1">Mobile <span className="required">*</span></label>
                                                    <br />
                                                    <input type="number" title="Street Address" onChange={handleChange} name="mobile" id="billing:street1  street1" value={mobile} className="input-text required-entry" />
                                                    {mobileError !== '' ? <div className="text-danger">{mobileError}</div> : ''}
                                                </div>
                                                <div className="input-box name-lastname" style={{width:'40%'}}>
                                                    <label for="ChangeProfile">Change Profile</label>
                                                    <br />
                                                    <input type="file" title="Profile" onChange={handleChange} name="profile" id="ChangeProfile" className="input-text" />
                                                </div>
                                            </li>
                                        </ul>
                                        {error !== '' ? <div className="text-danger">{error}</div>: ''}
                                        <p className="require"><em className="required">* </em>Required Fields</p>
                                        <button type="button" className="button continue" onClick={handleSubmit}><span>Save</span></button>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Footer --> */}
                <Footer />
                {/* <!-- End Footer -->  */}

            </div>

            {/* <!-- mobile menu --> */}
            <MobileMenu />

            <MobileMenuIcons />

            {/* <!-- JavaScript -->  */}

            <ChangePassword />
        </div>
    );
};

export default Index;